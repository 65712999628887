var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dialog_header"},[(_vm.showBack)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.backLoading),expression:"backLoading"}],staticClass:"left_back cursor",attrs:{"element-loading-spinner":"el-icon-loading"},on:{"click":_vm.back}},[_vm._t("left_back",function(){return [_c('Icon',{attrs:{"icon":"left","size":"24px"}})]})],2):_vm._e(),_c('div',{staticClass:"header_title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.showIgnore)?_c('div',{staticClass:"ignore_step cursor",on:{"click":_vm.ignore}},[_vm._t("ignore",function(){return [_c('ZjsjHomeButton',{attrs:{"type":"text","loading":_vm.ignoreLoading,"customStyle":{
          'font-size': '16px',
          color: '#333333',
          'font-weight': 400,
          background: 'transparent',
          'border-color': 'none',
        }}},[_vm._v(" 跳过 ")]),_c('span')]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }