export const DOWNLOAD_CENTER_TYPE = [
  {
    path: '/dataCockpit/case_data',
    code_type: 'add',
    type: 2,
    name: '数据驾驶舱-方案数据',
    request_url: '/server/batch/stat/exportDesignData',
    request_method: 'POST',
  },
  {
    path: '/submissionReview/list',
    code_type: 'add',
    type: 5,
    name: '样板间投稿-投稿审核',
    request_url: '/server/batch/contribute/auditList/export',
    request_method: 'POST',
  },
  {
    path: '/submissionReview/quickDesign',
    code_type: 'add',
    type: 6,
    name: '样板间投稿-快搭设计管理',
    request_url: '/server/batch/quick/list/export',
    request_method: 'POST',
  },
  {
    path: '/submissionReview/onlineDesignManage',
    code_type: 'add',
    type: 7,
    name: '样板间投稿-快设计方案管理',
    request_url: '/server/batch/custom/case/list/export',
    request_method: 'POST',
  },
  {
    path: '/case/caselist',
    code_type: 'add',
    type: 8,
    name: '案例库-设计灵感',
    request_url: '/server/batch/haierCase/export',
    request_method: 'POST',
  },
  {
    path: '/manager/accountManage',
    code_type: 'add',
    type: 9,
    name: '系统管理-账号管理',
    request_url: '/server/batch/operationUser/export',
    request_method: 'POST',
  },
  {
    path: '/manager/depManage',
    code_type: 'add',
    type: 10,
    name: '系统管理-部门管理',
    request_url: '/server/batch/dept/export',
    request_method: 'POST',
  },
  {
    path: '/materialCentre/drawingManage',
    code_type: 'add',
    type: 11,
    name: '素材中心-图纸管理',
    request_select_url: '/server/batch/drawing/exportDrawing',
    request_all_url: '/server/batch/drawing/exportAllDrawing',
    request_url: '',
    request_method: 'POST',
  },
  {
    path: '/materialCentre/modelManage',
    code_type: 'add',
    type: 12,
    name: '素材中心-模型管理',
    request_url: '/server/batch/saleModel/export',
    request_method: 'POST',
  },
  {
    path: '/dataCockpit/account',
    code_type: 'exists',
    type: 1,
    name: '数据驾驶舱-账号数据',
    request_url: '',
  },
  {
    path: '/dataCockpit/quickdesign',
    code_type: 'exists',
    type: 3,
    name: '数据驾驶舱-快设计数据',
    request_url: '',
  },
  {
    path: '/manager/user',
    code_type: 'exists',
    type: 4,
    name: '设计师管理-用户列表',
    request_url: '',
  },
  {
    path: '/permission/rightsDistribution',
    code_type: '',
    type: 13,
    name: '设计师管理-设计权益审核',
    request_url: '/batch/privilege/exportPrivilegeOrder',
    request_method: 'POST',
  },
];
