import store from '@/store';
import router from '@/router';
import { DESIGNER_DELETE_STATE } from '@/utils/resources';
// authFlag - 设计师认证标识：0：未认证 1：审核中 2：认证成功 3：认证失败
function goToApplyRights(
  content = '您的设计工具权益已失效，若需使用权益，可以到“我的工作台-个人中心-权益申请”页续约，或点此申请~'
) {
  let query = {
    confirmButtonStyle: { width: '112px', height: '44px' },
    confirmButtonText: '去申请',
    content: content,
    onConfirm: () => {
      store.dispatch('setCurrentComponent', null);
      router.push({ path: '/person/authApply' });
    },
  };
  store.dispatch('setCurrentComponent', {
    name: 'AUTH_ERROR',
    query,
  });
}
/**
 * @description: 解绑提示
 * @return {Boolean}  解绑状态 return true
 */
function unbindTips() {
  if (store.state.user.userProfileState === DESIGNER_DELETE_STATE) {
    let operationUserName = window.localStorage.getItem('operationUserName') || '';
    let operationUserMobile = window.localStorage.getItem('operationUserMobile') || '';
    let tips = '';
    if (operationUserName) {
      tips = `您已被解除设计师身份，若需恢复，请联系负责人${operationUserName}(${operationUserMobile}）`;
    } else {
      tips = '您已被解除设计师身份';
    }
    let query = {
      confirmButtonStyle: { width: '112px', height: '44px' },
      confirmButtonText: '确定',
      content: tips,
      onConfirm: () => {
        store.dispatch('setCurrentComponent', null);
      },
    };
    store.dispatch('setCurrentComponent', {
      name: 'AUTH_ERROR',
      query,
    });
    return true;
  } else {
    return false;
  }
}

export { goToApplyRights, unbindTips };
