import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import user from './modules/user';
import permission from './modules/permission';
import menu from './modules/menu';
import operationInfo from './modules/operationInfo';
import designIframe from './modules/designIframe';
import { SampleRoomSubmissionStore } from './modules/publication';
import youchaoxiuStore from './modules/youchaoxiu.js';
import LoginRegistreStore from './modules/loginRegister';
import footerStore from './modules/contolFotter';
import QuotatuinStore from '@/store/modules/caseQuotation';

export default new Vuex.Store({
  modules: {
    user,
    permission,
    menu,
    operationInfo,
    designIframe,
    SampleRoomSubmissionStore,
    youchaoxiuStore,
    LoginRegistreStore,
    footerStore,
    QuotatuinStore,
  },
});
