import { render, staticRenderFns } from "./UploadDesignPlan.vue?vue&type=template&id=528047f6&scoped=true"
import script from "./UploadDesignPlan.vue?vue&type=script&lang=js"
export * from "./UploadDesignPlan.vue?vue&type=script&lang=js"
import style0 from "./UploadDesignPlan.vue?vue&type=style&index=0&id=528047f6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "528047f6",
  null
  
)

export default component.exports