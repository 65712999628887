import Vue from 'vue';
import App from './App';
import router from '@/router';
import store from '@/store/';
import VueCropper from 'vue-cropper';
import '@/components/core/registerDirective';
import '@/components/directives/trim';
import '@/components/directives/intersection';
import './assets/font/font.css';
import VueClipboard from 'vue-clipboard2';
import VueDND from 'awe-dnd';
import VueMatomo from 'vue-matomo';
import '@/plugins/elementUI.js';
import '@/plugins/plugins.js';
import { getToken } from '@/utils/auth'; // 验权
import { isIframeInNebula } from '@/utils/beforeSetUp';
import { GetQueryString } from '@/utils/mUtils';
import { initArms } from '@/utils/arms';
import sentryInit from '@/utils/sentry';
import { isIE } from './utils/tools';
import { Notification } from 'element-ui';
// import { warnInit } from '@syn/syn-monitor/dev.js'; // vite.config 配置的别名
// warnInit({
//   env: process.env.NODE_ENV,
//   webhook:
//     'https://open.feishu.cn/open-apis/bot/v2/hook/ef00c395-2e35-4a2c-b09e-e967d8339a35',
//   errorignore: [],
// });

if (isIE()) {
  Notification({
    title: '温馨提示',
    type: 'warning',
    message: '为了您的体验请使用Chrome浏览器',
    duration: 0,
  });
}
// 埋点
Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://sa.haier.net/piwik',
  siteId: 'tdcd',
  trackerFileName: 'piwik',
  // import
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  debug: false,
  userId: undefined,
});
Vue.use(VueClipboard);
Vue.use(VueCropper);
Vue.use(VueDND);
Vue.config.productionTip = false;
import '@/permission'; // permission control
// 本地运行禁止启用sentry
if (process.env.VUE_APP_SENTRY !== 'localhost') {
  initArms();
  sentryInit(router);
}

async function bootstrap() {
  try {
    // 是否在iframe中,且在星云平台内
    if (isIframeInNebula()) {
      let token = GetQueryString('token');
      window.localStorage.setItem('X-Access-Token', token);
      let menuId = GetQueryString('menuId');
      window.localStorage.setItem('xy-menu-id', menuId);
      window.localStorage.setItem('user-type', '5'); // 星云平台专属userType
      /**
       * 新增请求头header  区分平台
        platform - design:设计师平台, operation:运营平台
      */
      // let nPlatform = GetQueryString('platform') || 'design';
      // window.localStorage.setItem('platform', nPlatform); // design:设计师平台
    } else {
      // 是否由iam进入
      const iamCode = GetQueryString('code');
      const url = window.location.href;
      const checkUrl = '/#/operateManagePlat';

      if (iamCode && url.includes(checkUrl)) {
        console.log('由iam登录系统');
      } else if (getToken()) {
        // 是否存在缓存
        if (window.localStorage.getItem('platform') === 'design') {
          // 3D设计师 - 获取用户信息
          // 是否完成注册流程，0否，1是
          await store.dispatch('tokenLogin', getToken()); // 获取用户信息
        } else {
          await store.dispatch('getBtnLimits');
        }
        // 3D设计师、运营人员
        await store.dispatch('GenerateRoutes', getToken()); // 获取路由信息
        await store.dispatch('accessRouters', getToken()); // 有权限路由
      }
    }
  } catch (error) {
    console.log('bootstrap error', error);
  }

  new Vue({
    router,
    store,
    render: (h) => h(App),
    mounted() {
      // 如果已经有登录信息则执行跳转
      // 否则走默认路由
      if (getToken()) {
        console.log('平台已登录！');
        // store.dispatch('loginSuccess');
      }
    },
  }).$mount('#app');
}
bootstrap();
