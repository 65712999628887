<template>
  <!-- 注册 - 门店 -->
  <div class="store_select">
    <DialogHeader title="门店选择" :show-ignore="false" :show-back="showBack" @handleBack="handleBack"></DialogHeader>
    <!-- 查询 -->
    <div class="content_base_padding">
      <div class="search_box padding-r-l">
        <el-input
          class="search_inp"
          v-model="code"
          @keyup.enter.native="search()"
          :disabled="isPartner"
          placeholder="请输入商家编码/门店名称"></el-input>
        <div v-if="!isPartner">
          <ZjsjHomeButton
            type="primary"
            @click="search()"
            size="large"
            :customStyle="{
              'font-weight': 400,
              'font-size': '14px',
              width: '88px',
            }">
            搜索
          </ZjsjHomeButton>
          <ZjsjHomeButton
            @click="reset()"
            :customStyle="{
              'background-color': '#fff',
              color: '#666',
              borderColor: '#eee',
              'font-weight': 400,
              'font-size': '14px',
              width: '88px',
            }">
            重置
          </ZjsjHomeButton>
        </div>
        <div v-else class="store_tip">
          <span v-show="storeType === '0'">店铺合伙人/负责人仅能加入对应店铺下的门店！</span>
          <span v-show="storeType === '1'">您合作的所有门店与您解除了绑定，使用设计师权益需要您至少加入一家门店！</span>
          <span v-show="storeType === '2'">待门店通过您的申请，您便可以畅享设计师的所有权益！</span>
        </div>
      </div>
      <!-- 门店table -->
      <el-table
        class="store_select_table"
        :data="tableData"
        :height="'325px'"
        ref="multipleTable"
        border
        size="small"
        :row-key="getRowKeys"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" :reserve-selection="true" width="50" align="center"></el-table-column>
        <el-table-column prop="mdName" label="门店名称" align="center"></el-table-column>
        <el-table-column prop="mdCode" label="门店编码" width="100" align="center"></el-table-column>
        <el-table-column prop="storeName" label="所属店铺" align="center"></el-table-column>
        <el-table-column prop="storeCode" label="店铺编码" width="100" align="center"></el-table-column>
        <el-table-column prop="tradingVolume" label="成交量" width="80" align="center"></el-table-column>
        <el-table-column label="店铺服务区域" width="100" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <ZjsjHomeButton
              type="text"
              @click="view(scope.row)"
              :customStyle="{
                'font-size': '12px',
                color: '#BE965A',
                'text-align': 'justify',
                'font-weight': 400,
                background: 'transparent',
                'border-color': 'none',
              }">
              查看
            </ZjsjHomeButton>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        popper-class="store_select_pagination"
        v-show="storeType !== '2'"
        class="page_block"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, sizes,jumper,->,prev, pager, next"
        :total="total"></el-pagination>
    </div>
    <!-- 查看店铺服务区域 -->
    <el-dialog width="400px" title="查看店铺服务区域" :visible.sync="innerShow" :destroy-on-close="true" append-to-body>
      <el-table :data="serviceAreaList" v-loading="tableLoading" height="520px" border size="small">
        <el-table-column prop="provinceName" label="省份" align="center"></el-table-column>
        <el-table-column prop="cityName" label="城市" align="center"></el-table-column>
        <el-table-column prop="regionName" label="区域" align="center"></el-table-column>
        <el-table-column prop="streetName" label="街道" align="center"></el-table-column>
      </el-table>
    </el-dialog>
    <div slot="footer" class="footer_btns">
      <div v-if="storeType === '0'">
        <ZjsjHomeButton class="wid160" type="primary" size="medium" @click="confirmBtn()">确认</ZjsjHomeButton>
        <ZjsjHomeButton
          class="wid160"
          size="medium"
          :customStyle="{
            'background-color': '#fff',
            color: '#666',
            borderColor: '#eee',
          }"
          @click="cancelBtn()">
          取消
        </ZjsjHomeButton>
      </div>
      <div v-else>
        <ZjsjHomeButton v-if="storeType === '1'" class="wid160" type="primary" size="medium" @click="applyStore()">
          确认加入
        </ZjsjHomeButton>

        <ZjsjHomeButton v-if="storeType === '2'" disabled type="primary" size="medium" @click="enterHome()">
          进入三翼鸟定制平台
        </ZjsjHomeButton>
        <ZjsjHomeButton class="wid160" size="medium" @click="toLogin()">返回登录</ZjsjHomeButton>
      </div>
    </div>
  </div>
</template>

<script>
  import request from '@/utils/axios';
  import { serverUrl } from '@/utils/env';
  import pagQuery from '@/mixins/pagQuery';
  import { removeToken } from '@/utils/auth';
  import DialogHeader from './dialogHeader.vue';

  export default {
    mixins: [pagQuery],
    props: {
      dialogVisible: {
        type: Boolean,
        default: false,
      },
      storeType: {
        // 0,注册时选择门店;
        // 1,首页显示时，申请被驳回;
        // 2,首页显示时，申请中;
        type: String,
        default: '0',
      },
      storeCode: {
        type: String,
        default: '',
      },
      checkedStore: {
        type: Array,
        default: () => [],
      },
      showBack: {
        type: Boolean,
        default: true,
      },
      fromPage: {
        type: Object,
        default: () => {},
      },
    },
    components: {
      DialogHeader,
    },
    watch: {},
    computed: {
      isPartner() {
        return this.partnerType === '1' || this.partnerType === '2';
      },
    },
    data() {
      return {
        partnerType: window.localStorage.getItem('partnerType'), // 1 - 店铺/店铺负责人; 2 - 店铺合伙人；3 - 共享设计师
        isShow: false,
        innerShow: false,
        tableLoading: false,
        code: '',
        tableData: [],
        checkedStoreList: [], // 已选择店铺
        serviceAreaList: [],
      };
    },
    mounted() {
      console.log(1111, this.checkedStore);
      this.isShow = this.dialogVisible;
      this.checkedStoreList = this.checkedStore;
      this.code = this.storeCode;

      if (this.code !== '') {
        this.getData();
      }
    },
    methods: {
      /**
       * @description 从那个界面调用的storeSelect就返回到哪个界面
       * 1. 首页 designerOperateLayout.vue
       * 2. designerInfoComplete.vue
       */
      handleBack() {
        if (this.fromPage.type) {
          if (this.fromPage.type === 'STORE') {
            this.$store.dispatch('setCurrentComponent', {
              name: this.fromPage.value || 'COMPLETE_DESIGNER_INFO',
            });
          } else if (this.fromPage.type === 'PAGE') {
            this.$emit('handleback');
          }
        } else {
          this.$store.dispatch('setCurrentComponent', null);
        }
      },
      search() {
        // if (this.code === '') {
        //   this.$message.error('请填写商家编码');
        //   return;
        // }
        this.$refs.multipleTable.clearSelection(); // 取消选择
        this.checkedStoreList = [];

        this.getData();
      },
      reset() {
        this.code = '';
        this.tableData = [];
        this.checkedStoreList = [];
      },
      getData() {
        // if (this.code === '') {
        //   this.$message.error('请填写商家编码');
        //   return;
        // }

        if (this.storeType === '2') {
          // 申请中
          setTimeout(() => {
            this.tableData = this.checkedStoreList;
            this.tableData.forEach((e) => {
              this.$refs.multipleTable.toggleRowSelection(e, true);
            });
          });
          return;
        }
        let param = {
          key: this.code,
        };
        this.query('/api/designerExternal/getMdList', param, {
          type: 'get',
        }).then((res) => {
          if (res.status === '0') {
            if (!res.data || !res.data.dataList) {
              this.tableData = [];
              this.checkedStoreList = [];
              return;
            }

            if (this.checkedStoreList.length === 0) {
              return;
            }

            setTimeout(() => {
              this.checkedStoreList.forEach((e) => {
                this.tableData.forEach((f) => {
                  if (e.mdCode === f.mdCode) {
                    this.$refs.multipleTable.toggleRowSelection(f, true);
                  }
                });
              });
            });
          }
        });
      },
      getRowKeys(row) {
        return row.mdCode;
      },
      // 表格选择
      handleSelectionChange(val) {
        this.checkedStoreList = val;
      },
      // 查看
      view(val) {
        this.innerShow = true;
        this.tableLoading = true;
        request({
          url:
            serverUrl + '/api/designerExternal/getShopServiceArea?mdCode=' + val.mdCode + '&storeCode=' + val.storeCode,
          method: 'get',
        })
          .then((res) => {
            if (res.status === '0') {
              this.serviceAreaList = res.data;
            }
            this.tableLoading = false;
          })
          .catch(() => {
            this.tableLoading = false;
          });
      },
      confirmBtn() {
        if (this.checkedStoreList.length === 0) {
          this.$message.error('请选择门店！');
          return;
        }
        this.$emit('storeChanges', this.checkedStoreList);
        this.cancelBtn();
      },
      // 申请门店
      applyStore() {
        if (this.checkedStoreList.length === 0) {
          this.$message.error('请选择门店！');
          return;
        }
        this.$emit('storeChanges', this.checkedStoreList);
      },
      // 进入三翼鸟定制平台
      enterHome() {
        // if (this.checkedStoreList.length === 0) {
        //   this.$message.error(
        //     '尊敬的用户您好，您至少加入一家门店才能专享设计师权益！'
        //   );
        //   return;
        // }
        // this.cancelBtn();
      },
      toLogin() {
        this.$emit('toLogin');
        removeToken();
        this.$router.replace({
          path: '/zjsj/zjsjHome',
          query: {
            needReload: Math.random().toString(),
          },
        });
        this.$store.dispatch('setCurrentComponent', { name: 'LOGIN' });
      },
      cancelBtn() {
        // this.$emit('dialogHide');
        this.isShow = false;
        this.handleBack();
      },
    },
  };
</script>

<style lang="less" scoped>
  .store_select {
    box-sizing: border-box;
    padding-bottom: 30px;
    box-sizing: border-box;
    .content_base_padding {
      padding: 30px 40px 0 40px;
      box-sizing: border-box;
    }
    .search_box {
      margin-bottom: 20px;
      display: flex;
      .search_inp {
        width: 320px;
        height: 40px;
        margin-right: 20px;
        border-radius: 8px;
        .el-input__inner {
          height: 40px !important;
        }
      }
      .el-input--mini .el-input__inner {
        height: 100% !important;
      }
      .store_tip {
        display: flex;
        align-items: center;
        margin-left: 5px;
        color: #e83421;
        line-height: 28px;
      }
    }
    .store_select_table {
      color: #666;
      font-weight: 500;
      /deep/ th {
        background-color: rgba(51, 51, 51, 0.04) !important;
        border: none;
      }
      /deep/ .el-checkbox.is-checked {
        .el-checkbox__inner {
          border-color: #be965a;
          background: #be965a;
        }
      }
      /deep/ .el-checkbox {
        .el-checkbox__input.is-indeterminate {
          .el-checkbox__inner {
            border-color: #be965a;
            background: #be965a;
          }
        }
        .el-checkbox__inner {
          &:hover {
            border-color: #be965a;
          }
        }
      }
    }
    .el-pagination {
      /deep/ ul.el-pager {
        li.number.active {
          color: #be965a !important;
        }
      }
    }
    .page_block {
      text-align: right;
      margin-top: 30px;
    }
    .footer_btns {
      text-align: center;
      margin-top: 14px;
      .wid160 {
        width: 160px;
      }
    }
  }
</style>
<style lang="less">
  .store_select_pagination {
    .el-scrollbar {
      .el-select-dropdown__item.hover {
        background: rgba(51, 51, 51, 0.1);
      }
      .el-select-dropdown__item.selected {
        color: #be965a !important;
        background: rgba(190, 150, 90, 0.1);
      }
    }
  }
  .page_block {
    .el-pagination__sizes {
      .el-select {
        .el-input.is-focus {
          .el-input__inner {
            border-color: #be965a !important;
          }
        }
      }
    }
  }
  .search_inp.el-input.el-input--mini input {
    height: 40px;
    &:focus {
      border-color: rgba(190, 150, 90, 0.9);
    }
  }
</style>
