// import router from '@/router';
import store from '@/store/';
import * as mUtils from '@/utils/mUtils';
import { login, tokenlogout, tokenlog, getBtnList } from '@/api/login'; // 导入登录相关接口
import { getToken, setToken, removeToken } from '@/utils/auth';
import { Message } from 'element-ui';
import { DESIGNER_DELETE_STATE } from '@/utils/resources';

const user = {
  state: {
    userId: window.localStorage.getItem('userId') || '',
    name: window.localStorage.getItem('name') || '',
    avatar: window.localStorage.getItem('avatar') || '',
    designerRule: window.localStorage.getItem('designerRule') || '',
    token: getToken(),
    browserHeaderTitle: mUtils.getStore('browserHeaderTitle') || '设计后台管理',
    kjlOpenId: '',
    authFlag: window.localStorage.getItem('authFlag') || '',
    infoFlag: window.localStorage.getItem('infoFlag') || '',
    rejectRemark: '',
    userCurrentAuth: window.localStorage.getItem('userCurrentAuth') || '',
    userProfileState: window.localStorage.getItem('userProfileState') || '',
  },
  getters: {
    token: (state) => state.token,
    userId: (state) => state.userId,
    avatar: (state) => state.avatar,
    designerRule: (state) => state.designerRule,
    name: (state) => state.name,
    browserHeaderTitle: (state) => state.browserHeaderTitle,
    kjlOpenId: (state) => state.kjlOpenId,
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_OPEN_ID: (state, id) => {
      state.kjlOpenId = id;
    },
    SET_BROWSERHEADERTITLE: (state, action) => {
      state.browserHeaderTitle = action.browserHeaderTitle;
    },
    SET_NAME: (state, name) => {
      window.localStorage.setItem('name', name);
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      window.localStorage.setItem('avatar', avatar);
      state.avatar = avatar;
    },
    SET_DESRULE: (state, designerRule) => {
      window.localStorage.setItem('designerRule', designerRule);
      state.designerRule = designerRule;
    },
    SET_USERID: (state, userId) => {
      window.localStorage.setItem('userId', userId);
      state.userId = userId;
    },
    SET_AUTHFLAG: (state, data) => {
      window.localStorage.setItem('authFlag', data);
      state.authFlag = data;
    },
    SET_INFOFLAG: (state, data) => {
      window.localStorage.setItem('infoFlag', data);
      state.infoFlag = data;
    },
    SET_REJECTREMARK: (state, data) => {
      window.localStorage.setItem('rejectRemark', data);
      state.rejectRemark = data;
    },
    setUserCurrentAuth: (state, data) => {
      window.localStorage.setItem('userCurrentAuth', data);
      state.userCurrentAuth = data;
    },
    setUserProfileState: (state, data) => {
      window.localStorage.setItem('userProfileState', data);
      state.userProfileState = data;
    },
  },
  actions: {
    setOpenId({ commit }, openid) {
      commit('SET_OPEN_ID', openid);
    },
    // 登录
    Login({ dispatch, commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((res) => {
            if (res.status === '0') {
              if (res.data.resultFlg === false) {
                // 账号已删除
                resolve({ type: 'delete' });
                gio('track', 'CZHT30165', { // eslint-disable-line
                  third_classification_name: '设计师登录',
                  failure_reason: res.msg,
                });
                return;
              }
              window.localStorage.setItem('operationUserName', res.data.accreditVo.info?.operationUser?.name || '');
              window.localStorage.setItem('operationUserMobile', res.data.accreditVo.info?.operationUser?.mobile || '');
              // 1 - 店铺/店铺负责人; 2 - 店铺合伙人；3 - 共享设计师
              window.localStorage.setItem('partnerType', res.data.accreditVo.userIdentityVo.partnerType || '');
              // 绑定的门店列表
              window.localStorage.setItem(
                'retailStoreList',
                JSON.stringify(res.data.accreditVo.userIdentityVo?.retailStoreList)
              );
              // needId=Y:合伙人
              window.localStorage.setItem('needId', res.data.accreditVo.userIdentityVo.needId);
              // 关联店铺
              if (res.data.accreditVo.userIdentityVo.storeCode) {
                window.localStorage.setItem('storeInfo', JSON.stringify(res.data.accreditVo.userIdentityVo));
              }
              // 用户信息
              let userStorage = {
                lastName: res.data.accreditVo.info.user.lastName,
                access_token: '',
                token: res.data.accreditVo.token,
                username: res.data.accreditVo.info.user.firstName,
                userid: res.data.accreditVo.info.user.id,
                userType: res.data.accreditVo.info.user.type,
                userLabel: res.data.accreditVo.info.mallIdentityValue,
                uacUserId: res.data.accreditVo.info.user.uacUserId,
                avatar: '',
                industryTrade: res.data.accreditVo.info.user.industryTrade,
                industryTypeValue: res.data.accreditVo.info.industryTypeValue,
                designerPhone: res.data.accreditVo.info.mobileDesc,
              };
              if (res.data && res.data.access_token) {
                userStorage.access_token = res.data.access_token;
              }
              if (res.data.accreditVo.info && res.data.accreditVo.info.userProfile) {
                userStorage.avatar = res.data.accreditVo.info.userProfile.avatarFpath || '';
                // 认证后是否登录 0否1是
                window.localStorage.setItem('loginAfterAuth', res.data.accreditVo.info.userProfile?.loginAfterAuth);
              }

              dispatch('loginSuccess', userStorage);
              window.localStorage.setItem('isRegisterComplete', '1'); // 已完成注册流程

              const { infoFlag, authFlag, rejectRemark, state } = res.data.accreditVo.info.userProfile;
              // infoFlag - 设计师资料完善标识，0：未完善 1：已完善
              // authFlag - 设计师认证标识：0：未认证 1：审核中 2：认证成功 3：认证失败
              commit('SET_AUTHFLAG', authFlag);
              commit('SET_INFOFLAG', infoFlag);
              commit('setUserProfileState', state);
              commit('setUserCurrentAuth', res.data.accreditVo?.info?.userCurrentAuthVo?.auth);
              if (Number(authFlag) === 3) {
                commit('SET_REJECTREMARK', rejectRemark);
              }
              if (Number(infoFlag) === 0 || (Number(infoFlag) === 1 && Number(authFlag) !== 2)) {
                // 认证未完成
                // handleLoginAuth(res.data.accreditVo.info.userProfile);
                dispatch('whiteRoutes'); // 路由白名单
                dispatch('accessRouters', getToken());
                window.localStorage.setItem('isRegisterComplete', '0');
                resolve({
                  type: 'registering',
                  data: res.data,
                });
                return;
              }
              resolve({ type: 'success', data: res.data });
            } else {
              if (store.state.LoginRegistreStore.currentComponent === 'LOGIN') {
                gio('track', 'CZHT30165', { // eslint-disable-line
                  third_classification_name: '设计师登录',
                  failure_reason: res.msg,
                });
              }

              if (store.state.LoginRegistreStore.currentComponent === 'REGISTER') {
                gio('track', 'CZHT30169', { // eslint-disable-line
                  failure_reason: res.msg,
                });
              }
              resolve({
                type: 'error',
                data: res,
              });
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 通过token获取用户信息
    tokenLogin({ dispatch }, tok) {
      return new Promise((resolve, reject) => {
        tokenlog(tok)
          .then((res) => {
            if (res.status !== '0' || !res.data) {
              removeToken();
              let url = window.location.origin + window.location.pathname + '#/login';
              window.location.replace(url);
              location.reload();
              return;
            }
            let { user, userProfile } = res.data;
            // 用户信息
            let userStorage = {
              access_token: decodeURIComponent(window.localStorage.getItem('access_token')),
              token: getToken(),
              username: user.firstName,
              userid: user.id,
              userType: user.type,
              userLabel: res.data.mallIdentityValue,
              uacUserId: user.uacUserId,
              avatar: userProfile.avatarFpath || '',
              industryTrade: user.industryTrade,
              industryTypeValue: res.data.industryTypeValue,
              designerPhone: res.data.mobileDesc,
            };

            dispatch('loginSuccess', userStorage);
            window.localStorage.setItem('isRegisterComplete', '1'); // 已完成注册流程

            const { infoFlag, authFlag } = userProfile;
            // labelFlag - 设计师标签选择标识，0：未维护 1：已维护
            // infoFlag - 设计师资料完善标识，0：未完善 1：已完善
            // authFlag - 设计师认证标识：0：未认证 1：审核中 2：认证成功 3：认证失败
            if (Number(infoFlag) === 0 || (Number(infoFlag) === 1 && Number(authFlag) !== 2)) {
              // 认证未完成
              handleLoginAuth(userProfile);
              window.localStorage.setItem('isRegisterComplete', '0'); // 未完成注册流程
            }

            resolve();
          })
          .catch((error) => {
            if (error) {
              Message({
                message: error.msg ? error.msg : '登录失败',
                type: 'error',
                duration: 3 * 1000,
              });
            }
            reject(error);
          });
      });
    },
    loginSuccess({ dispatch, commit }, data) {
      // 存入缓存，避免页面刷新后vuex被清空
      window.localStorage.setItem('access_token', data.access_token || '');
      window.localStorage.setItem('avatar', data.avatar || '');
      window.localStorage.setItem('name', data.username || '');
      window.localStorage.setItem('userId', data.userid || '');
      window.localStorage.setItem('userType', data.userType || ''); // 0:官方设计师, 1:店铺设计师, 3:共享设计师, 5:星云平台专属, 50:超管
      window.localStorage.setItem('uacUserId', data.uacUserId || ''); // 用户中心id
      window.localStorage.setItem('userLabel', data.userLabel || ''); // 用户标签：店铺合伙人、直销员等等
      window.localStorage.setItem('industryTrade', data.industryTrade || ''); // 所属工贸：青岛工贸、北京工贸等等
      window.localStorage.setItem('industryTypeValue', data.industryTypeValue || ''); // 所属产业：三翼鸟、厨房等等
      window.localStorage.setItem('designerPhone', data.designerPhone || ''); // 所属产业：三翼鸟、厨房等等
      window.localStorage.setItem('lastName', data.lastName || ''); // 所属产业：三翼鸟、厨房等等
      /* eslint-disable */
      if (gio) {
        gio('setUserId', data.uacUserId);
      }
      /* eslint-enable */
      /**
       * 新增请求头header  区分平台
        platform - design:设计师平台, operation:运营平台
      */
      window.localStorage.setItem('platform', 'design'); // design:设计师平台
      // 登录成功后获取token; token保存方式为：setToken,'SET_TOKEN';
      setToken(data.token);
      commit('SET_TOKEN', data.token);
      commit('SET_NAME', data.username);
      commit('SET_USERID', data.userid);
      dispatch('getBtnLimits');
    },
    async getBtnLimits() {
      // 按钮权限
      let btnList = await getBtnList();
      let btn = btnList.data.map((b) => {
        return b.code;
      });
      window.localStorage.setItem('btnList', JSON.stringify(btn));
    },
    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        if (!getToken()) {
          // 如果通过其他途径清除token缓存，直接刷新
          commit('SET_TOKEN', '');
          removeToken();
          window.location.reload();
          return;
        }
        tokenlogout()
          .then(() => {
            gio("clearUserId"); // eslint-disable-line
            commit('SET_TOKEN', '');
            removeToken();
            resolve();
          })
          .catch((error) => {
            Message({
              message: '退出失败',
              type: 'error',
            });
            reject(error);
          });
      });
    },
    setAuthFlag({ commit }, authFlag) {
      commit('SET_AUTHFLAG', authFlag);
    },
    setInfoFlag({ commit }, infoFlag) {
      commit('SET_INFOFLAG', infoFlag);
    },
  },
};
// 处理未认证完成
function handleLoginAuth(userProfile) {
  // 解绑状态不提示驳回
  if (userProfile.state === DESIGNER_DELETE_STATE) return;
  let { rejectRemark, infoFlag, authFlag } = userProfile;
  window.localStorage.setItem('rejectRemark', rejectRemark);
  let query = {
    isReject: false,
    content: '抱歉，您的设计师认证资料审核失败，请重新认证。',
    rejectRemark: '驳回原因：' + rejectRemark,
    confirmButtonText: '去认证',
    confirmButtonStyle: { width: '112px', height: '44px' },
    onConfirm: () => {
      if (Number(infoFlag) === 0) {
        store.dispatch('setCurrentComponent', {
          name: 'SELECT_DESIGNER_TYPE',
        });
      } else if (Number(infoFlag) === 1 && Number(authFlag) !== 2) {
        store.dispatch('setCurrentComponent', {
          name: 'COMPLETE_DESIGNER_INFO',
        });
      }
    },
    onClose: () => {
      store.dispatch('setCurrentComponent', null);
    },
  };
  if (Number(authFlag) === 0) {
    // 未认证
    // if (infoFlag === '0') {
    //   // 信息未填
    //   store.dispatch('setCurrentComponent', {
    //     name: 'SELECT_DESIGNER_TYPE',
    //   });
    // } else if (infoFlag === '1') {
    //   store.dispatch('setCurrentComponent', {
    //     name: 'COMPLETE_DESIGNER_INFO',
    //   });
    // }
  }
  if (Number(authFlag) === 3) {
    // 认证审核失败
    query.isReject = true;
    store.dispatch('setCurrentComponent', {
      name: 'AUTH_ERROR',
      query,
    });
  }
}
export default user;
