<template>
  <div class="comm_layout">
    <component :is="Layout"></component>
    <el-dialog
      v-if="currStore"
      class="home_layout_dialog"
      :width="dialogWidth"
      top="'0vh'"
      :title="dialogTitle"
      :show-close="showClose"
      :before-close="handleBeforeClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="destroyOnClose"
      :visible="currStore">
      <component
        v-if="currStore"
        :is="currentComponent.component"
        v-bind="currentComponent.props"
        v-on="currentComponent.props && currentComponent.props.event ? currentComponent.props.event : {}"></component>
    </el-dialog>
  </div>
</template>

<script>
  import LayoutDesigner from '@/layout/zjdzHome'; // 设计师前台 - 页面整体布局
  import LayoutOperate from '@/layout/zjdzHomeOperate'; // 运营人员前台 - 页面整体布局
  import LoginBase from '@/views/register/login.vue';
  import RegisterAccount from '../views/register/registerAccount.vue';
  import ForgetPassword from '../views/register/forgetPassword.vue';
  import DesignerAuthStep from '@/views/register/designerAuthStep.vue';
  import DesignerRevieDialog from '@/views/register/component/designerRevieDialog.vue';
  import StoreSelect from '@/views/register/component/storeSelect.vue';
  import { serverUrl } from '@/utils/env';
  import request from '@/utils/axios';
  import { getToken } from '@/utils/auth';
  import { DESIGNER_DELETE_STATE } from '@/utils/resources';
  const STORE_MAP_COMPONENT = new Map([
    [
      ['LOGIN'],
      {
        component: LoginBase,
        width: '940px',
      },
    ], // 登录功能
    [
      ['REGISTER'],
      {
        width: '940px',
        component: RegisterAccount,
      },
    ], // 注册功能
    [
      ['FORGET_PASSWORD'],
      {
        width: '940px',
        component: ForgetPassword,
      },
    ], // 忘记密码和重置密码
    [
      ['SELECT_DESIGNER_TYPE', 'COMPLETE_DESIGNER_INFO'],
      {
        width: '50%',
        component: DesignerAuthStep,
      },
    ], // 选择设计师类型 认证流程
    [
      ['AUTH_ERROR'],
      {
        width: '424px',
        component: DesignerRevieDialog,
      },
    ], // 设计师审核失败
    [
      ['STORE_SELECT'],
      {
        width: '960px',
        component: StoreSelect,
      },
    ], // 设计师审核失败
  ]);
  export default {
    name: 'DesignOperateLayout',
    components: {
      LoginBase,
      RegisterAccount,
      ForgetPassword,
      DesignerAuthStep,
      DesignerRevieDialog,
      StoreSelect,
    },
    data() {
      return {
        storeSelect: {
          storeType: null,
          retailStoreList: [],
          customName: '',
          customCode: '',
        },
        isDesigner: window.localStorage.getItem('platform') === 'design', // design:设计师平台, operation:运营平台
        dialogWidth: '424px',
        showClose: false,
        Layout: null,
        currentComponent: {
          component: {
            component: null,
            width: 0,
          },
          props: null,
        },
        dialogTitle: null,
        destroyOnClose: true,
      };
    },
    computed: {
      currStore() {
        return this.$store.state.LoginRegistreStore.currentComponent ? true : false;
      },
    },
    watch: {
      '$store.state.LoginRegistreStore.currentComponent': function (value) {
        this.mapStore2Component(value);
      },
      '$route.query': function (query) {
        if (query.needReload) {
          this.init(query.needReload);
        }
      },
    },
    async mounted() {
      let platform = window.localStorage.getItem('platform');
      this.Layout = platform === 'design' ? LayoutDesigner : LayoutOperate;
      // 在这个组件里接受Store数据 控制el-dialog的显示
      this.mapStore2Component(this.$store.state.LoginRegistreStore.currentComponent);
      await this.init();
    },
    methods: {
      async init(type) {
        this.isDesigner = window.localStorage.getItem('platform') === 'design';
        if (getToken() && this.isDesigner) {
          await this.getShopPartnerApplyStatus(type);
        }
      },
      // 验证店铺合伙人
      getShopPartnerApplyStatus(type) {
        console.log(type);
        request({
          url: serverUrl + '/api/userInfo/getShopPartnerApplyStatus',
          method: 'post',
          data: {},
        }).then((res) => {
          if (res.status === '0') {
            if (res.data.bindStatus === '2') {
              // 申请被驳回
              this.storeSelect.storeType = '1';
            }
            if (res.data.bindStatus === '0') {
              // 申请中
              this.storeSelect.storeType = '2';
            }
            if (res.data.isShopPartner && res.data.bindStatus !== '1') {
              this.storeSelect.customName = res.data.customName; // 店铺名称
              this.storeSelect.customCode = res.data.customCode; // 店铺编码
              // 门店回显
              if (res.data.applyStoreInformations && res.data.applyStoreInformations.length > 0) {
                this.storeSelect.retailStoreList = res.data.applyStoreInformations.map((e) => {
                  return {
                    storeCode: res.data.customCode,
                    storeName: res.data.customName,
                    mdCode: e.storeCode,
                    mdName: e.storeName,
                  };
                });
              }
              // 没有被解除身份 state!==9
              let userProfileState = this.$store.state.user.userProfileState !== DESIGNER_DELETE_STATE;
              if (this.$store.state.user.authFlag === '2' && userProfileState) {
                this.$store.dispatch('setCurrentComponent', {
                  name: 'STORE_SELECT',
                  query: {
                    storeType: this.storeSelect.storeType,
                    storeCode: this.storeSelect.customCode,
                    checkedStore: this.storeSelect.retailStoreList,
                    event: {
                      storeChanges: (val) => {
                        this.bindStore(val);
                      },
                    },
                    showBack: false,
                    fromPage: {
                      type: 'STORE',
                      value: '',
                    },
                  },
                });
              }
            }
          }
        });
      },
      bindStore(val) {
        this.storeSelect.retailStoreList = val;

        if (val.length === 0) {
          this.$message.error('您还未申请加入门店，请去申请加入合作门店！');
          return;
        }
        let params = val.map((e) => {
          return {
            storeCode: this.storeSelect.customCode,
            storeName: this.storeSelect.customName,
            mdCode: e.mdCode,
            mdName: e.mdName,
          };
        });

        request({
          url: serverUrl + '/api/userInfo/applyOrUnbindStore?applyType=0',
          method: 'post',
          data: params,
        }).then((res) => {
          if (res.status === '0') {
            this.$message.success('申请成功');
            this.storeSelect.storeType = '2'; // 申请中
            this.$store.dispatch('setCurrentComponent', null);
            this.$router.replace({
              path: this.$route.path,
              query: {
                ...this.$route.query,
                needReload: Math.random().toString(),
              },
            });
          }
        });
      },
      /**
       * @description 根据Vuex的值 映射组件
       * @param {String} storeValue vuex的存储值
       */
      mapStore2Component(storeValue) {
        if (storeValue) {
          for (const [key, value] of STORE_MAP_COMPONENT) {
            if (key.includes(storeValue)) {
              let component = value;
              this.dialogWidth = component.width;
              this.currentComponent = {
                component: component.component,
                props: {
                  ...this.$store.state.LoginRegistreStore.currentComponentQuery,
                },
              };
            }
          }
        }
      },
      handleBeforeClose() {
        this.$store.dispatch('setCurrentComponent', null);
      },
    },
  };
</script>
<style lang="less">
  .comm_layout {
    .home_layout_dialog {
      .el-dialog {
        margin: 0 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        .el-dialog__header {
          padding: 0;
        }
        .el-dialog__body {
          padding: 0;
          border-radius: 8px;
          overflow: hidden;
        }
      }
    }
  }
</style>
