<template>
  <div class="cascader_box">
    <el-cascader
      :disabled="disabled"
      v-loading="loading"
      element-loading-custom-class="syn-zjsj-front-loading"
      style="width: 100%"
      placeholder="请选择省市区街道"
      v-model="fourCodeData"
      size="medium"
      :popper-class="selectAreaPopperClass"
      @change="fourCodeChangeAction"
      :props="fourCodeProps"
      ref="cascaderAddr"></el-cascader>
  </div>
</template>
<script>
  import request from '@/utils/axios';

  export default {
    name: 'zjsjLazyCascader',
    props: {
      fourCode: {
        type: Array,
        default: () => {
          return [];
        },
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      checkStrictly: {
        type: Boolean,
        default: false,
      },
      expandTrigger: {
        type: String,
        default: 'click',
      },
      areaPopperClass: {
        // 如果需要黄色主题的class名，请在这里添加'multi-designerManage-search-hide-checkbox'
        type: String,
        default: '',
      },
    },
    watch: {
      fourCode: {
        handler(newValue) {
          this.fourCodeData = newValue;
        },
        immediate: true,
      },
    },
    computed: {
      selectAreaPopperClass() {
        return this.areaPopperClass ? `${this.areaPopperClass} 'four-address-list'` : 'four-address-list';
      },
    },
    data() {
      return {
        fourCodeData: [],
        loading: false,
        fourCodeProps: {
          checkStrictly: this.checkStrictly,
          value: 'regionCode',
          label: 'regionName',
          lazy: true,
          expandTrigger: this.expandTrigger,
          lazyLoad: (node, resolve) => {
            this.loading = true;
            let { level } = node;
            const regionCode = (node.data && node.data.regionCode) || '000000';
            request({
              url: `/server/order/basic/work/address/getAddressByPid?regionCode=${regionCode}`,
              method: 'get',
            })
              .then((res) => {
                let filterData = (res.data || []).filter((item) => {
                  return item.levels == level;
                });
                // 最后一级数据不显示请求箭头、不再触lazyLoad
                if (node.level === 3) {
                  filterData.forEach((i) => {
                    i.leaf = node.level;
                  });
                }
                const nodes = filterData;
                resolve(nodes);
              })
              .finally(() => {
                this.loading = false;
              });
          },
        },
      };
    },
    mounted() {},
    methods: {
      fourCodeChangeAction(a) {
        let node = this.$refs['cascaderAddr'].getCheckedNodes()[0];
        let c = node.pathLabels;

        this.$emit('cascaderValue', { code: a, name: c, level: node.level });
      },
    },
  };
</script>
<style lang="less" scoped>
  .cascader_box {
    .four-address-list {
      .el-cascader-menu__wrap {
        height: 300px;
      }
    }
  }
</style>
