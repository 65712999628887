/*
 * @Author: liujiawei liujiawei@haier.com
 * @Date: 2023-02-20 17:39:33
 * @LastEditors: Please set LastEditors
 * @Description:
 */
import axios from 'axios';
import Vue from 'vue';
import { Message, MessageBox } from 'element-ui';
import qs from 'qs';
import { getHeaders } from '@/utils/getHttpHeader';
import { removeToken } from '@/utils/auth';
// import router from '@/router';
import { isIframe } from '@/utils/mUtils';
import { mockUrlTran } from '@/utils/mockUrlHandle';
import store from '@/store';
import router from '@/router';
let confirm401 = false;
const IS_MOCK = process.env.VUE_APP_ENV_IS_MOCK === 'mock';

// // 创建axios实例
// let service = axios.create({
//   baseURL: process.env.BASE_API, // api的base_url
//   timeout: 120000, // 请求超时时间
// });
// request拦截器

axios.interceptors.request.use(
  (config) => {
    // mock环境下对请求的url进行mock处理
    if (IS_MOCK) {
      config.url = mockUrlTran(config.url);
    }
    let header = getHeaders();
    config.headers = header;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// respone拦截器
axios.interceptors.response.use(
  (response) => {
    let url = response.config.url;
    let contentType = response.headers['content-type'];
    // /api/haier_kjl_case/exportJdList/  家电清单下载
    // /api/haier_kjl_case/exportJzList/  家装清单下载
    // /batch/userInfo/userInfoExport  设计师列表导出
    // /api/haier_kjl_case/apiTransfer  酷家乐销售提案书生成
    // /api/haier_kjl_case/apiTransfer1  酷家乐销售提案书任务进度
    let downloadUrls = [
      '/api/haier_kjl_case/exportJdList/',
      '/api/haier_kjl_case/exportJzList/',
      '/batch/userInfo/userInfoExport',
      '/api/haier_kjl_case/apiTransfer',
      '/api/haier_kjl_case/apiTransfer1',
    ];
    let zipDownloadUrls = [
      '/api/saleModel/download/modelFile/batch',
      '/api/sale_model/download/model_file',
      '/server/order/work/home/downloadPic',
      '/server/batch/operationUser/export',
      '/server/batch/dept/export',
      '/server/batch/quick/list/export',
      '/server/zjsj/haier_kjl_case/downloadPic',
      '/server/zjsj/haier_kjl_case/exportList',
      '/server/batch/stat/exportAccountList',
      '/server/batch/stat/exportQuickDesignData',
      '/server/order/work/stat/exportStatOrderList',
      '/server/batch/stat/exportDesignData',
      '/server/batch/custom/case/list/export',
      '/haier/file/upload',
      '/api/saleModel/download/model_file',
      '/server/zjsj/drawing/downloadFile',
    ];
    let errorList = ['/server/order/work/submitQuotationCheck'];
    let zipIncludeDownload = zipDownloadUrls.map((d) => {
      return url.includes(d);
    });
    let includeDownload = downloadUrls.map((d) => {
      return url.includes(d);
    });
    let errorMap = errorList.map((d) => {
      return url.includes(d);
    });
    let headerTypes = [
      'multipart/form-data;charset=utf-8',
      'application/vnd.ms-excel;charset=utf-8',
      'text/html;charset=utf-8',
      'image/png;charset=UTF-8',
    ];
    if (response.config.__downloadRes) {
      return response;
    }
    // 报错列表单独处理
    if (errorMap.includes(true)) {
      return response;
    }
    // 设计师投稿审核导出单独处理
    if (url.includes('batch/contribute/auditList/export')) {
      return response;
    }
    // 下载家电报价单或家装报价单单独处理
    if (contentType === 'application/json;charset=UTF-8' && includeDownload.includes(true)) {
      return response;
    }
    //zip下载单独处理
    if (zipIncludeDownload.includes(true)) {
      return response;
    }
    // 对设计师说菜单进行单独处理
    if (headerTypes.includes(contentType) || url.includes('api/user/docking_user_center')) {
      return response.data;
    }
    const res = response.data;
    // 新项目成功为 code = 0
    let codeList = [0, -2, -3, 1, '0'];
    // 老项目成功为status = 0
    let statusList = ['0', '2'];
    if (statusList.includes(res.status) || codeList.includes(res.code)) {
      if (response.config.url.includes('api/userInfo/applyOrUnbindStore') && response.config.method === 'post') {
        if (response.data.status === '2') {
          Message({
            message: res.msg ? res.msg : res.data ? res.data : '系统繁忙请稍后重试',
            type: 'error',
            duration: 5 * 1000,
          });
        }
      }
      return res;
    } else {
      // 该接口不做提示
      let ignoreErrorApiList = [
        '/by_t/',
        '/iam/getTokenAndUserInfo',
        '/register/verifyEmailOrNo',
        '/zjsj/haierhome/user/getRetailStore',
      ];
      let isIgnoreError = ignoreErrorApiList.some((item) => {
        return url.includes(item);
      });
      if (isIgnoreError || response.config.ignoreError) {
        return response.data;
      }
      Message({
        message: res.msg ? res.msg : res.data ? res.data : '系统繁忙请稍后重试',
        type: 'error',
        duration: 5 * 1000,
      });
      return response.data;
    }
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Message.closeAll();
        // 星云 - 登录超时 - 不退出
        if (isIframe()) {
          return;
        }

        // removeToken();
        // 请求超时是否退出
        // Message({
        //   message: '登录超时，请重新登录',
        //   type: 'error',
        //   duration: 3 * 1000,
        //   onClose() {
        //     router.push({
        //       path: '/zjsj/zjsjHome',
        //       needReload: Math.random().toString(),
        //     });
        //     store.dispatch('setCurrentComponent', { name: 'LOGIN' });
        //   },
        // });
        if (confirm401) return;
        console.log('window.location.href', window.location.href);
        // let isHome = window.location.href.indexOf('/zjsj/zjsjHome') > -1;
        MessageBox.confirm('登录超时，请重新登录', '提示', {
          confirmButtonText: '跳转',
          // confirmButtonClass: isHome ? 'go_to_login_confirm_btn' : '',
          confirmButtonClass: 'go_to_login_confirm_btn',
          type: 'warning',
          showClose: false,
          showCancelButton: false,
          closeOnPressEscape: false,
          closeOnClickModal: false,
        })
          .then(() => {
            router.push({
              path: '/zjsj/zjsjHome',
              needReload: Math.random().toString(),
            });
            removeToken();
            store.dispatch('setCurrentComponent', { name: 'LOGIN' });
            confirm401 = false;
          })
          .catch(() => {
            router.push({
              path: '/zjsj/zjsjHome',
              needReload: Math.random().toString(),
            });
            removeToken();
            confirm401 = false;
          });
        removeToken();
        confirm401 = true;
      } else {
        Message({
          message: '网络连接异常,请检查您的网络',
          type: 'error',
          duration: 5 * 1000,
        });
      }
    }
    return Promise.reject();
  }
);
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
export default axios;
