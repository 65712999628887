<template>
  <div class="home">
    <!-- 设计师 - 前台 -->
    <header-com></header-com>
    <!--页面渲染入口-->
    <keep-alive :include="componentName">
      <router-view class="home_content"></router-view>
    </keep-alive>
    <!-- 返回顶部 -->
    <el-backtop :visibility-height="scrollHeight" :right="20" :bottom="120">
      <div class="home_back_top">
        <div class="bgcIcon back_top_icon"></div>
      </div>
    </el-backtop>
    <div class="footer" v-if="fotterStats">
      <HomeFooter />
    </div>
  </div>
</template>
<script>
  import HeaderCom from './headerCom.vue';
  import { keepAliveName } from './keepAlive.js';
  import HomeFooter from './homeFooter.vue';

  let throughTopStylePath = ['/zjsj/designerStar', '/zjsj/zjsjHome'];
  export default {
    name: 'zjdzHome',
    components: {
      HeaderCom,
      HomeFooter,
    },
    data() {
      return {
        showFotter: false,
        setHeight: () => null,
        scrollHeight: window.innerHeight || 800, // 滚动高度达到此参数值，返回顶部出现
        componentName: keepAliveName,
      };
    },
    watch: {
      '$route.path': function (val) {
        this.showFotter = !throughTopStylePath.includes(val);
      },
    },
    created() {
      this.showFotter = !throughTopStylePath.includes(this.$route.path);
    },
    computed: {
      fotterStats() {
        return this.showFotter && this.$store.state.footerStore.show;
      },
    },
    mounted() {
      document.querySelector('html').setAttribute('style', 'overflow: auto; height: auto;');
    },
    beforeDestroy() {},
    methods: {},
  };
</script>
<style scoped lang="less">
  .home {
    min-width: 1420px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .home_content {
      min-height: calc(100vh - 72px);
      box-sizing: border-box;
    }
    /deep/ .el-backtop {
      box-shadow: none !important;
    }
    .home_back_top {
      padding: 8px;
      background: rgba(0, 0, 0, 0.4);
      // box-shadow: 0px 1px 5px 0px rgba(51, 51, 51, 0.15);
      border-radius: 50%;
      overflow: hidden;
      &:hover {
        background: #ffffff;
        .back_top_icon {
          background-position: -549px 0;
        }
      }
      .back_top_icon {
        width: 24px;
        height: 24px;
        background-position: -488px 0;
      }
    }
  }
</style>
