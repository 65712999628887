const BrowserLogger = require('@arms/js-sdk');
export function initArms() {
  BrowserLogger.singleton({
    pid: 'hp8r7tbybs@3ad2d8c9f02b8ba',
    appType: 'web',
    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
    environment: process.env.VUE_APP_ARMS_ENV,
    enableSPA: true,
    // 设定日志上传地址：
    // 部署新加坡地域可设为`https://arms-retcode-sg.aliyuncs.com/r.png?`。
    // 部署美西地域可设为`https://retcode-us-west-1.arms.aliyuncs.com/r.png?`。
    imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
    // 其他config配置。
  });
}
