import { Layout } from './layout';
export const DataCockPitRouter = {
  path: '/dataCockpit',
  component: Layout,
  meta: {
    title: '数据驾驶舱',
    icon: 'el-icon-s-home',
  },
  noDropdown: true,
  children: [
    {
      path: 'dashboard',
      meta: {
        title: '仪表盘',
        routerType: 'leftmenu',
      },
      component: () => import('@/page/dataCockpit/dashboard'),
    },
    {
      path: 'account',
      meta: {
        title: '账号数据',
        routerType: 'leftmenu',
      },
      component: () => import('@/page/dataCockpit/account'),
    },
    {
      path: 'order',
      meta: {
        title: '工单数据',
        routerType: 'leftmenu',
      },
      component: () => import('@/page/dataCockpit/order'),
    },
    {
      path: 'case_data',
      meta: {
        title: '方案数据',
        routerType: 'leftmenu',
      },
      component: () => import('@/page/dataCockpit/caseData'),
    },
    {
      path: 'rights_data',
      meta: {
        title: '权益池明细',
        routerType: 'leftmenu',
      },
      component: () => import('@/page/dataCockpit/rightsDistributionData'),
    },
    {
      path: 'quickdesign',
      meta: {
        title: '快设计数据',
        routerType: 'leftmenu',
      },
      component: () => import('@/page/dataCockpit/quickDesign'),
    },
    {
      path: 'abnormaldata',
      meta: {
        title: '方案数据同步',
        routerType: 'leftmenu',
      },
      component: () => import('@/page/dataCockpit/abnormalData'),
    },
    {
      path: 'metabaseLayout/:pageId',
      meta: {
        title: '数据驾驶舱',
        routerType: 'leftmenu',
      },
      component: () => import('@/layout/metabaseLayout'),
    },
  ],
};
