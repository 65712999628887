import { render, staticRenderFns } from "./registerAccount.vue?vue&type=template&id=908bbf4c&scoped=true"
import script from "./registerAccount.vue?vue&type=script&lang=js"
export * from "./registerAccount.vue?vue&type=script&lang=js"
import style0 from "./registerAccount.vue?vue&type=style&index=0&id=908bbf4c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "908bbf4c",
  null
  
)

export default component.exports