/**
 * @description 海尔家居相关接口定义
 */

import request from '@/utils/axios';
import { serverUrl } from '@/utils/env';
import { isArray, queryString } from '@/utils/tools';

export function getUserStoreList(userId, uacUserId) {
  let queryStr = queryString({ userId, uacUserId });
  return new Promise((resolve, reject) => {
    request({
      url: `${serverUrl}/server/zjsj/haierhome/user/getRetailStore?${queryStr}`,
      method: 'get',
    })
      .then((res) => {
        if (res && res.code === '0') {
          resolve(flatStoreList(res.data));
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function flatStoreList(storeList) {
  let list = [];
  if (isArray(storeList)) {
    storeList.map((item) => {
      if (isArray(item.items)) {
        item.items.forEach((mdItem) => {
          list.push({
            haierHomeUserId: item.userId, // 海尔家居UserId
            storeCode: item.storeCode,
            storeName: item.storeName,
            code: mdItem.code,
            value: mdItem.value,
          });
        });
      }
    });
    return list;
  }
  return list;
}
