<template>
  <div class="login_home">
    <el-row>
      <el-col :span="8">
        <div class="carousel_part">
          <div class="syn_logo"></div>
          <!-- 轮播 -->
          <carousel :imgList="carouselList" :carData="carData" />
        </div>
      </el-col>
      <el-col :span="16">
        <div class="content_page">
          <!--页面渲染入口-->
          <router-view></router-view>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import carousel from '@v/zjsj/components/carousel.vue';
  import hom_banner1 from '@/assets/img/login/hom_banner1.jpg';
  import hom_banner2 from '@/assets/img/login/hom_banner2.jpg';
  import hom_banner3 from '@/assets/img/login/hom_banner3.jpg';

  export default {
    name: 'loginHome',
    data() {
      return {
        carouselList: [
          { id: 1, fpath: hom_banner1 },
          { id: 2, fpath: hom_banner2 },
          { id: 3, fpath: hom_banner3 },
        ],
        carData: {
          arrow: 'never',
          interval: 3000,
          height: '100vh',
          indicator: 'none',
        },
      };
    },
    components: {
      carousel,
    },
    created() {},
    mounted() {},
    methods: {},
  };
</script>
<style scoped lang="less">
  .login_home {
    min-width: 1200px;
    .carousel_part {
      .syn_logo {
        position: fixed;
        z-index: 10;
        left: 30px;
        top: 30px;
        display: inline-block;
        width: 312px;
        height: 60px;
        background-image: url('~@/assets/img/zjdz/logo-full.png');
        background-size: cover;
      }
    }
    .content_page {
      height: 100vh;
      overflow-y: auto;
    }
  }
</style>
