import { isIframe } from '@/utils/mUtils';
import LayoutMgr from '../layout/home'; // 页面整体布局 - 带header、leftMenu
import LayoutMgrBlank from '../Layouts/Blank/BlankLayout'; // 页面整体布局 - 不带header、leftMenu

// 根据是否为iframe修改layout布局
let Layout = isIframe() ? LayoutMgrBlank : LayoutMgr;

export default [
  {
    path: '/publication',
    name: 'publication',
    component: Layout,
    meta: {
      title: '样板间投稿',
      icon: 'el-icon-message',
    },
    noDropdown: true,
    children: [
      {
        path: 'publicationList',
        name: 'publicationList',
        component: () => import('@/page/publication/publicationList/publicationList'),
        meta: {
          title: '投稿活动',
          routerType: 'leftmenu',
        },
      },
      {
        path: 'ownPublication',
        name: 'ownPublication',
        component: () => import('@/page/publication/ownPublication/ownPublication'),
        meta: {
          title: '我的投稿',
          routerType: 'leftmenu',
        },
      },
      {
        path: 'publicationDetail',
        name: 'publicationDetail',
        component: () => import('@/page/publication/ownPublication/publicationDetail/publicationDetail'),
      },
      {
        path: 'activityList',
        name: 'activityList',
        component: () => import('@/page/contribution/activity/activityList'),
        meta: {
          title: '投稿活动管理',
          routerType: 'leftmenu',
        },
      },
      {
        path: 'electricalList',
        name: 'electricalList',
        component: () => import('@/page/contribution/activity/electricalList'),
        meta: {
          title: '家电套系管理',
          routerType: 'leftmenu',
        },
      },
      {
        path: 'activityEdit',
        name: 'activityEdit',
        component: () => import('@/page/contribution/activity/activityEdit'),
        meta: {
          title: '活动编辑',
          routerType: 'leftmenu',
        },
      },
      {
        path: 'electricalEdit',
        name: 'electricalEdit',
        component: () => import('@/page/contribution/activity/electricalEdit'),
        meta: {
          title: '套系编辑',
          routerType: 'leftmenu',
        },
      },
    ],
  },
  {
    path: '/submissionReview',
    name: 'submissionReview',
    component: Layout,
    meta: {
      title: '设计师投稿',
      icon: 'el-icon-message',
    },
    noDropdown: true,
    children: [
      {
        path: 'list',
        name: 'submissionReviewList',
        component: () => import('@/page/submissionReview/list'),
        meta: {
          title: '设计师投稿审核',
        },
      },
      {
        path: 'detail',
        name: 'submissionReviewDetail',
        component: () => import('@/page/submissionReview/detail'),
        meta: {
          title: '设计师投稿审核',
        },
      },
      {
        path: 'detail_edit',
        name: 'submissionReviewDetailEdit',
        component: () => import('@/page/submissionReview/detailEdit'),
        meta: {
          title: '设计师投稿审核编辑',
        },
      },
      {
        path: 'quickDesign',
        name: 'quickDesign',
        component: () => import('@/page/submissionReview/quickDesign'),
        meta: {
          title: '快搭设计管理',
        },
      },
      {
        path: 'quickDesignDetail',
        name: 'quickDesignDetail',
        component: () => import('@/page/submissionReview/quickDesignDetail'),
        meta: {
          title: '快搭设计管理详情',
        },
      },
      {
        path: 'onlineDesignManage',
        name: 'onlineDesignManage',
        component: () => import('@/page/submissionReview/onlineDesignManage'),
        meta: {
          title: '在线设计方案管理',
        },
      },
      {
        path: 'onlineDesignManageDetail',
        name: 'onlineDesignManageDetail',
        component: () => import('@/page/submissionReview/onlineDesignManageDetail'),
        meta: {
          title: '在线设计方案管理详情',
        },
      },
      {
        path: 'tagList',
        name: 'tagList',
        component: () => import('@/page/submissionReview/tagList'),
        meta: {
          title: '标签管理',
        },
      },
    ],
  },
];
