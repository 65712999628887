<template>
  <div class="designer_info_complete">
    <el-form
      :inline="true"
      label-position="right"
      label-width="100px"
      :model="designerInfo"
      ref="completeForm"
      :rules="rules">
      <el-form-item label="昵称" prop="nickName">
        <el-input
          type="text"
          v-model="designerInfo.nickName"
          maxlength="10"
          autocomplete="off"
          placeholder="昵称必须含有中文或者英文、不能使用空格；字数限制2-10字；"
          @blur="verNickName()"
          class="syn_loading_mask"
          v-loading="checkSensitiveWordsLoading"></el-input>
      </el-form-item>
      <el-form-item v-if="!isPartner" label="真实姓名" key="realName" :prop="forbidModifyIdNum ? '' : 'realName'">
        <el-input
          type="text"
          v-model="designerInfo.realName"
          :disabled="forbidModifyIdNum"
          maxlength="20"
          autocomplete="off"
          placeholder="请输入您的真实姓名"
          clearable></el-input>
      </el-form-item>
      <el-form-item
        label="身份证号"
        v-if="isNeedId === 'Y'"
        key="idCard"
        class="id_card_number"
        :prop="forbidModifyIdNum ? '' : 'idCard'">
        <el-input
          type="text"
          v-model="designerInfo.idCard"
          :disabled="forbidModifyIdNum"
          autocomplete="off"
          @input="inputIdCardNumber(true)"
          @blur="inputIdCardNumber(false)"
          placeholder="请输入您的身份证号"
          clearable>
          <template slot="suffix">
            <el-tooltip
              v-if="!inputIdNumber"
              class="item"
              effect="dark"
              content="身份证信息仅用于注册账号验证，平台会保护您的信息安全，请放心填写。"
              placement="top-end">
              <Icon icon="question"></Icon>
            </el-tooltip>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input
          type="text"
          v-model="designerInfo.email"
          :disabled="!designerInfo.allowModifyEmail"
          maxlength="50"
          autocomplete="off"
          placeholder="请输入您的邮箱"
          clearable></el-input>
      </el-form-item>
      <div class="flex_between">
        <el-form-item label="设计费标准" prop="designFeeStandardMin" class="designfee_min">
          <el-input
            v-model="designerInfo.designFeeStandardMin"
            placeholder="设计费最小值(元/㎡)"
            @blur="blurFee(designerInfo.designFeeStandardMin, 'min')"
            clearable></el-input>
        </el-form-item>
        <div class="gap_line"></div>
        <el-form-item label="" prop="designFeeStandardMax" label-width="0" class="designfee_max">
          <el-input
            v-model="designerInfo.designFeeStandardMax"
            placeholder="设计费最大值(元/㎡)"
            @blur="blurFee(designerInfo.designFeeStandardMax, 'max')"
            clearable></el-input>
        </el-form-item>
      </div>
      <el-form-item label="服务区域" required prop="areaCodeArr" class="designer_service_area">
        <addressMulti
          class="wid100"
          :selected-area="designerInfo.areaCodeArr"
          :multi="multi"
          :areaData="areaData"
          @changeAreaCode="changeAreaCode" />
      </el-form-item>
      <el-form-item v-if="isHaier" label="工号" prop="haierNo" class="haier_no_item">
        <el-input
          type="text"
          v-model="designerInfo.haierNo"
          maxlength="10"
          autocomplete="off"
          placeholder="请输入您的工号"
          clearable>
          <template slot="suffix" v-if="haierName">
            <span>{{ haierName }}</span>
          </template>
        </el-input>
      </el-form-item>
      <div v-if="isShop">
        <el-form-item v-if="!applied || !isPartner" label="加入门店" class="choose_store" required>
          <ZjsjHomeButton
            size="large"
            class="login_btn"
            type="primary"
            :customStyle="{
              'background-image': 'linear-gradient(90deg, #E3D1B3 0%, #C8A065 100%)',
              width: '88px',
              'font-size': '14px',
              'font-weight': 400,
              padding: 0,
              'border-radius': '8px',
              border: 'none',
            }"
            @click="handleStoreSelectDialog(true)">
            选择门店
          </ZjsjHomeButton>
          <div class="store_select_table" v-if="retailStoreList.length">
            <StoreTable :storeInfo="retailStoreList" @storeChanges="storeChanges"></StoreTable>
          </div>
        </el-form-item>
      </div>
      <el-form-item class="mt20" label="归属产业" prop="industryType" v-if="!isCommDesigner">
        <el-select
          v-model="designerInfo.industryType"
          popper-class="designer_industry_type"
          class="wid100"
          placeholder="请选择归属产业"
          clearable>
          <el-option
            v-for="item in industryTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="您的职能" prop="fun" class="designer_fun_select" v-if="designerInfo.designerType !== '3'">
        <el-checkbox-group v-model="designerInfo.fun" class="designer_fun_checkbox_select">
          <el-checkbox v-for="item in functionList" :label="item.code" :key="item.id">{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item class="auth_list" label="设计方案" prop="designScheme">
        <div class="upload">
          <UploadDesignPlan
            @illegal="illegal"
            :accept="'image/jpeg,image/png'"
            :echoList="echoList"
            @onChange="handleUploadImg"></UploadDesignPlan>
        </div>
        <div class="tips">
          <div>1.请上传1-9张设计方案图片</div>
          <div>2.图片支持JPG、PNG，每张大小不超过20M</div>
        </div>
      </el-form-item>
      <el-form-item prop="captchaCode" label="验证码" class="complete_captcha">
        <div class="flex">
          <el-input
            class="wid175"
            type="text"
            maxlength="5"
            v-model="designerInfo.captchaCode"
            autocomplete="off"
            placeholder="请输入图片验证码"
            clearable></el-input>
          <ZjsjImage
            @click="getCaptchaCode"
            :src="imgSrc"
            :v-loading="captchLoading"
            class="code_img syn_loading_mask"></ZjsjImage>
        </div>
      </el-form-item>
    </el-form>
    <div class="submit_review">
      <ZjsjHomeButton
        size="large"
        :customStyle="{ width: '360px', height: '48px' }"
        :disabled="submitBtnDisable"
        :loading="submitLoading"
        @click="designSubmit">
        提交审核
      </ZjsjHomeButton>
    </div>
    <el-dialog
      v-if="showDialog"
      class="home_layout_dialog"
      width="100%"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible="showDialog">
      <StoreSelect
        storeType="0"
        :show-header="false"
        :from-page="{ type: 'PAGE' }"
        :storeCode="accountInfo.shopCode"
        :checkedStore="retailStoreList"
        @storeChanges="storeChanges"
        @handleback="handleDialogBack"></StoreSelect>
    </el-dialog>
  </div>
</template>

<script>
  import { checkSensitiveWords, queryUserInfo, allAreaData, getCaptcha } from '@/api/commApi';
  import addressMulti from '@v/register/component/addressMulti';

  import { validateRegistorName, isChinaIdCard, isIntNumber } from '@/utils/verify.js';
  import { getToken } from '@/utils/auth';
  import { isEmail } from '@/utils/mUtils';
  import { validateHaierNo, registerDict, handleValidateIdCard } from '@/api/register.js';
  import UploadDesignPlan from '@/views/zjsj/components/UploadDesignPlan.vue';
  import Icon from '@/components/ui/zjsjIcon/icon.vue';
  import { registerFillUserInfo } from '@/api/register.js';
  import { RegisterService } from '@/api/register.js';
  import StoreSelect from '@/views/register/component/storeSelect.vue';
  import StoreTable from './storeTable.vue';
  import { identityTypeList } from '@/utils/resources';
  import { generatorParams } from './designerSubmit';
  // 是否为昵称
  function isNickName(value) {
    // 昵称必须含有中文或者英文、不能使用空格；字数限制4-10字；
    const nickNameReg = /[\u4e00-\u9fa5a-zA-Z]+/;
    const notBlankReg = /^\S+$/;
    const countReg = /^\S{2,10}$/;
    if (!nickNameReg.test(value) || !notBlankReg.test(value) || !countReg.test(value)) {
      return false;
    } else {
      return true;
    }
  }
  export default {
    name: 'designerInfoComplete',
    components: {
      addressMulti,
      UploadDesignPlan,
      Icon,
      StoreSelect,
      StoreTable,
    },
    emit: [
      'changeInfo', // 填写信息发生变化触发监听
    ],
    data() {
      // 校验昵称
      let checkNickName = (rule, value, callback) => {
        if (value) {
          if (isNickName(value)) {
            callback();
          } else {
            callback(new Error(rule.message));
          }
        }
      };
      // 校验真实姓名
      let checkRealName = (rule, value, callback) => {
        if (value) {
          if (validateRegistorName(value)) {
            callback();
          } else {
            callback(new Error(rule.message));
          }
        }
      };
      // 校验身份证号
      let checkIdCard = (rule, value, callback) => {
        if (value) {
          if (isChinaIdCard(value)) {
            callback();
          } else {
            callback(new Error(rule.message));
          }
        }
      };
      // 校验邮箱
      let checkEmail = (rule, value, callback) => {
        if (value) {
          if (isEmail(value)) {
            callback();
          } else {
            callback(new Error(rule.message));
          }
        } else {
          callback();
        }
      };
      // 校验设计费
      let checkFee = (rule, value, callback) => {
        if (value) {
          if (isIntNumber(value)) {
            callback();
          } else {
            callback(new Error(rule.message));
          }
        } else {
          callback();
        }
      };
      return {
        showDialog: false,
        echoList: [],
        captchLoading: false,
        imgSrc: '',
        captchaId: '',
        submitBtnDisable: false,
        submitLoading: false,
        inputIdNumber: false,
        haierName: '',
        isHaier: false,
        isShop: false,
        applied: false, // 如果已经提交了门店申请，隐藏门店选择按钮
        isAuth: false, // 是否可修改真实姓名和身份证号
        areaData: [],
        provinceName: '', // 省name
        provinceCode: '', // 省code
        province: [],
        city: [], // 市列表
        serviceArea: [], // 区列表
        retailStoreList: [], // 门店列表
        dialogVisible: false,
        accountInfo: {}, // 个人信息详情
        industryTypeList: [], // 产业列表
        multi: '', // 省市区是否多选
        isNeedId: window.localStorage.getItem('needId'), // needId=Y:合伙人，隐藏身份证号
        partnerType: window.localStorage.getItem('partnerType'), // 1 - 店铺/店铺负责人; 2 - 店铺合伙人；3 - 共享设计师
        authStatus: null,
        checkSensitiveWordsLoading: false,
        designerInfo: {
          designFeeStandardMin: 80,
          designFeeStandardMax: 200,
          fun: [],
          designScheme: [],
          captchaCode: '',
          areaCodeArr: [],
          nickName: '',
          realName: '',
          email: '',
          idCard: '',
          industryType: '',
        },
        functionList: [],
        rules: {
          nickName: [
            {
              required: true,
              message: '请输入昵称',
              trigger: ['change', 'blur'],
            },
            {
              validator: checkNickName,
              message: '昵称必须含有中文或者英文、不能使用空格；字数限制2-10字；',
              trigger: ['change', 'blur'],
            },
          ],
          realName: [
            {
              required: true,
              message: '请输入真实姓名',
              trigger: ['change', 'blur'],
            },
            {
              validator: checkRealName,
              message: '真实姓名不超过20个字符，不能输入空格，不能输入特殊字符；',
              trigger: ['change', 'blur'],
            },
          ],
          idCard: [
            {
              required: true,
              message: '请输入身份证号',
              trigger: ['change', 'blur'],
            },
            {
              validator: checkIdCard,
              message: '请输入正确的身份证号',
              trigger: ['change', 'blur'],
            },
          ],
          email: [
            {
              required: true,
              message: '请输入您的邮箱',
              trigger: ['change', 'blur'],
            },
            {
              validator: checkEmail,
              message: '邮箱格式不正确，请输入正确的邮箱',
              trigger: ['change', 'blur'],
            },
          ],
          designFeeStandardMin: [
            {
              required: false,
              message: '请输入设计费最小值',
              trigger: ['change', 'blur'],
            },
            {
              validator: checkFee,
              message: '设计费标准为1元/㎡-3000元/㎡(正整数)',
              trigger: ['change', 'blur'],
            },
          ],
          designFeeStandardMax: [
            {
              required: false,
              message: '请输入设计费最大值',
              trigger: ['change', 'blur'],
            },
            {
              validator: checkFee,
              message: '设计费标准为1元/㎡-3000元/㎡(正整数)',
              trigger: ['change', 'blur'],
            },
          ],
          haierNo: [
            {
              required: true,
              message: '请输入您的工号',
              trigger: ['change', 'blur'],
            },
          ],
          industryType: [
            {
              required: true,
              message: '请选择归属产业',
              trigger: ['change', 'blur'],
            },
          ],
          designScheme: [
            {
              required: true,
              message: '请上传1-9张设计方案图片',
              trigger: ['change', 'blur'],
            },
          ],
          captchaCode: [
            {
              required: true,
              message: '请输入验证码',
              trigger: ['change', 'blur'],
            },
          ],
          fun: [
            {
              required: true,
              message: '请选择您的职能',
              trigger: ['change', 'blur'],
            },
          ],
          areaCodeArr: [
            {
              required: true,
              message: '请选择服务区域',
              trigger: ['change', 'blur'],
            },
          ],
        },
      };
    },
    computed: {
      isCommDesigner() {
        // https://ihaier.feishu.cn/docx/VqfRdlOi3oiwTgxPmBmcca8pn2f 放开共享设计师的限制 都可以选择产业
        return false;
      },
      // 是否能修改身份证号 审核失败禁止修改真实姓名和身份证号
      forbidModifyIdNum() {
        return Number(this.authStatus) === 3;
      },
      isPartner() {
        // 1 2  店铺负责人 店铺合伙人  只能选择店铺设计师
        // 隐藏真实姓名、身份选择、加入门店
        return this.partnerType === '1' || this.partnerType === '2';
      },
      designerName() {
        return this.designerInfo.designerType
          ? identityTypeList.find((item) => item.value == this.designerInfo.designerType).label
          : '';
      },
    },
    watch: {
      designerInfo: {
        handler: async function (info) {
          this.$emit('changeInfo', info);
          // try {
          //   let validateResult = await this.$refs['completeForm'].validate();
          //   console.log('实时校验', validateResult);
          //   this.submitBtnDisable = !validateResult;
          //   this.$refs['completeForm'].clearValidate();
          //   console.log('清除校验');
          // } catch (error) {
          //   this.$refs['completeForm'].clearValidate();
          //   console.error(error);
          //   this.submitBtnDisable = true;
          // }
        },
        deep: true,
      },
    },
    async mounted() {
      console.log('mounted', window.localStorage.getItem('partnerType'));
      await this.getData();
      await this.queryAllArea();
      if (getToken()) {
        await this.getUserInfo();
      }
      this.getCaptchaCode();
    },
    methods: {
      gioTrack(id, payload) {
        if (payload) {
          gio('track', id, payload); // eslint-disable-line
        } else {
          gio('track', id); // eslint-disable-line
        }
      },
      gioTrackError(error) {
        this.gioTrack('CZHT30176', {
          roughly_classification_name: identityTypeList.find((item) => item.value == this.designerInfo.designerType)
            .label,
          failure_reason: error,
        });
      },
      gioTrackSuccess() {
        this.gioTrack("CZHT30175", {roughly_classification_name: identityTypeList.find(item => item.value == this.designerInfo.designerType).label}); // eslint-disable-line
      },
      handleDialogBack() {
        this.showDialog = false;
      },
      getCaptchaCode() {
        if (this.captchLoading) {
          return;
        }
        this.captchaId = '';
        this.imgSrc = '';
        this.designerInfo.captchaCode = '';
        this.captchLoading = true;
        getCaptcha()
          .then((res) => {
            if (res.code === '0' && res.data.captcha) {
              this.imgSrc = res.data.captcha;
              this.captchaId = res.data.captchaId;
            } else {
              this.$message.error('获取图片验证码失败');
            }
          })
          .finally(() => {
            this.captchLoading = false;
          });
      },
      /**
       * @description 上传文件变动事件
       * @param {Array} imglist 上传的文件列表
       */
      handleUploadImg(imglist) {
        this.designerInfo.designScheme = imglist;
      },
      illegal() {
        this.$message.error('请选择JPG、PNG类型的图片');
      },
      /**
       * @description 表单的其他校验项
       */
      otherValidate() {},
      /**
       * @description 设计师表单提交
       */
      designSubmit() {
        this.submitLoading = true;
        try {
          this.$refs['completeForm'].validate(async (val) => {
            if (val) {
              if (this.designerInfo.designerType === '1') {
                // 店铺设计师至少加入一家门店
                if (!this.retailStoreList || this.retailStoreList.length === 0) {
                  this.$message.error('店铺设计师至少加入一家门店');
                  this.submitLoading = false;
                  return;
                }
                // 店铺合伙人/负责人至少加入一家门店；
                if (this.isPartner && (!this.retailStoreList || this.retailStoreList.length === 0)) {
                  this.$message.error('店铺合伙人/负责人至少加入一家门店');
                  this.submitLoading = false;
                  return;
                }
              } else if (this.designerInfo.designerType === '0') {
                // 海尔员工
                if (this.applied) {
                  // 如果已经申请门店，需先解绑门店，再变更身份
                  this.$message.error('尊敬的设计师您好，身份的变更需要您先与门店解绑合作！');
                  this.submitLoading = false;
                  return;
                }
              }

              // 仅能加入相同店铺下的门店
              if (this.retailStoreList && this.retailStoreList.length > 1) {
                const storeCodeList = [
                  ...new Set(
                    this.retailStoreList.map((e) => {
                      return e.storeCode;
                    })
                  ),
                ];

                if (storeCodeList.length > 1) {
                  this.$message.error('您仅能加入相同店铺下的门店！');
                  this.submitLoading = false;
                  return;
                }
              }
              //  1邮箱 * 2工号 * 3邮箱&工号
              let verifyParams = {
                haierNo: this.designerInfo.haierNo,
                realName: this.designerInfo.realName,
                email: this.designerInfo.email,
                type: this.isHaier ? 3 : 1,
                captchaId: this.captchaId,
                captchaCode: this.designerInfo.captchaCode,
              };
              let verifyResult = await this.verifyNoOrEmail(verifyParams).catch(() => {
                this.submitLoading = false;
              });
              console.log('verifyResult', verifyResult);
              if (!verifyResult) {
                console.log('verifyResult', verifyResult, 'ddddddddd');
                this.submitLoading = false;
                this.getCaptchaCode();
                return;
              }
              // 身份证和姓名实名验证, 仅在需要身份证号场景下校验
              if (this.isNeedId === 'Y' && this.accountInfo.authFlag !== '3') {
                if (this.designerInfo.idCard) {
                  let params = {
                    name: this.designerInfo.realName,
                    idCard: this.designerInfo.idCard,
                    ucToken: window.localStorage.getItem('access_token'),
                  };
                  const { result, message } = await handleValidateIdCard(params);
                  if (!result) {
                    this.submitLoading = false;
                    this.getCaptchaCode();
                    this.gioTrackError(message);
                    return;
                  }
                }
              }
              if (this.isNeedId === 'Y') {
                const isPartner = await this.becomePartner();
                console.log('isPartner', isPartner);
                if (!isPartner) {
                  this.submitLoading = false;
                  this.getCaptchaCode();
                  return;
                }
              }
              let params = { ...this.designerInfo };
              let submitParams = generatorParams(params, this.functionList, this);
              registerFillUserInfo(submitParams)
                .then((res) => {
                  if (res.status === '0') {
                    this.gioTrackSuccess();
                    this.completeIgnoreBackHome();
                  } else {
                    this.gioTrackError(res.msg);
                    this.getCaptchaCode();
                  }
                })
                .finally(() => {
                  this.submitLoading = false;
                });
            } else {
              setTimeout(() => {
                try {
                  var isError = document.getElementsByClassName('is-error');
                  isError && isError[0] && isError[0].querySelector('input').focus();
                } catch (error) {
                  console.log(error, '寻找节点失败');
                }
              }, 100);
              this.submitLoading = false;
            }
          });
        } catch (error) {
          console.log('提交表单error', error);
        }
      },
      becomePartner() {
        return new Promise((resolve) => {
          resolve(true);
          // becomePartner()
          //   .then((res) => {
          //     if (res.status === '0') {
          //       // 1 - 店铺/店铺负责人; 2 - 店铺合伙人；3 - 共享设计师
          //       window.localStorage.setItem('partnerType', '3');
          //       resolve(true);
          //     } else {
          //       resolve(false);
          //     }
          //   })
          //   .catch(() => {
          //     resolve(false);
          //   });
        });
      },
      backHome() {
        this.$store.dispatch('setCurrentComponent', null);
        this.$router.push({
          path: '/zjsj/zjsjHome',
          query: {
            needReload: Math.random().toString(),
          },
        });
      },
      completeIgnoreBackHome() {
        this.backHome();
        this.$store.dispatch('setCurrentComponent', {
          name: 'AUTH_ERROR',
          query: {
            title: '欢迎加入三翼鸟定制平台！',
            content: '您的认证申请已提交，1-2个工作日将完成审核，烦请耐心等待。',
            confirmButtonText: '确认',
            confirmButtonStyle: { width: '112px', height: '44px' },
            onConfirm: () => {
              this.$store.dispatch('setCurrentComponent', null);
            },
          },
        });
      },
      handleStoreSelectDialog() {
        this.showDialog = true;
      },
      // 已选门店回显
      storeChanges(val) {
        this.retailStoreList = val;
      },
      getData() {
        // 获取从业年限
        registerDict('working_years').then((res) => {
          if (res.status === '0') {
            this.workingYearsList = res.data || [];
          }
        });
        // 归属产业
        registerDict('industry_type').then((res) => {
          if (res.status === '0') {
            this.industryTypeList = res.data || [];
          }
        });
        registerDict('all_label').then((res) => {
          if (res.status === '0') {
            let functionList = [];
            (res.data || []).forEach((item) => {
              if (item.type === 'fun_label') {
                functionList.push(item);
              }
            });
            this.functionList = functionList;
          }
        });
      },
      // 获取省市区
      queryAllArea() {
        allAreaData().then((res) => {
          if (res.status === '0') {
            this.areaData = res.data || [];
          }
        });
      },
      /**
       * @description 验证邮箱和工号
       * @param {Object} params 验证参数
       */
      verifyNoOrEmail(params) {
        return new Promise((resolve, reject) => {
          RegisterService.verifyEmailOrNo(params)
            .then((res) => {
              if (res.status === '1') {
                this.mapErrorCode2msg(res.data);
                reject(false);
              } else {
                resolve(true);
              }
            })
            .catch(() => {
              reject(false);
            });
        });
      },
      /**
       * @description 根据返回的错误码list 映射错误信息
       * @param {Array} errorCodeList 错误列表
       */
      mapErrorCode2msg(errorCodeList) {
        errorCodeList.forEach((item) => {
          this.getCaptchaCode();
          let { message } = item;
          this.gioTrackError(message);
          this.$message.error(message);
        });
      },
      /**
       * @description 修改服务区域
       * @param {Object} data 修改后的信息
       */
      changeAreaCode(data) {
        this.designerInfo.areaCodeArr = data.codeArr;
        this.provinceName = data.provinceName;
        this.provinceCode = data.provinceCode;
        this.city = data.city;
        this.serviceArea = data.serviceArea;
        this.province = data.province;
      },
      getUserInfo() {
        // 获取用户信息
        // 接口文档 - http://zjsj-dev.qd-aliyun-internal2.haier.net/doc.html#/default/%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%E7%9B%B8%E5%85%B3%E5%8A%9F%E8%83%BDAPI/queryUserInfoUsingGET_2
        const loading = this.$loading({
          lock: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
          customClass: 'syn-fullscreen-text-loading',
          target: document.querySelector('.div1'),
        });
        queryUserInfo()
          .then(async (res) => {
            loading.close();
            if (res.status === '0' && res.data) {
              const { userInfo, userLabel, authInfo } = res.data;
              this.$store.dispatch('setDesignerType', userInfo.registerUserType);
              try {
                let designerName = identityTypeList.find((item) => item.value == userInfo.registerUserType).label;
                gio('track', 'CZHT30172', { roughly_classification_name: designerName }); // eslint-disable-line
              } catch (error) {
                console.log('gio error', error);
              } // 用户信息
              this.accountInfo = userInfo;
              try {
                await this.getInfo(userInfo, userLabel, authInfo);
                // 省市区
                await this.getAreaInfo(userInfo.userServiceArea);
              } catch (error) {
                console.log(error);
              }
            }
          })
          .catch(() => {
            loading.close();
          });
      },
      getAreaInfo(val) {
        if (!val) {
          return;
        }
        let areaDataKey = ['provinceName', 'provinceCode', 'city', 'serviceArea'];
        areaDataKey.forEach((key) => {
          if (val[key]) {
            this[key] = val[key];
          }
        });
        let allProvince = {};
        this.designerInfo.areaCodeArr = this.serviceArea.map((e) => {
          if (!allProvince[e.provinceCode]) {
            allProvince[e.provinceCode] = {
              code: e.provinceCode,
              value: e.provinceValue,
            };
          }
          return [e.provinceCode, e.cityCode, e.code];
        });
        this.province = Object.values(allProvince);
      },
      /**
       * @description 职能标签的数据映射
       * @param {String} labelsStr 职能标签
       */
      mapFunLabels(labelsStr) {
        let funLabels = labelsStr ? JSON.parse(labelsStr) : [];
        let allLabels = funLabels.map((item) => item.code);
        return allLabels.filter((item) => item);
      },
      /**
       * @description 处理设计费的默认值
       * @param {Number} type  0最小值 1最大值
       * @param {Number} value 设计师费
       */
      designFee(type, value) {
        let intValue = Number(value);
        if (intValue) {
          return intValue > 3000 ? 3000 : intValue;
        } else {
          return type === 0 ? 80 : 200;
        }
      },
      /**
       *
       * @param {Array} processKey 需要处理的key
       * @param {Object} params 需要在哪一个对象上处理
       */
      processEmptyValue(processKey = [], params) {
        if (processKey.length) {
          processKey.forEach((key) => {
            if (params[key] === null) {
              params[key] = '';
            }
          });
        }
      },
      /**
       * @description 个人信息回显处理
       * @param {Object} val userInfo
       * @param {Object} userLabel 职能标签
       * @param {Object} authInfo 认证信息
       */
      getInfo(val, userLabel, authInfo) {
        this.processEmptyValue(['nickName', 'realName', 'email', 'idCard'], val);

        let feeMin = val.designFeeStandardMin;
        let feeMax = val.designFeeStandardMax;
        // 信息反显,
        this.designerInfo = {
          ...this.designerInfo,
          ...val,
          ...{
            designFeeStandardMin: this.designFee(0, feeMin),
            designFeeStandardMax: this.designFee(1, feeMax),
          },
        };
        this.designerInfo.industryType = val.industryType || '';
        this.designerInfo.fun = userLabel ? this.mapFunLabels(userLabel.funLabel) : [];
        let splitDesignScheme = authInfo && authInfo.designScheme ? authInfo.designScheme.split(',') : [];
        let designScheme = splitDesignScheme.filter((item) => item);
        this.designerInfo.designScheme = designScheme;
        this.echoList = designScheme;
        this.designerInfo.designerType = val.registerUserType; // 返回的设计师类型
        this.authStatus = val.authFlag;
        if (this.designerInfo.designerType === '0') {
          // 官方设计师
          if (val.haierNo) {
            this.verHaierNo(); // 回显员工真实姓名
          }
          this.isHaier = true;
          this.multi = '0';
          this.isShop = false;
        } else if (this.designerInfo.designerType === '1') {
          // 店铺设计师
          this.multi = '1';
          this.isShop = true;
          const storeInfo = JSON.parse(window.localStorage.getItem('storeInfo'));
          if (val.shopCode) {
            this.storeDataComb(val, 'retailStore');
          } else if (storeInfo && storeInfo.storeCode) {
            this.accountInfo.shopCode = storeInfo.storeCode;
            this.storeDataComb(storeInfo, 'retailStoreList');
          }
          // 如果已经提交了门店申请，隐藏门店选择按钮
          this.applied = val.applied;
          this.authStatus = val.authFlag;
          if (Number(val.authFlag) === 3) {
            this.rules.realName[0].required = false;
            this.rules.idCard[0].required = false;
            this.$forceUpdate();
          }
        } else if (this.designerInfo.designerType === '3') {
          // 共享设计师

          this.isHaier = false;
          this.multi = '0';
          this.isShop = false;
        }
        // 各种身份都需要的统一处理
        if (Number(val.authFlag) === 3) {
          // 审核失败禁止修改真实姓名和身份证号
          this.isAuth = true;
        }
      },
      /**
       * @description 组合门店和店铺信息
       * @param {Object} storeInfo 店铺信息
       */
      storeDataComb(storeInfo, storeKey) {
        if (storeInfo[storeKey] && storeInfo[storeKey].length > 0) {
          this.retailStoreList = storeInfo[storeKey].map((e) => {
            return {
              storeCode: storeInfo.storeCode || storeInfo.shopCode,
              storeName: storeInfo.storeName || storeInfo.shopName,
              mdCode: e.code,
              mdName: e.value,
            };
          });
        }
      },
      // 校验海尔工号
      verHaierNo() {
        if (!this.designerInfo.haierNo) {
          return;
        }
        validateHaierNo(this.designerInfo.haierNo)
          .then((res) => {
            if (res && res.status === '0' && res.data) {
              this.haierName = res.data.userName;
            } else {
              this.designerInfo.haierNo = '';
              this.haierName = '';
            }
          })
          .catch(() => {
            this.designerInfo.haierNo = '';
            this.haierName = '';
          });
      },
      // 校验昵称是否合规 敏感词检验
      async verNickName() {
        if (!this.designerInfo.nickName || !isNickName(this.designerInfo.nickName)) {
          return;
        }
        try {
          this.checkSensitiveWordsLoading = true;
          await checkSensitiveWords(this.designerInfo.nickName);
          this.checkSensitiveWordsLoading = false;
        } catch (error) {
          this.checkSensitiveWordsLoading = false;
        }
      },
      clearIdNumber() {
        this.designerInfo.idCard = '';
      },
      /**
       * @description 是否正在输入身份证号
       * @param {Boolean} input 是否正在输入
       */
      inputIdCardNumber(input) {
        this.inputIdNumber = input;
      },
      /**
       * @description 校验设计费标砖
       * @param {Number} val 输入的设计费
       * @param {String} type 输入的类型  最大值 最小值
       */
      blurFee(val, type) {
        if (!isIntNumber(val)) {
          return;
        }
        if (val <= 0) {
          this.$message.error('设计费标准为1元/㎡-3000元/㎡(正整数)');
          val = 1;
        }
        if (val > 3000) {
          this.$message.error('设计费标准为1元/㎡-3000元/㎡(正整数)');
          val = 3000;
        }
        if (type === 'min') {
          this.designerInfo.designFeeStandardMin = val;
        } else {
          this.designerInfo.designFeeStandardMax = val;
        }
      },
    },
  };
</script>
<style lang="less" scoped>
  .syn_loading_mask {
    /deep/ .el-loading-mask {
      .el-loading-spinner {
        position: relative;
        height: 100%;
        .circular {
          width: 0px !important;
          height: 0px !important;
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: calc(50% - 10px);
          left: calc(50% - 10px);
          width: 20px;
          height: 20px;
          background: url('~@/assets/img/zjsj/loading.png') 0 0 no-repeat;
          animation: loading-rotate 2s linear infinite;
          @keyframes loading-rotate {
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
  .placehodertStyle {
    color: #bbbbbb;
    font-size: 14px;
  }
  .designer_info_complete {
    .wid100 {
      width: 100%;
    }
    .el-form {
      padding: 0 20% 0 20%;
      .el-form-item {
        width: 100%;
        /deep/.el-form-item__label {
          line-height: 40px;
        }
        /deep/ .el-form-item__content {
          width: calc(100% - 110px);
        }
        /deep/ .el-cascader .el-input.is-focus .el-input__inner {
          border-color: #be965a !important;
        }
        /deep/ .el-input__inner {
          height: 40px;
          border-radius: 8px;
          font-size: 14px;
          color: #333333;
          font-weight: 400;
          &:focus {
            border-color: #be965a !important;
          }
          &::-webkit-input-placeholder {
            .placehodertStyle();
          }
          &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            .placehodertStyle();
          }
          &::-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            .placehodertStyle();
          }
          &::-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            .placehodertStyle();
          }
        }
      }
      .el-form-item.id_card_number {
        /deep/ .el-form-item__content {
          .el-input__suffix-inner {
            display: flex;
            height: 100%;
            align-items: center;
          }
        }
      }
      .el-form-item.choose_store {
        /deep/ .store_select_table {
          margin-top: 5px;
        }
      }
      .el-form-item.complete_captcha {
        .flex {
          display: flex;
          align-items: center;
        }
        /deep/ .el-form-item__content {
          .el-input__suffix-inner {
            width: calc(100% - 80px);
          }
        }
        .code_img {
          width: 80px;
          height: 40px;
          cursor: pointer;
          margin-left: 10px;
        }
      }
      .el-form-item.designer_fun_select {
        .designer_fun_checkbox_select {
          /deep/ label.el-checkbox {
            margin-right: 16px;
            .el-checkbox__input {
              display: none;
            }
            .el-checkbox__label {
              padding: 0;
              width: 118px;
              height: 22px;
              border: 1px solid rgba(216, 216, 216, 1);
              border-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              color: #666660;
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;
            }
            &.is-checked {
              .el-checkbox__label {
                background: rgba(190, 150, 90, 0.2);
                color: #be965a;
                border-color: transparent;
              }
            }
          }
        }
      }
      .el-form-item.auth_list {
        .tips {
          margin-top: 12px;
          height: 44px;
          font-size: 12px;

          color: #999999;
          font-weight: 400;
          div {
            line-height: 22px;
          }
        }
      }
      .el-form-item.haier_no_item {
        /deep/ .el-form-item__content {
          .el-input__suffix-inner {
            display: flex;
            height: 100%;
            align-items: center;
            padding: 0 5px 0 0;
          }
        }
      }
      .el-form-item.designer_service_area {
        /deep/ .el-form-item__content {
          line-height: 40px !important;
          .cascader_box {
            height: 40px !important;
            .cascader_stl {
              height: 100%;
            }
          }
        }
      }
      .flex_between {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        .el-form-item {
          width: 100%;
        }
        .el-form-item.designfee_min {
          width: calc(((100% - 110px) / 2) + 110px);
          /deep/ .el-form-item__content {
            width: calc(100% - 100px);
            .el-form-item__error {
              left: -5px !important;
            }
          }
        }
        .el-form-item.designfee_max {
          width: calc(((100% - 110px) / 2));
          /deep/ .el-form-item__content {
            width: 100%;
            .el-form-item__error {
              left: -5px !important;
            }
          }
        }

        .gap_line {
          width: 10px;
          margin: 0 10px 18px 0;
          height: 40px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            width: 10px;
            height: 1px;
            background: #666;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    @media screen and (min-width: 1200px) and (max-width: 1800px) {
      .el-form {
        padding: 0 10% 0 10%;
      }
    }
    .submit_review {
      width: 100%;
      text-align: center;
      position: sticky;
      bottom: 0px;
      padding: 10px 0;
      background-color: #fff;
      z-index: 10;
    }
  }
</style>
<style lang="less">
  .designer_industry_type {
    .el-scrollbar {
      .el-select-dropdown__item.hover {
        background: rgba(51, 51, 51, 0.1);
      }
      .el-select-dropdown__item.selected {
        color: #be965a !important;
        background: rgba(190, 150, 90, 0.1);
      }
    }
  }
</style>
