/**
 * @description 运营信息配置 vuex
 */
const operationInfo = {
  state: {
    operationInfo: window.localStorage.getItem('operationInfo') || {},
  },
  mutations: {
    setOperationInfo: (state, operationInfo) => {
      localStorage.setItem('operationInfo', JSON.stringify(operationInfo));
      state.operationInfo = operationInfo;
    },
  },
  actions: {
    setOperationInfo: ({ commit }, operationInfo) => {
      commit('setOperationInfo', operationInfo);
    },
  },
  getters: {
    operationInfo: (state) => state.operationInfo,
  },
};
export default operationInfo;
