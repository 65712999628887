const youchaoxiuStore = {
  state: {
    selectCaseInfo: JSON.parse(
      localStorage.getItem('selectCaseInfo') === '[object Object]'
        ? '{}'
        : localStorage.getItem('selectCaseInfo') || '{}'
    ),
  },
  mutations: {
    setCaseInfo: (state, caseInfo) => {
      localStorage.setItem('selectCaseInfo', JSON.stringify(caseInfo));
      state.selectCaseInfo = caseInfo;
    },
  },
  actions: {
    setCaseInfo: ({ commit }, caseInfo) => {
      commit('setCaseInfo', caseInfo);
    },
  },
};
export default youchaoxiuStore;
