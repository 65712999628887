<template>
  <el-image v-bind="$attrs" v-on="$listeners" :style="customeStyle" v-loading="$attrs['v-loading']">
    <div class="loading_placeholder" slot="placeholder"></div>
    <div slot="error" class="loading_error">
      <slot name="error" class="error_image">
        <span style="color: rgb(119 123 133); font-size: 14px">加载失败</span>
      </slot>
    </div>
  </el-image>
</template>
<script>
  export default {
    name: 'ZjsjImage',
    props: {
      customStyle: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      customeStyle() {
        let commStyle = {
          background: 'rgba(0,0,0,0.2)',
        };
        return { ...commStyle, ...this.customStyle };
      },
    },
  };
</script>
<style lang="less" scoped>
  .loading_placeholder {
    width: 30px;
    height: 30px;
    background: url('~@/assets/img/zjsj/loading.png') 0 0 no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    animation: rotate 3s linear infinite;
  }
  /deep/ .el-image__inner {
    border-radius: 4px;
  }
  .loading_error {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
