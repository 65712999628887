<template>
  <div :class="['container', customeClass]">
    <div class="item" tabindex="0" v-for="(item, index) in imageList" :key="item.id">
      <!-- 上传中... -->
      <div class="ing" v-if="item.state === 1">
        <div class="text">上传中...</div>
        <el-progress
          class="progress"
          type="line"
          :stroke-width="2"
          :color="customColor"
          :percentage="item.progress"
          :show-text="false"
          :define-back-color="customBackColor"></el-progress>
      </div>
      <!-- 上传成功 -->
      <div class="success" v-if="item.state === 2">
        <ZjsjImage :src="item.url" class="image" fit="cover"></ZjsjImage>
        <div class="overlay">
          <img
            class="item-bt"
            src="../../../../src/assets/img/designerAuth/image-preview.png"
            @click="preview(index)" />
          <img
            class="item-bt"
            v-if="showDetele"
            src="../../../../src/assets/img/designerAuth/image-delete.png"
            @click="handleDelete(index)" />
        </div>
      </div>
    </div>
    <!-- 上传 -->
    <div class="add" v-show="isShowAdd" @click="openFile">
      <input id="addImage" v-show="false" type="file" ref="fileRef" :accept="accept" @change="handleChange" />
      <div class="top-text">＋</div>
      <div class="bottom-text">点击上传</div>
    </div>
    <ElImageViewer
      v-if="isPreview"
      :z-index="9999"
      :initialIndex="initialIndex"
      :url-list="previewImages"
      :on-close="onClose"></ElImageViewer>
  </div>
</template>
<script>
  import request from '@/utils/axios';
  import { serverUrl } from '@/utils/env';
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer.vue';
  export default {
    components: {
      ElImageViewer,
    },
    props: {
      showDetele: {
        // 是否显示删除按钮
        required: false,
        type: Boolean,
        default: true,
      },
      showAdd: {
        // 是否显示上传按钮
        required: false,
        type: Boolean,
        default: true,
      },
      isCover: {
        // 是否直接覆盖之前的图片
        type: Boolean,
        default: false,
      },
      // 单位 MB
      sizeLimit: {
        type: [Number, String],
        default: 20,
      },
      countLimit: {
        type: [Number, String],
        default: 9,
      },
      typeLimit: {
        type: Array,
        default: () => ['jpeg', 'jpg', 'png'],
      },
      accept: {
        type: String,
        default: 'image/*',
      },
      echoList: {
        type: Array,
        default: () => [],
      },
      customeClass: {
        type: String,
        default: '',
      },
    },
    emits: [
      'preview',
      'onDeleted',
      'uploadSuccess',
      'uploadFailure',
      'onChange',
      'exceed', //超出限制
      'illegal', //非法格式
    ],
    data() {
      return {
        initialIndex: 0,
        customColor: '#BE965A',
        customBackColor: '#BBBBBB',
        upperLimit: 0, //B
        isPreview: false,
        imageList: [],
      };
    },
    computed: {
      isShowAdd() {
        return this.showAdd ? this.imageList.length < this.countLimit : false;
      },
      previewImages() {
        return this.imageList.map((item) => item.url);
      },
    },
    watch: {
      echoList: {
        handler: function (val) {
          if (val) {
            val.forEach((url) => {
              this.imageList.push({
                id: url,
                url: url,
                state: 2,
              });
            });
          }
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {
      this.upperLimit = this.sizeLimit * 1024 * 1024; //B
    },
    methods: {
      handleChange(ev) {
        const files = ev.target.files;
        if (!files || files.length < 1) {
          return;
        }
        let file = files[0];
        //单张图片大小校验
        if (file.size > this.upperLimit) {
          this.$emit('exceed', file.size, this.upperLimit);
          return;
        }
        //图片格式校验
        let flag = this.typeLimit.some((value) => file.type.includes(value));
        if (!flag) {
          this.$emit('illegal', file.type);
          return;
        }
        let newImage = {
          id: file.name,
          file: file,
          url: window.URL.createObjectURL(file),
          state: 1,
          progress: 0,
        };
        if (this.isCover) {
          this.imageList = [newImage];
        } else {
          this.imageList.push(newImage);
        }
        this.upload(newImage);
      },
      openFile() {
        this.$refs.fileRef.dispatchEvent(new MouseEvent('click'));
      },
      preview(index) {
        this.initialIndex = index;
        this.isPreview = true;
      },
      onClose() {
        this.isPreview = false;
      },
      handleDelete(index) {
        let deleted = this.imageList.splice(index, 1);
        this.$emit('onDeleted', deleted);
        this.$emit('onChange', this.imageList);
      },
      upload(item) {
        // setInterval(() => {
        //   if (item.progress < 100) {
        //     item.progress += 10;
        //   } else {
        //     item.state = 2;
        //     // this.$emit('onChange', this.imageList);
        //     // this.deleteUploadFailedImage(item)
        //   }
        // }, 100);
        let formData = new FormData();
        formData.append('file', item.file);
        request({
          url: serverUrl + '/api/haier_case/case-desc/upload-img',
          method: 'post',
          data: formData,
          onUploadProgress: (progressEvent) => {
            item.progress = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          },
        })
          .then((resp) => {
            if (resp.status == 0) {
              //成功
              item.state = 2;
              item.url = resp.data;
              this.$emit('uploadSuccess', item);
              this.$emit('onChange', this.imageList);
              this.$refs.fileRef.value = null;
            } else {
              this.deleteUploadFailedImage(item);
              this.$emit('onChange', this.imageList);
              this.$emit('uploadFailure', item);
            }
          })
          .catch(() => {
            this.deleteUploadFailedImage(item);
            this.$emit('uploadFailure', item);
            this.$emit('onChange', this.imageList);
          });
      },
      deleteUploadFailedImage(item) {
        let index = this.imageList.indexOf(item);
        this.imageList.splice(index, 1);
      },
    },
  };
</script>
<style lang="less" scoped>
  @item-width: 100px;
  @item-height: 100px;
  @item-bt-width: 24px;
  @item-bt-height: 24px;
  @radius: 8px;

  .container {
    width: 340px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
  }

  .item-border {
    border: 1px solid #d8d8d8;
    border-radius: @radius;
  }

  .item {
    width: @item-width;
    height: @item-height;

    .ing {
      width: @item-width;
      height: @item-height;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fafafa;
      .item-border();

      .progress {
        width: 84px;
        margin-top: 8px;
      }
    }

    .success {
      position: relative;

      .image {
        position: absolute;
        border-radius: @radius;
        width: @item-width;
        height: @item-height;
      }

      .overlay {
        position: absolute;
        z-index: 10;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: @item-width;
        height: @item-height;
        border-radius: @radius;

        .item-bt {
          display: none;
          width: @item-bt-width;
          height: @item-bt-height;
          margin: 5px;
          cursor: pointer;
        }
      }

      .overlay:hover {
        background-color: rgba(0, 0, 0, 0.5);

        .item-bt {
          display: block;
        }
      }
    }
  }

  .text {
    font-size: 14px;
    color: #333333;
  }

  .add {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: @item-width;
    height: @item-height;
    background-color: #fafafa;
    cursor: pointer;
    .item-border();

    .top-text {
      text-align: center;
      letter-spacing: 1px;
      .text();
    }

    .bottom-text {
      .text();
      margin-top: 10px;
    }
  }
</style>
