// 操作指南 - 地址
export const operationGuideUrl =
  'https://haier-tdcd-04.oss-cn-qingdao.aliyuncs.com/%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C/%E4%B8%89%E7%BF%BC%E9%B8%9F%E8%AE%BE%E8%AE%A1%E5%B8%88%E5%90%8E%E5%8F%B0%E6%93%8D%E4%BD%9C%E8%AF%B4%E6%98%8EV2.0%E7%89%88%E6%9C%AC%200211.pdf';
export const genderList = [
  { value: 'M', label: '男' },
  { value: 'F', label: '女' },
];
export const identityTypeList = [
  // 0：三翼鸟官方设计师
  // 1：店铺设计师
  // 3：共享设计师(行业设计师)
  // 5：星云平台专属userType
  // 50：超管
  { value: '1', label: '店铺设计师', tips: '门店设计师' },
  { value: '3', label: '共享设计师', tips: '与海尔无雇佣关系，外部的设计师' },
  { value: '0', label: '官方设计师', tips: '岗位为设计师的海尔员工' },
];

// 注册 - 认证
export const registerCert = {
  designScheme: '', // 设计方案
  qualificationCertificate: '', // 其他资质
  panoramicScheme: 'https://yun.kujiale.com/design/3FO46O2TO21I/show', // VR全景漫游链接
};
// 注册 - 协议
export const registerAgreement = {
  userAgree: '/mgr/protocol.html', // 《三翼鸟筑巢设计平台用户使用协议》
  partnerAgree: 'https://m.ehaier.com/sgmobile/documentInfo?id=1485', // 《平台合伙人协议》
  declaration: 'https://m.ehaier.com/sgmobile/documentInfo?id=1623', // 《声明》
};

export const DESIGNER_DELETE_STATE = '9';
