/**
 * 处理分页查询的mixin
 * 移除_isSearching: Element2.0 表单组件的 change 事件和 Pagination 的 current-change 事件现在仅响应用户交互
 * options提供配置参数：
 *              type - 请求方式 'get'、'post'
 *              loading - 是否需要loading
 *              target - 渲染loading的dom元素，可传class或id或dom对象（只能传入一个dom元素）
 *              tableData -- 用来接收返回数据存储的变量，用来应变一个页面存在多个table
 */
import q from './query';

export default {
  created() {
    this._url = '';
    this.queryParams = {};
    this._type = 'post'; // 默认post请求
    this._tableData = '';
  },
  data() {
    return {
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      arr: [],
    };
  },
  watch: {
    total: function () {
      // 删除最后一页全部数据时显示无数据 调整为自动回调翻页上页image.png
      if (
        this.total === (this.currentPage - 1) * this.pageSize &&
        this.total !== 0
      ) {
        this.currentPage -= 1;
        this.query();
      }
    },
  },
  methods: {
    query(url = '', params = null, options = {}) {
      // 设置请求类型后，将type存在当前实例中，调用query时直接从实例中的type取值
      if (options.type) {
        this._type = options.type;
      }
      options.type = this._type;
      //缓存存储数据的tableData变量名，用于换页时赋值
      if (options.tableData) {
        this._tableData = options.tableData;
      }
      options.tableData = this._tableData;
      if (url !== '') {
        this._url = url;
      }
      if (params) {
        // 传入查询参数时需要重置currentPage
        this.currentPage = params.currentPage ? params.currentPage : 1;
        this.queryParams = Object.assign({}, params); // 缓存查询参数
      }
      let data = Object.assign({}, this.queryParams, {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      });

      return q(this._url, data, options, this).then((res) => {
        if (res && res.data && res.data.total) {
          this.total = parseInt(res.data.total);
        } else if (this.currentPage === 1) {
          this.total = 0;
        }
        if (res && res.data) {
          this.tableData =
            res.data.result ||
            res.data.list ||
            res.data.records ||
            res.data.dataList ||
            [];
        }
        if (options.tableData) {
          this[options.tableData] = this.tableData;
        }
        return res;
      });
    },
    handleSizeChange(pageSize) {
      this.currentPage = 1;
      this.pageSize = pageSize;
      this.query();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.query('', null, { tableData: this._tableData });
    },
    indexMethod(index) {
      return index + 1 + this.currentPage;
    },
    reverseOrder(index) {
      return this.tableData.length - index;
    },
  },
};
