import request from '@/utils/axios';
import { serverUrl } from '@/utils/env';

export function getToken() {
  return window.localStorage.getItem('token');
}

export function setToken(token) {
  return window.localStorage.setItem('token', token);
}

export function removeToken() {
  // return window.localStorage.clear();

  window.localStorage.removeItem('access_token');
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('routers');
  window.localStorage.removeItem('name');
  window.localStorage.removeItem('avatar');
  window.localStorage.removeItem('designerRule');
  window.localStorage.removeItem('userId');
  window.localStorage.removeItem('userType');
  window.localStorage.removeItem('userLabel');
  window.localStorage.removeItem('industryTrade');
  window.localStorage.removeItem('industryTypeValue');
  window.localStorage.removeItem('uacUserId');
  window.localStorage.removeItem('partnerType');
  window.localStorage.removeItem('retailStoreList');
  window.localStorage.removeItem('needId');
  window.localStorage.removeItem('storeInfo');
  window.localStorage.removeItem('isRegisterComplete');
  window.localStorage.removeItem('btnList');
  window.localStorage.removeItem('storageAside');
  window.localStorage.removeItem('platform');
  window.localStorage.removeItem('haierNo');
  window.localStorage.removeItem('mobile');
  window.localStorage.removeItem('userCurrentAuth');
  window.localStorage.removeItem('userProfileState');
  window.localStorage.removeItem('operationUserName');
  window.localStorage.removeItem('operationUserMobile');
  window.localStorage.removeItem('authFlag');
  window.localStorage.removeItem('infoFlag');
  window.localStorage.removeItem('registerUserType');
  window.localStorage.removeItem('fullBtnList');
  window.localStorage.removeItem('designerPhone');
  window.localStorage.removeItem('lastName');
}

/**
 * @description 获取星云token获取对应工号
 * @param {String} token 星云token
 */
export function getUserJobNum(token) {
  return new Promise((resolve, reject) => {
    request({
      url: serverUrl + '/openApi/getUserInfoByNebulaToken?xAccessToken=' + token,
      method: 'get',
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
