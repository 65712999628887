const QuotatuinStore = {
  state: {
    viewType: window.localStorage.getItem('viewType') || null,
  },
  mutations: {
    setViewType: (state, viewType) => {
      window.localStorage.setItem('viewType', viewType || '');
      state.viewType = viewType;
    },
  },
  actions: {
    setQuotationViewType: ({ commit }, viewType) => {
      commit('setViewType', viewType);
    },
  },
};
export default QuotatuinStore;
