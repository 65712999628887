<template>
  <el-button v-bind="$attrs" v-on="$listeners" :style="buttonStyle">
    <slot></slot>
  </el-button>
</template>

<script>
  export default {
    name: 'ZjsjHomeButton',
    inheritAttrs: false,
    props: {
      customStyle: {
        type: Object,
        default: () => ({}),
      },
    },
    watch: {
      $attrs: function (val) {
        if (!val.type) {
          val.type = 'primary';
        }
      },
    },
    computed: {
      isDefault() {
        return this.$attrs.type === 'default';
      },
      buttonStyle() {
        let commStyle = {
          'border-radius': '22px',
          color: this.isDefault ? '#666' : '#fff',
          'font-size': '16px',
          'font-weight': 500,
          'border-color': this.isDefault ? '#eee' : 'rgb(190, 150, 90, 0.2)',
          height: '40px',
          'box-sizing': 'border-box',
        };
        return this.$attrs.disabled || this.$attrs.disabled === ''
          ? {
              backgroundColor: this.isDefault
                ? 'rgba(255, 255, 255, 1)'
                : 'rgba(217, 194, 162, 0.9)',

              ...commStyle,
              ...this.customStyle,
            }
          : { background: '#BE965A', ...commStyle, ...this.customStyle };
      },
    },
    methods: {
      // handleClick() {
      //   this.$emit('click');
      // },
    },
  };
</script>

<style lang="less" scoped>
  .el-button:focus {
    border-color: rgba(217, 194, 162, 0.9);
  }
  .el-button--text:focus {
    border-color: transparent;
  }
</style>
