import Vue from 'vue';
import Router from 'vue-router';
import LayoutLogin from '../layout/loginHome'; // login布局
import { Layout } from './layout'; // 界面整体使用哪种layout布局
import publication from './submit-for-publication'; // 样板间投稿相关路由
import zjsj from './zjsj'; // 筑巢设计相关路由
import { DataCockPitRouter } from './dataCockpit.router'; // 数据驾驶舱路由
import { CrmRouter, CRM_ANALYSE_ROUTE, CRM_SETTING } from './crm.router';
import { TROUBLEROUTER } from '@/router/trouble.router';
import { ISMROUTER } from './ism.router';
import { HaierHomeRouter } from './haierHome.router';

Vue.use(Router);

// 解决Redirected when going from "/xxx" to "/yyy" via a navigation guard.报错
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  }
  return originalReplace.call(this, location).catch((err) => err);
};
// end~

// 无权限路由
export const commonRouters = [
  { path: '', component: Layout, redirect: '', hidden: true },
  // { path: '/login', component: () => import('@/page/login'), hidden: true },
  // { path: '/login', component: () => import('@v/register/login3D'), hidden: true },
  // { path: '/register', component: () => import('@v/register/register'), hidden: true },
  // { path: '/forgetpwd', component: () => import('@/page/forgetPwd'), hidden: true },
  {
    path: '/',
    component: LayoutLogin,
    hidden: true,
    children: [
      // {
      //   path: 'login',
      //   meta: {
      //     title: '登录',
      //   },
      //   component: () => import('@v/register/login3D'),
      // },
      // {
      //   path: 'register',
      //   meta: {
      //     title: '注册',
      //   },
      //   component: () => import('@v/register/register'),
      // },
      // {
      //   path: 'forgetpwd',
      //   meta: {
      //     title: '忘记密码',
      //   },
      //   component: () => import('@/page/forgetPwd'),
      // },
      {
        path: 'operateManagePlat',
        meta: {
          title: '设计师·运营管理',
        },
        component: () => import('@v/register/operateManagePlat'),
      },
    ],
  },
  {
    path: '/404',
    component: () => import('@/page/errorPage/404'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/page/errorPage/401'),
    hidden: true,
  },
  ...zjsj,
];

// 全部路由
export const constantRouterMap = [
  ...commonRouters,
  ...publication,
  {
    path: '/case',
    component: Layout,
    meta: {
      title: '全国案例',
      icon: 'el-icon-menu',
    },
    noDropdown: true,
    children: [
      {
        path: 'caselist',
        meta: {
          title: '全国案例',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/caseList'),
      },
      {
        path: 'personcase',
        meta: {
          title: '个人案例',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/personCase'),
      },
      {
        path: 'casePersonalDesigner',
        meta: {
          title: '黑标设计创造营',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/casePersonal/casePersonalDesigner'),
      },
      {
        path: 'caseadd',
        meta: {
          title: '添加案例',
          meta: {
            keepAlive: true,
          },
        },
        component: () => import('@/page/case/caseAdd'),
      },
      {
        path: 'caseedit',
        meta: {
          title: '编辑案例',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/caseEdit'),
      },
      {
        path: 'casedetail',
        meta: {
          title: '案例详情',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/caseDetail'),
      },
      {
        path: 'caselinkedlist',
        meta: {
          title: '关联清单',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/caseLinkedList'),
      },
      {
        path: 'caselinkeddetail',
        meta: {
          title: '关联详情',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/caseLinkedDetail'),
      },
      {
        path: 'designcase',
        meta: {
          title: '我的方案',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/designCase'),
      },
      {
        path: 'designcasesub',
        meta: {
          title: '子账号方案',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/subAccount/designCase'),
      },
      {
        path: 'Appman',
        meta: {
          title: '回收站',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/appman'),
      },
      {
        path: 'inventory',
        meta: {
          title: '案例清单',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/caseInventory'),
      },
      {
        path: 'quotationDetails',
        meta: {
          title: '案例清单',
          routerType: 'leftmenu',
        },
        component: () => import('@opn/__Base__Utility/_Components/_EUI/v2x/QuotationDetails/index.vue'),
      },
      {
        path: 'casesyn',
        meta: {
          title: '设计案例同步',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/designCaseSyn'),
      },
      {
        path: 'designcaselinkdetail',
        meta: {
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/designCaseLinkDetail'),
      },
      {
        path: 'designcaselinkinventory',
        meta: {
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/designCaseLinkInventory'),
      },
      {
        path: 'matchlinkeddetail',
        meta: {
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/designMatch/matchLinkedDetail'),
      },
      {
        path: 'matchlinkedlist',
        meta: {
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/designMatch/matchLinkedList'),
      },
    ],
  },
  {
    path: '/kxjIframe',
    component: Layout,
    meta: {
      title: '营销管理工具',
      icon: 'el-icon-menu',
    },
    noDropdown: false,
    children: [
      {
        path: 'statistics',
        meta: {
          title: '营销统计',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/kxjIframe/statistics'),
      },
      {
        path: 'commodityManagement',
        meta: {
          title: '商品管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/kxjIframe/kxjIframe'),
      },
      {
        path: 'planManagement',
        meta: {
          title: '方案编辑',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/kxjIframe/planManagement'),
      },
      {
        path: 'appManagement',
        meta: {
          title: '应用编辑',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/kxjIframe/appManagement'),
      },
      {
        path: 'kxjCommLogin',
        meta: {
          title: '',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/kxjIframe/kxjCommIframe'),
      },
      {
        path: 'recycleBin',
        meta: {
          title: '回收站',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/case/kxjIframe/recycleBin'),
      },
    ],
  },
  {
    path: '/workOrder',
    component: Layout,
    meta: {
      title: '设计工单',
      icon: 'el-icon-s-order',
    },
    noDropdown: true,
    children: [
      {
        path: 'info',
        name: 'workOrderInfo',
        meta: {
          title: '设计工单',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/workOrder/workOrderList'),
      },
      {
        path: 'createWorkOrder',
        meta: {
          title: '新建工单',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/workOrder/createWorkOrder'),
      },
      {
        path: 'infoSub',
        meta: {
          title: '设计工单',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/workOrder/workOrderListSub'),
      },
      {
        path: 'designSelect',
        meta: {
          title: '设计图纸选择',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/workOrder/designSelect'),
      },
      {
        path: 'designDisclosure',
        meta: {
          title: '设计交底',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/disclosure/designerConstruction'),
      },
      {
        path: 'designDetailContainer',
        meta: {
          title: '设计交底详情',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/disclosure/designDetailContainer/designDetailContainer'),
      },
      {
        path: 'offer',
        meta: {
          title: '橱柜报价管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/workOrder/offerManage'),
      },
      {
        path: 'order',
        meta: {
          title: 'OMS订单同步',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/workOrder/omsOrder'),
      },
      {
        path: 'detail',
        meta: {
          title: '工单详情',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/workOrder/detailIframe'),
      },
      {
        path: 'detailContainer',
        meta: {
          title: '工单详情',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/workOrder/detailContainer'),
      },
      {
        path: 'drawingSelect',
        meta: {
          title: '图纸加载',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/workOrder/detailPage/drawingSelect'),
      },
      {
        path: 'amountRoomDetail',
        meta: {
          title: '',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/workOrder/detailPage/amountRoomDetail'),
      },
      {
        path: 'businessProcess',
        meta: {
          title: '商机查看',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/workOrder/detailPage/businessProcess'),
      },
      {
        path: 'caseIframe',
        meta: {
          title: '',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/workOrder/detailPage/iframe/caseIframe'),
      },
    ],
  },
  {
    path: '/home',
    component: Layout,
    meta: {
      title: '首页管理',
      icon: 'el-icon-s-home',
    },
    noDropdown: false,
    children: [
      {
        path: 'homemgr',
        meta: {
          title: '优秀设计管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/home/homeMgr'),
      },
      {
        path: 'homerec',
        meta: {
          title: '首页推荐配置',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/home/homeRec'),
      },
    ],
  },
  {
    path: '/message',
    component: Layout,
    meta: {
      title: '消息管理',
      icon: 'el-icon-s-home',
    },
    noDropdown: false,
    children: [
      {
        path: 'inboxlist',
        meta: {
          title: '收件箱列表',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/message/inboxList'),
      },
      {
        path: 'messagemgr',
        meta: {
          title: '站内信管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/message/messageMgr'),
      },
      {
        path: 'inboxadd',
        meta: {
          title: '新增站内信',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/message/inboxAdd'),
      },
      {
        path: 'inboxedit',
        meta: {
          title: '编辑站内信',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/message/inboxEdit'),
      },
    ],
  },
  {
    path: '/banner',
    component: Layout,
    meta: {
      title: '轮播管理',
      icon: 'el-icon-s-home',
    },
    noDropdown: true,
    children: [
      {
        path: 'bannerlist',
        meta: {
          title: '轮播管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/banner/bannerList'),
      },
      {
        path: 'advertisement',
        component: () => import('@/page/banner/advertisement.vue'),
        meta: {
          title: '轮播图管理',
          routerType: 'leftmenu',
        },
      },
      {
        path: 'basebanner',
        component: () => import('@/page/banner/baseBanner.vue'),
        meta: {
          title: '广告管理',
          routerType: 'leftmenu',
        },
      },
      {
        path: 'banneradd',
        meta: {
          title: '轮播添加',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/banner/bannerAdd'),
      },
      {
        path: 'banneredit',
        meta: {
          title: '轮播修改',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/banner/bannerEdit'),
      },
      {
        path: 'bannerdetail',
        meta: {
          title: '案例详情',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/banner/bannerDetail'),
      },
    ],
  },
  {
    path: '/sce',
    component: Layout,
    meta: {
      title: '家电案例管理',
      icon: 'el-icon-s-home',
    },
    noDropdown: false,
    children: [
      {
        path: 'scelist',
        meta: {
          title: '家电案例管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/scenarioCase/sceCaseList'),
      },
    ],
  },
  {
    path: '/model',
    component: Layout,
    meta: {
      title: '模型管理',
      icon: 'el-icon-s-home',
    },
    noDropdown: true,
    children: [
      {
        path: 'modelmgr',
        meta: {
          title: '模型管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/model/modelMgr'),
      },
      {
        path: 'modeledit',
        meta: {
          title: '模型修改',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/model/modelEdit'),
      },
      {
        path: 'downloads',
        meta: {
          title: '模型下载量',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/model/downloads'),
      },
    ],
  },
  {
    path: '/stats',
    component: Layout,
    meta: {
      title: '数据统计',
      icon: 'el-icon-menu',
    },
    noDropdown: false,
    children: [
      {
        path: 'integratedata',
        meta: {
          title: '综合数据',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/stats/integrateData'),
      },
    ],
  },
  {
    path: '/package',
    component: Layout,
    meta: {
      title: '成套方案管理',
      icon: 'el-icon-s-home',
    },
    noDropdown: true,
    children: [
      {
        path: 'packagemgr',
        meta: {
          title: '成套方案管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/package/packageMgr'),
      },
      {
        path: 'packageadd',
        meta: {
          title: '成套方案添加',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/package/packageAdd'),
      },
      {
        path: 'packageedit',
        meta: {
          title: '成套方案修改',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/package/packageEdit'),
      },
      {
        path: 'packageselect',
        meta: {
          title: '成套方案选择',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/package/packageSelect'),
      },
    ],
  },
  {
    path: '/realCase',
    component: Layout,
    meta: {
      title: '真实案例管理',
      icon: 'el-icon-s-home',
    },
    noDropdown: true,
    children: [
      {
        path: 'realCaseMgr',
        meta: {
          title: '真实案例管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/realCase/realCaseMgr'),
      },
      {
        path: 'realCaseEdit',
        meta: {
          title: '真实案例添加、编辑',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/realCase/realCaseEdit'),
      },
    ],
  },
  {
    path: '/person',
    component: Layout,
    meta: {
      title: '个人中心',
      icon: 'el-icon-s-home',
    },
    noDropdown: true,
    children: [
      {
        path: 'info',
        meta: {
          title: '个人中心',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/person/personInfo'),
      },
      {
        path: 'authApply',
        meta: {
          title: '权益申请',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/person/authApply'),
      },
      {
        path: 'auth',
        meta: {
          title: '认证信息',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/person/designerAuth'),
      },
    ],
  },
  {
    path: '/pwd',
    component: Layout,
    meta: {
      title: '密码',
      icon: 'el-icon-s-home',
    },
    noDropdown: true,
    children: [
      {
        path: 'changePwd',
        meta: {
          title: '修改密码',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/password/changePwd'),
      },
      {
        path: 'setPwd',
        meta: {
          title: '设置密码',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/password/setPwd'),
      },
      {
        path: 'changeMobile',
        meta: {
          title: '修改手机号码',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/password/changeMobile'),
      },
      {
        path: 'changeEmail',
        meta: {
          title: '设置/修改邮箱',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/password/changeEmail'),
      },
    ],
  },
  {
    path: '/designerManger',
    component: Layout,
    meta: {
      title: '设计师管理',
      icon: 'el-icon-setting',
    },
    noDropdown: false,
    children: [
      {
        path: 'designerRole',
        meta: {
          title: '设计师角色',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/designerManger/designerRole'),
      },
    ],
  },
  {
    path: '/materialCentre',
    name: 'materialCentre',
    component: Layout,
    meta: {
      title: '素材中心',
      icon: 'el-icon-orange',
    },
    noDropdown: false,
    children: [
      {
        path: 'modelManage',
        name: 'modelManage',
        meta: {
          title: '模型管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/materialCentre/modelPage.vue'),
      },
      {
        path: 'modelManageItem',
        name: 'modelManageItem',
        meta: {
          title: '模型编辑',
        },
        component: () => import('@/page/materialCentre/components/modelDetail.vue'),
      },
      {
        path: 'drawingManage',
        name: 'drawingManage',
        meta: {
          title: '图纸管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/materialCentre/drawingPage.vue'),
      },
      {
        path: 'drawingManageItem',
        name: 'drawingManageItem',
        meta: {
          title: '图纸详情',
        },
        component: () => import('@/page/materialCentre/components/drawingDetail.vue'),
      },
    ],
  },
  {
    path: '/tagCategory',
    name: 'tagCategory',
    component: Layout,
    meta: {
      title: '标签管理',
      icon: 'el-icon-collection-tag',
    },
    noDropdown: false,
    children: [
      {
        path: 'searchTagManage',
        name: 'searchTagManage',
        meta: {
          title: '检索标签管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/materialCentre/tagCategory.vue'),
      },
      // {
      //   path: 'searchTagManageItem',
      //   name: 'searchTagManageItem',
      //   meta: {
      //     title: '检索标签',
      //   },
      //   component: () => import('@/page/materialCentre/components/tagDetail.vue'),
      // },
    ],
  },
  {
    path: '/manager',
    component: Layout,
    meta: {
      title: '系统管理',
      icon: 'el-icon-setting',
    },
    noDropdown: false,
    children: [
      {
        path: 'user',
        meta: {
          title: '设计师列表',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/user'),
      },
      {
        path: 'deleteDetail',
        meta: {
          title: '设计师列表',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/deleteDetail'),
      },
      {
        path: 'delTable',
        meta: {
          title: '设计师列表',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/delTable'),
      },
      {
        path: 'role',
        meta: {
          title: '角色管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/role'),
      },
      {
        path: 'auz',
        meta: {
          title: '权限管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/auz'),
      },
      {
        path: 'menu',
        meta: {
          title: '菜单管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/menu'),
      },
      {
        path: 'origan',
        meta: {
          title: '组织账号管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/origanizationManage'),
      },
      {
        path: 'menuManage',
        meta: {
          title: '菜单管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/menuManage'),
      },
      {
        path: 'roleManage',
        meta: {
          title: '角色权限管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/roleManage'),
      },
      {
        path: 'accountManage',
        meta: {
          title: '账号管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/accountManage'),
      },
      {
        path: 'TemplateManagement',
        meta: {
          title: '模板管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/TemplateManagement'),
      },
      {
        path: 'depManage',
        meta: {
          title: '部门管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/depManage'),
      },
      {
        path: 'orgManage',
        meta: {
          title: '组织管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/orgManageComps/orgManage'),
      },
      {
        path: 'dataDic',
        meta: {
          title: '数据字典',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/dataDicComps/dataDic'),
      },
      {
        path: 'operationManage',
        meta: {
          title: '运营信息配置',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/operationManage'),
      },
      {
        path: 'metabaseManage',
        meta: {
          title: 'metabase管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/manager/metabaseManage/metabaseManage'),
      },
    ],
  },
  {
    path: '/permission',
    component: Layout,
    meta: {
      title: '权限审核',
      icon: 'el-icon-menu',
    },
    noDropdown: false,
    children: [
      {
        path: 'peraudit',
        meta: {
          title: '权限审核',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/permission/perAudit'),
      },
      {
        path: 'designeraudit',
        meta: {
          title: '设计师审核',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/permission/perChildComps/designerAudit'),
      },
      {
        path: 'permissionaudit',
        meta: {
          title: '设计权益审核',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/permission/perChildComps/permissionAudit'),
      },
      {
        path: 'rightsDistribution',
        meta: {
          title: '权益分配订单',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/permission/perChildComps/rightsDistribution'),
      },
      {
        path: 'perauditDetail',
        meta: {
          title: '审核详情',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/permission/perChildComps/designerAudit/detail'),
      },
    ],
  },
  {
    path: '/designIframe',
    component: () => import('@/page/case/designIframe'),
    noDropdown: false,
    children: [],
  },
  {
    path: '/designCircleIframe',
    meta: {
      title: '设计圈',
    },
    component: () => import('@/page/case/designCircleIframe'),
    noDropdown: false,
    children: [],
  },
  {
    path: '/operate_kxjCommLogin',
    component: () => import('@/page/case/kxjIframe/kxjCommIframe'),
    noDropdown: false,
    children: [],
  },
  {
    path: '/operate_designIframe',
    component: () => import('@/page/case/kxjIframe/operateIframe'),
    noDropdown: false,
    children: [],
  },
  {
    path: '/designIframeEdit',
    component: () => import('@/page/case/designIframeEdit'),
    noDropdown: false,
    children: [],
  },
  {
    path: '/HouseTourIframe',
    component: () => import('@/page/nesting/HouseTourIframe'),
    noDropdown: false,
    children: [],
  },
  {
    path: '/caseconfig',
    component: Layout,
    meta: {
      title: 'DIY-方案配置',
      icon: 'el-icon-menu',
    },
    noDropdown: false,
    children: [
      {
        path: 'stylemgr',
        meta: {
          title: '风格管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/caseConfig/styleMgr'),
      },
      {
        path: 'casemgr',
        meta: {
          title: '方案管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/caseConfig/caseMgr'),
      },
      {
        path: 'caseadd',
        meta: {
          title: '上传DIY方案',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/caseConfig/caseAdd'),
      },
      {
        path: 'categoryconfig',
        meta: {
          title: '类目配置',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/caseConfig/categoryConfig'),
      },
    ],
  },
  {
    path: '/quo',
    component: Layout,
    meta: {
      title: '家装报价管理',
      icon: 'el-icon-s-shop',
    },
    noDropdown: false,
    children: [
      {
        path: 'ecopackage',
        meta: {
          title: '生态家装舒适套餐',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/quoManage/decEcoPackage'),
      },
      {
        path: 'prapackage',
        meta: {
          title: '生态家装实用套餐',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/quoManage/decPraPackage'),
      },
      {
        path: 'luxpackage',
        meta: {
          title: '生态家装豪华套餐',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/quoManage/decLuxPackage'),
      },
    ],
  },
  {
    path: '/designercompet',
    component: Layout,
    meta: {
      title: '黑标创造营管理',
      icon: 'el-icon-s-shop',
    },
    noDropdown: false,
    children: [
      {
        path: 'list',
        meta: {
          title: '黑标创造营管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/designerCompet/designerCompetList'),
      },
      {
        path: 'designerCompeteEdit',
        meta: {
          title: '比赛信息设置',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/designerCompet/designerCompeteEdit'),
      },
      {
        path: 'eventDetails',
        meta: {
          title: '黑标创造营详情',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/designerCompet/eventDetails'),
      },
      {
        path: 'commentManage',
        meta: {
          title: '评论管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/designerCompet/commentManage'),
      },
      {
        path: 'tabulation',
        meta: {
          title: '设计师大赛列表',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/designerCompet/tabulation'),
      },
      {
        path: 'establish',
        meta: {
          title: '创建设计师大赛',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/designerCompet/establish'),
      },
      {
        path: 'particular',
        meta: {
          title: '设计师大赛详情',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/designerCompet/particular'),
      },
    ],
  },
  {
    path: '/agreement',
    meta: { title: '用户协议' },
    noDropdown: false,
    component: () => import('@/page/person/authChildComps/agreement'),
  },
  {
    path: '/shop',
    component: Layout,
    meta: {
      title: '门店列表',
      icon: 'el-icon-s-home',
    },
    noDropdown: true,
    children: [
      {
        path: 'shopList',
        meta: {
          title: '门店资源池',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/shop/shopList'),
      },
      {
        path: 'mystore',
        meta: {
          title: '我的门店',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/shop/myStore'),
      },
    ],
  },
  {
    path: '/dec',
    component: Layout,
    meta: {
      title: '主页管理',
      icon: 'el-icon-s-home',
    },
    noDropdown: true,
    children: [
      {
        path: 'homedecoration',
        meta: {
          title: '主页装修',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/homeDecoration/decoration'),
      },
    ],
  },
  {
    path: '/pageCenter',
    component: Layout,
    meta: {
      title: '页面中心',
      icon: 'el-icon-s-home',
    },
    noDropdown: true,
    children: [
      {
        path: 'avatarManage',
        meta: {
          title: '默认头像管理',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/pageCenter/avatarManage'),
      },
      {
        path: 'avatarAdd',
        meta: {
          title: '默认头像上传',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/pageCenter/avatarAdd'),
      },
    ],
  },
  {
    path: '/simu',
    component: Layout,
    meta: {
      title: '设计师规则管理',
      icon: 'el-icon-s-home',
    },
    noDropdown: true,
    children: [
      {
        path: 'simulation',
        meta: {
          title: '仿真数据规则',
          routerType: 'leftmenu',
        },
        component: () => import('@/page/simulation/simulation'),
      },
    ],
  },
  DataCockPitRouter,
  CrmRouter,
  CRM_ANALYSE_ROUTE,
  CRM_SETTING,
  TROUBLEROUTER,
  ISMROUTER,
  HaierHomeRouter,
];
let router = new Router({
  routes: constantRouterMap,
  scrollBehavior() {
    return {
      y: 0, // Vue路由切换时新页面自动回到顶部 // return 期望滚动到哪个的位置
    };
  },
});
router.$addRoutes = (params) => {
  router.matcher = new Router().matcher;
  router.addRoutes(params);
};
//注册路由
export default router;
