<template>
  <div class="home">
    <!-- 设计师 - 后台管理 -->
    <head-nav></head-nav>
    <div class="left-fixed-right-auto">
      <left-menu></left-menu>
      <div class="content_page" ref="content_page">
        <div class="content">
          <keep-alive :include="componentName">
            <router-view></router-view>
            <!--页面渲染入口-->
          </keep-alive>
        </div>
      </div>
    </div>
    <download-center></download-center>
    <routerToHomeDialog></routerToHomeDialog>
  </div>
</template>
<script>
  import HeadNav from './headNav.vue';
  import LeftMenu from './leftMenu.vue';
  import { EventBus, setFixedHeight } from '@/utils/event-bus';
  import { WINDOW_RESIZE } from '@/utils/const';
  import { isIframeInNebula } from '@/utils/beforeSetUp';
  import downloadCenter from '@/layout/downloadCenter.vue';
  import routerToHomeDialog from '@/layout/routerToHomeDialog';
  export default {
    name: 'home3D',
    data() {
      return {
        setHeight: () => null,
        componentName: [
          'submissionReviewList',
          'quickDesign',
          'onlineDesignManage',
          'workOrderInfo',
          'designerAudit',
          'caselist',
          'modelManage',
          'drawingManage',
        ],
      };
    },
    computed: {},
    components: {
      downloadCenter,
      HeadNav,
      LeftMenu,
      routerToHomeDialog,
    },
    created() {},
    mounted() {
      document.querySelector('body').setAttribute('style', 'overflow: hidden; height: 100%;');
      document.querySelector('html').setAttribute('style', 'overflow: hidden; height: 100%;');
      let headerHeight = 60;
      if (isIframeInNebula()) {
        headerHeight = 0;
      }
      this.setHeight = setFixedHeight(this, this.$refs.content_page, headerHeight);
      this.setHeight();
      EventBus.$on(WINDOW_RESIZE, this.setHeight);
    },
    beforeDestroy() {
      EventBus.$off(WINDOW_RESIZE, this.setHeight);
    },
    methods: {},
  };
</script>
<style scoped lang="less">
  body,
  html {
    width: 100%;
    height: 100%;
  }
  @import '@/assets/style/less/baseContentPage.less';
  .home {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
  }

  .left-fixed-right-auto {
    width: 100%;
    display: flex;
    flex: auto;
  }

  .content_page {
    width: calc(100% - 220px);
    flex: auto;
    background: #fff;
    display: flex;
    overflow-y: auto;
  }
  .content {
    overflow: auto;
    width: 100%;
    flex: auto;
    background: #f0f0f0;
  }
</style>
