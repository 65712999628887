var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"store_table"},[_c('el-table',{attrs:{"data":_vm.retailStoreList,"border":"","size":"small"}},[_c('el-table-column',{attrs:{"prop":"storeCode","label":"店铺编码","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"storeName","label":"店铺名称","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"mdCode","label":"门店编码","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"mdName","label":"门店名称","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"操作","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('ZjsjHomeButton',{attrs:{"type":"text","customStyle":{
            'font-size': '12px',
            color: '#BE965A',
            'text-align': 'justify',
            'font-weight': 400,
            background: 'transparent',
            'border-color': 'none',
          }},on:{"click":function($event){return _vm.remove(scope.row)}}},[_vm._v("移除")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }