<template>
  <div class="account_register">
    <div class="register_img register_item">
      <div class="left_carousel" v-if="bannerList && bannerList.length > 0">
        <carousel-hand :imgList="bannerList" :carData="carData"></carousel-hand>
      </div>
      <ZjsjImage v-else :src="require('@/assets/img/login/login_img.png')" fit="fill"></ZjsjImage>
    </div>
    <div class="right_part register_item">
      <div class="right_part_content">
        <div class="title">设计师注册</div>
        <i class="el-icon-close" @click="handleClose"></i>
        <!-- 表单 -->
        <el-form
          class="account_form"
          :model="ruleForm"
          size="medium"
          :rules="rules"
          @validate="handleValidateEvent"
          ref="ruleForm">
          <div>
            <el-form-item prop="mobile">
              <el-input
                type="text"
                :maxlength="11"
                v-model="ruleForm.mobile"
                autocomplete="off"
                placeholder="请输入手机号"
                clearable></el-input>
            </el-form-item>
            <el-form-item prop="mobileCode">
              <el-input
                type="text"
                class="mobile_code_input"
                v-model="ruleForm.mobileCode"
                autocomplete="off"
                placeholder="请输入验证码"
                clearable></el-input>
              <span class="ver_phone get_captcha_code" @click="getPhoneCode">{{ verPhone }}</span>
              <span class="ver_phone not_allowed">{{ countDownText }}</span>
            </el-form-item>

            <el-form-item class="register_btn_container">
              <ZjsjHomeButton
                size="large"
                :disabled="registerBtnDisabled"
                :loading="btnLoading"
                :customStyle="{ width: '100%', height: '48px' }"
                @click="submitForm('ruleForm')">
                注册
              </ZjsjHomeButton>
            </el-form-item>
            <div class="protocol_container">
              <el-checkbox v-model="protocolChecked">
                <span>同意</span>
              </el-checkbox>
              <span class="protocol_list">
                <span
                  :class="item.type === 'word' ? '' : 'protocol_item'"
                  v-for="(item, index) in protocolList"
                  :key="index"
                  @click="handleProtocol(item)">
                  {{ item.name }}
                </span>
              </span>
            </div>
          </div>
        </el-form>
        <div class="login_wrapper">
          <div class="to_login">
            <span>已有账号？</span>
            <el-button type="text" v-gio-track="{ id: 'CZHT30163' }" class="home_them_text_color" @click="toLogin">
              立即登录
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { serverUrl } from '@/utils/env';
  import request from '@/utils/axios';
  import { isMobile } from '@/utils/mUtils';
  import { getToken } from '@/utils/auth'; // 验权
  import carouselHand from '../zjsj/components/carousel.vue';

  export default {
    components: {
      carouselHand,
    },
    data() {
      // 校验手机号码
      let checkMobile = (rule, value, callback) => {
        if (value) {
          if (isMobile(value)) {
            callback();
          } else {
            callback(new Error(rule.message));
          }
        }
      };
      return {
        isHaveMobile: getToken(), // 是否有缓存手机号
        btnLoading: false,
        userPhone: '',
        protocolChecked: false,
        validateResult: {
          mobile: false,
          mobileCode: false,
          captcha_answer: false,
        },
        ruleForm: {
          mobile: '', // 手机号
          captcha_answer: '', // 图片验证码
          captcha_token: '', // 图片验证码token
          mobileCode: '', // 短信验证码
        },
        rules: {
          mobile: [
            { required: true, message: '请输入您的手机号', trigger: 'change' },
            {
              validator: checkMobile,
              message: '请输入正确的手机号',
              trigger: 'blur',
            },
          ],
          captcha_answer: [],
          mobileCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
        },
        canClickCode: true,
        imgSrc: '',
        // 手机号定时器
        timePromisePhone: null,
        captchaFlagPhone: true,
        countDownText: null,
        //验证码
        verPhone: '获取验证码',
        secondPhone: 60,
        bannerList: [],
        carData: {
          arrow: 'never',
          interval: 3000,
          height: '564px',
        },
        protocolList: [
          {
            name: '《三翼鸟筑巢设计平台用户服务协议》',
            link: '/mgr/service_protocol_v3.html',
            type: 'html',
          },
          {
            name: '和',
            link: '',
            type: 'word',
          },
          {
            name: '《三翼鸟筑巢设计平台个人信息保护政策》',
            link: '/mgr/info_protect_protocol.html',
            type: 'html',
          },
        ],
      };
    },
    computed: {
      registerBtnDisabled() {
        // 有一个没通过校验的就禁用
        return !this.validateResult.mobile || !this.validateResult.mobileCode;
      },
    },
    destroyed() {
      clearInterval(this.timePromisePhone);
      this.captchaFlagPhone = true;
      this.countDownText = '';
      this.secondPhone = 60;
    },
    created() {
      this.getBannerData();
      this.getImgCode();
    },
    mounted() {},
    methods: {
      getBannerData() {
        request({
          url: serverUrl + '/newPageController/binners?inColumn=4', //  inColumn :1.官方首页轮播；2.工作台弹窗 ;3.明星轮播图 ;4.登录轮播图
          method: 'get',
        })
          .then((res) => {
            if (res.status === '0') {
              this.bannerList = (res.data || []).map((e) => {
                // inColumn	所属栏目： 1.官方首页轮播；2.工作台弹窗 ; 3.明星轮播图 ;4.登录轮播图
                // isEnable	是否可用
                if (e.inColumn === '4' && e.isEnable) {
                  return {
                    id: e.id,
                    fpath: e.imgUrl,
                    clickUrl: e.clickUrl,
                    type: e.type,
                    caseId: e.caseId,
                    menuUrl: e.menuUrl,
                    imgUrlType: e.imgUrlType,
                  };
                }
              });
            }
          })
          .finally(() => {});
      },
      // 获取图片验证码
      getImgCode() {
        if (!this.canClickCode) {
          return;
        }
        this.canClickCode = false;

        request({
          url: serverUrl + '/captcha',
          method: 'post',
        }).then((res) => {
          this.canClickCode = true;

          if (res.status === '0' && res.data.captcha_image) {
            this.imgSrc = res.data.captcha_image;
            this.ruleForm.captcha_token = res.data.captcha_token;
          } else {
            this.$message.error('获取图片验证码失败');
          }
        });
      },
      // 验证手机号是否是合伙人
      verifyPhoneNo(val) {
        return new Promise((resolve) => {
          request({
            url: serverUrl + '/register/verifyPhoneNo?phone=' + val,
            method: 'get',
          })
            .then((res) => {
              if (res.status === '0') {
                // 加入门店是否必填
                // 共享设计师注册，选择门店不是必填信息，无需标记为必填信息；店铺负责人、合伙人，注册时，此字段为必填信息
                // 1 - 店铺/店铺负责人; 2 - 店铺合伙人；3 - 共享设计师
                // window.localStorage.setItem(
                //   'partnerType',
                //   res.data.partnerType
                // );
                // needId=Y:合伙人
                // window.localStorage.setItem('needId', res.data.needId);
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch(() => {
              resolve(false);
            });
        });
      },
      // 获取手机号验证码
      async getPhoneCode() {
        if (!this.ruleForm.mobile) {
          return this.$message.error('请输入手机号');
        }
        if (!isMobile(this.ruleForm.mobile)) {
          return this.$message.error('请输入正确的手机号');
        }

        let params = {
          captcha_answer: '',
          captcha_token: '',
          phone_number: this.ruleForm.mobile,
          scenario: 'login',
        };

        request({
          url: serverUrl + '/sendVerification',
          method: 'post',
          data: params,
        })
          .then((res) => {
            if (res.status === '0' && JSON.parse(res.data).success) {
              this.$message.success('发送验证码成功');
              this.timePromisePhone = setInterval(() => {
                if (this.secondPhone <= 0) {
                  clearInterval(this.timePromisePhone);
                  this.timePromisePhone = undefined;
                  this.secondPhone = 60;
                  this.countDownText = '';
                  this.captchaFlagPhone = true;
                  this.verPhone = '获取验证码';
                } else {
                  this.verPhone = '';
                  this.countDownText = '剩余' + this.secondPhone + '秒';
                  this.captchaFlagPhone = false;
                  this.secondPhone--;
                }
              }, 1000);
            } else {
              this.getImgCode();
            }
          })
          .catch(() => {
            this.getImgCode();
          });
      },
      async submitForm(formName) {
        if (!this.protocolChecked) {
          this.$message.error('请阅读并勾选协议');
          return;
        }
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.btnLoading = true;
            if (this.ruleForm.mobile) {
              // const verifyFlag = await this.verifyPhoneNo(this.ruleForm.mobile);
              // if (!verifyFlag) {
              //   this.btnLoading = false;
              //   return;
              // }
            }

            // 验证码注册
            let params = {
              username: this.ruleForm.mobile,
              password: this.ruleForm.mobileCode,
              captcha_answer: '',
              captcha_token: '',
              type: 'sms',
              activityChannel: this.$route.query.channel,
            };

            const loading = this.$loading({
              lock: true,
              text: '注册中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
              customClass: 'syn-fullscreen-text-loading',
              target: document.querySelector('.div1'),
            });

            this.$store.dispatch('Login', params).then(async (res) => {
              this.btnLoading = false;
              if (res.type === 'delete') {
                this.$message.error(
                  '尊敬的设计师您好，由于您长时间未使用，账号已被系统删除，如需继续使用可联系三翼鸟总监进行账号恢复'
                );
                loading.close();
                this.getImgCode();
                return;
              }
              if (res.type === 'error') {
                loading.close();
                this.getImgCode();
                return;
              }
              if (res.data.accreditVo.grantType === 'register') {
                gio('track', 'CZHT30168'); // eslint-disable-line
              }
              await this.$store.dispatch('GenerateRoutes', getToken()); // 获取路由信息
              await this.$store.dispatch('accessRouters', getToken()); // 有权限路由
              loading.close();
              this.getImgCode();
              this.nextStep();
            });
          }
        });
      },
      gioTrack(id, payload) {
        if (payload) {
          gio('track', id, payload); // eslint-disable-line
        } else {
          gio('track', id); // eslint-disable-line
        }
      },
      nextStep() {
        // this.$router.push({
        //   path: '/zjsj/zjsjHome',
        //   query: { needReload: Math.random().toString() },
        // });
        // // authFlag - 设计师认证标识：0：未认证 1：审核中 2：认证成功 3：认证失败
        // if (Number(this.$store.state.user.authFlag) === 0) {
        //   this.$message.success('您已在三翼鸟APP或智家商城APP注册了账号，可以直接登录，系统将跳转到认证流程。');
        //   this.gioTrack('CZHT30164', {
        //     roughly_classification_name: '注册登录',
        //   });
        //   if (Number(this.$store.state.user.infoFlag) === 0) {
        //     this.$store.dispatch('setCurrentComponent', {
        //       name: 'SELECT_DESIGNER_TYPE',
        //     });
        //   } else {
        //     this.$store.dispatch('setCurrentComponent', {
        //       name: 'COMPLETE_DESIGNER_INFO',
        //     });
        //   }
        //   return;
        // }
        // if (Number(this.$store.state.user.authFlag) === 2) {
        //   this.$message.success('您已在三翼鸟APP或智家商城APP注册了账号，可以直接登录。');
        //   this.gioTrack('CZHT30164', {
        //     roughly_classification_name: '注册登录',
        //   });
        //   this.$store.dispatch('setCurrentComponent', null);
        //   return;
        // }
        this.$message.success('您已在三翼鸟APP或智家商城APP注册了账号，可以直接登录。');
        this.gioTrack('CZHT30164', {
          roughly_classification_name: '注册登录',
        });
        this.$store.dispatch('setCurrentComponent', null);
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      },
      handleClose() {
        this.$store.dispatch('setCurrentComponent', null);
      },
      toLogin() {
        this.$store.dispatch('setCurrentComponent', { name: 'LOGIN' });
      },
      handleProtocol(data) {
        if (data.type === 'url') {
          window.open(data.link);
        } else if (data.type === 'html') {
          let open = window.open(window.location.origin + data.link);
          try {
            open.onload = () => {
              open.document.title = data.name;
            };
          } catch (error) {
            console.log(error);
          }
        }
      },
      handleValidateEvent(key, res) {
        this.validateResult[key] = res;
      },
    },
  };
</script>

<style lang="less" scoped>
  .account_register {
    display: flex;
    height: 564px;
    overflow: hidden;
    border-radius: 8px;
    .register_img {
      width: 500px;
    }
    .left_carousel {
      width: 500px;
    }
    .register_item {
      height: 100%;
    }
    .account_form {
      margin-top: 20px;
      .el-form-item {
        .el-input {
          /deep/ .el-input__inner {
            &:focus {
              border-color: #be965a !important;
            }
          }
        }
        /deep/ .mobile_code_input {
          .el-input__inner {
            padding-right: 90px;
          }
          .el-input__suffix {
            right: 80px;
          }
        }
      }
      .captcha_answer_item {
        // height: 48px;
      }
      .flex {
        display: flex;
      }
      .code_img {
        margin-left: 10px;
        width: 175px;
        // height: 48px;
        cursor: pointer;
        border-radius: 8px;
      }
      .get_captcha_code {
        color: #bbb;
        &:hover {
          color: #be965a;
        }
      }
      .ver_phone {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        font-weight: 500;
        color: #bbb;
        cursor: pointer;
      }
      .not_allowed {
        cursor: not-allowed;
      }
      /deep/.el-input--medium .el-input__inner {
        height: 48px;
        line-height: 48px;
        border-radius: 8px;
      }
      .el-form-item {
        width: 360px;
        margin-bottom: 20px;
      }
      .el-input {
        width: 360px;
      }
      .wid175 {
        width: 175px;
      }
      .register_btn_container {
        margin-top: 52px;
      }
    }
    .right_part {
      position: relative;
      .right_part_content {
        padding: 40px 40px 0;
      }
      // display: flex;
      //   flex-direction: column;
      //   align-items: center;
      .title {
        font-size: 28px;
        font-weight: 500;
        color: #333333;
        height: 40px;
        line-height: 40px;
        text-align: center;
      }
      .el-icon-close {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 20px;
        cursor: pointer;
      }
      .login_wrapper {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 56px;
        background-color: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: center;
        .to_login {
          color: #666;
          font-size: 14px;
          .el-button {
            font-size: 14px;
          }
        }
      }
      /deep/ .protocol_container {
        font-size: 14px;
        width: 360px;
        .el-checkbox__label {
          color: #666;
        }
        .el-checkbox.is-checked {
          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: #be965a;
            border-color: #be965a;
          }
        }
        .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #666;
        }
        .el-checkbox__inner:hover,
        .el-checkbox__inner:focus {
          border-color: #be965a;
        }
        .el-checkbox__input.is-focus .el-checkbox__inner {
          border-color: #be965a;
        }
      }
      .protocol_item {
        color: #be965a;
        cursor: pointer;
      }
    }
  }
</style>
