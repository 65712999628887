import { serverUrl } from '@/utils/env';
import request from '@/utils/axios';

export const RegisterService = {
  verifyEmailOrNo: (params) => {
    return request.post(`${serverUrl}/register/verifyEmailOrNo`, params);
  },
};

/**
 * @description 校验海尔工号
 * @param {String} haierNo 海尔工号
 * @returns Promise
 */
export function validateHaierNo(haierNo) {
  return request({
    url: `${serverUrl}/register/verifyHaierNo?haierNo=${haierNo}`,
    method: 'get',
  });
}

/**
 * @description 获取注册类的字典项
 * @param {String} type 字典类型
 * @returns Promise
 */
export function registerDict(type) {
  return request({
    url: `${serverUrl}/register/queryDictionary?type=${type}`,
    method: 'get',
  });
}

/**
 * @description 设计是认证信息填充跳过
 * @param {Object} params 设计师信息
 * @returns Promise
 */
export function registerFillUserInfoTemp(params) {
  return request({
    method: 'post',
    url: `${serverUrl}/register/registerFillUserInfoTemp`,
    data: params,
  });
}

/**
 * @description 设计师认证提交 提交认证
 * @param {Object} designerInfo 提交认证信息
 * @returns Promise
 */
export function registerFillUserInfo(designerInfo) {
  return request({
    method: 'post',
    url: `${serverUrl}/register/registerFillUserInfo`,
    data: designerInfo,
  });
}

/**
 * @description 注册认证选择设计师身份 选择身份的下一步和跳过
 * @param { String } type 设计师身份类型code
 * @param { Number } infoFlag 标识是下一步还是跳过
 */
export function choseUserType(type, infoFlag) {
  return request({
    method: 'post',
    url: `${serverUrl}/register/choseUserType`,
    data: {
      type,
      infoFlag,
    },
  });
}
/**
 * @description 成为平台合伙人
 * @returns Promise
 */
export function becomePartner() {
  return request({
    url: `${serverUrl}/register/becomePfPartner`,
    method: 'post',
  });
}

/**
 * @description 身份证和姓名的验证
 * @param {Object} params 姓名 身份证号和token
 * @returns Promise
 */
export function validateIdCard(params) {
  return request({
    url: `${serverUrl}/register/idCardNameAuth`,
    method: 'post',
    data: params,
  });
}

/**
 * @description 封装验证真实姓名
 * @param {Object} params
 * @returns
 */
export async function handleValidateIdCard(params) {
  return new Promise((resolve, reject) => {
    validateIdCard(params)
      .then((res) => {
        resolve({ result: res && res.status === '0', message: res.msg });
      })
      .catch(() => {
        reject(false);
      });
  });
}
