import { constantRouterMap, commonRouters } from '@/router';
import axios from 'axios';
import { serverUrl } from '@/utils/env';
import ElementUI from 'element-ui';
import getRouter from '@/utils/getRouter';
import router from '@/router';

const permission = {
  state: {
    routers: constantRouterMap,
    topTitle: '',
    activeMenuId: '',
  },
  getters: {
    routers: (state) => state.routers, // 所有路由
    access_routers: (state) => state.access_routers, // 有权限路由
    topTitle: (state) => state.topTitle, // 顶部的title
    activeMenuId: (state) => state.activeMenuId, // 选中菜单id
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      window.localStorage.setItem('routers', JSON.stringify(routers));
      state.routers = routers; // 总路由
    },
    ACCESS_ROUTERS: (state, access_routers) => {
      state.access_routers = access_routers; // 有权限路由
    },
    CLICK_TOP_MENU: (state, data) => {
      state.topTitle = data.title;
    },
    SET_ACTIVE_MENU_ID: (state, id) => {
      window.sessionStorage.setItem('active-menu-id', id);
      state.activeMenuId = id;
    },
  },
  actions: {
    // 重新设置权限路由;并保存到vuex中,SET_ROUTERS;
    GenerateRoutes({ commit }, token) {
      return new Promise((resolve) => {
        axios
          .get(serverUrl + '/server/base/user/permission', {
            headers: {
              Authorization: token,
            },
          })
          .then((res) => {
            if (res.data && res.code === '0') {
              let tree = res.data;
              tree.forEach((m) => {
                if (m.children && m.children.length >= 1) {
                  m.children.forEach((c) => {
                    delete c.children;
                  });
                  m.noDropdown = false;
                  m.hidden = false;
                } else {
                  m.noDropdown = true;
                  m.hidden = false;
                }
              });
              commit('SET_ROUTERS', tree);
              window.localStorage.setItem('routers', JSON.stringify(res.data));
              getRouter(tree, router);
              resolve();
            } else {
              ElementUI.Message.error(res.msg ? res.msg : res.data);
              commit('SET_ROUTERS', []);
              window.localStorage.setItem('routers', '');
              resolve();
            }
          });
      });
    },
    // 路由白名单
    whiteRoutes({ commit }) {
      commit('SET_ROUTERS', commonRouters);
      window.localStorage.setItem('routers', JSON.stringify(commonRouters));
    },
    // 有权限路由
    accessRouters() {
      // 本地全量路由
      // let allRouters = [];
      // constantRouterMap.forEach((e) => {
      //   allRouters.push(e.path);
      //   if (e.children && e.children.length > 0) {
      //     e.children.forEach((f) => {
      //       if (e.path === '/') {
      //         allRouters.push(e.path + f.path);
      //       } else {
      //         allRouters.push(e.path + '/' + f.path);
      //       }
      //     });
      //   }
      // });
      // commit('ACCESS_ROUTERS', allRouters);
    },
    ClickLeftInnerMenu({ commit }, data) {
      commit('CLICK_INNER_LEFT_MENU', data);
    },
    ClickTopMenu({ commit }, data) {
      commit('CLICK_TOP_MENU', data);
    },
    SetActiveMenuId({ commit }, data) {
      commit('SET_ACTIVE_MENU_ID', data);
    },
  },
};

export default permission;
