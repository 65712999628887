export const DesignerCompetitionRouter = [
  {
    path: 'designerCompetition',
    name: 'designercompetition',
    component: () => import('@v/zjsj/zjsjDesignerCompetition.vue'),
    meta: {
      title: '赛事中心',
    },
  },
  {
    path: 'designerCompetitionDetail',
    name: 'designercompetitiondetail',
    component: () => import('@v/zjsj/designerCompetitionDetail.vue'),
    meta: {
      title: '赛事中心',
    },
  },
  {
    path: 'zjsjDesignerAwardDetail',
    name: 'design',
    component: () => import('@v/zjsj/zjsjDesignerAwardDetail.vue'),
    meta: {
      title: '赛事中心',
    },
  },
];
