import mockArr from '@/utils/mockUrl';
import { serverUrl } from '@/utils/env';
const MOCK_PREFIX = '/mock/api';
export function mockUrlTran(url) {
  // 去掉baseurl
  let urlTran = url.replace(serverUrl, '');
  // 如果有参数去掉，只保留接口路径
  if (urlTran.indexOf('?') !== -1) {
    urlTran = urlTran.slice(0, urlTran.indexOf('?'));
  }
  // 校验是否在mock接口中，拼接上mock标识便于在vue.config.js中进行代理
  if (mockArr.includes(urlTran)) {
    urlTran = MOCK_PREFIX + urlTran;
    return urlTran;
  }
  // 不在mock接口数据中返回原url
  return url;
}
