var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cascader_box"},[_c('el-cascader',{ref:"cascaderTree",staticClass:"cascader_stl",attrs:{"options":_vm.areaData,"show-all-levels":true,"collapse-tags":"","size":"medium","clearable":"","placeholder":"请选择省市区","props":{
      multiple: _vm.multiple,
      checkStrictly: false,
      value: 'region_code',
      label: 'region_name',
      expandTrigger: 'hover',
    },"filterable":_vm.isSupportSearch,"popper-class":_vm.selectAreaPopperClass},on:{"change":_vm.areaChange},model:{value:(_vm.selectedCodeList),callback:function ($$v) {_vm.selectedCodeList=$$v},expression:"selectedCodeList"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }