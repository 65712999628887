/**
 * 对接集团portal
 */
import { isSim, SIM_OPERA_UTL, EXIT_URL } from './env.js';

function iamInit() {
  window.__USERCENTER__.configUserCenter({
    clientId: process.env.VUE_APP_IMA_ID, // 账号中心cliendtId
    ssoUrl: process.env.VUE_APP_IMA, // 账号中心统一登录页地址
    tokenUrl: process.env.VUE_APP_IMA + '/api/admin/auth/getToken', // 集成了账号中心提供的后端服务的地址
    redirectUri: isSim
      ? `${SIM_OPERA_UTL}/mgr/#/operateManagePlat`
      : process.env.VUE_APP_OPERATE_URL + '/#/operateManagePlat', // 业务系统登录成功后重定向的地址（运营人员-中转页）
    exitUrl: EXIT_URL, // 业务系统登出成功后重定向的地址（3D首页）
    // project: '三翼鸟·筑巢设计', // 自定义登录项目的名称
    onTokenChange: (token) => {
      if (token) {
        console.log('token', token);
      }
    },
    onUserInfoChange: (userInfo) => {
      if (userInfo) {
        console.log('userInfo', userInfo);
      }
    },
  });
}
export default iamInit;
