<template>
  <!-- 验证码、密码登录 -->
  <div class="login_code_part">
    <el-form :model="ruleForm" size="medium" :rules="rules" ref="ruleForm" @validate="handleValidateEvent">
      <div v-if="tabsActive === 1">
        <el-form-item prop="mobile">
          <el-input
            type="text"
            v-model="ruleForm.mobile"
            @keyup.enter.native="submitForm('ruleForm')"
            :maxlength="11"
            autocomplete="off"
            placeholder="请输入手机号"
            clearable></el-input>
        </el-form-item>
        <el-form-item prop="mobileCode" class="mobile_code">
          <el-input
            type="text"
            v-model="ruleForm.mobileCode"
            @keyup.enter.native="submitForm('ruleForm')"
            autocomplete="off"
            placeholder="手机验证码"></el-input>
          <span class="ver_phone get_captcha_code" @click="getPhoneCode">{{ verPhone }}</span>
          <span class="ver_phone not_allowed">{{ countDownText }}</span>
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item prop="userName">
          <el-input
            type="text"
            v-model="ruleForm.userName"
            @keyup.enter.native="submitForm('ruleForm')"
            autocomplete="off"
            placeholder="请输入三翼鸟平台账号"
            clearable></el-input>
          <!--          <warmReminder />-->
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            :type="showPwd ? 'password' : 'text'"
            v-model="ruleForm.password"
            @keyup.enter.native="submitForm('ruleForm')"
            autocomplete="off"
            placeholder="请输入密码"
            class="pwd_input"
            clearable>
            <div slot="suffix">
              <div class="pwd_view show_pwd" @click="showPwd = false" v-show="showPwd"></div>
              <div class="pwd_view hide_pwd" @click="showPwd = true" v-show="!showPwd"></div>
            </div>
          </el-input>
        </el-form-item>
        <el-form-item prop="captcha_answer">
          <div class="flex" v-show="showCaptcha">
            <el-input
              class="wid175"
              type="text"
              v-model="ruleForm.captcha_answer"
              @keyup.enter.native="submitForm('ruleForm')"
              autocomplete="off"
              placeholder="请输入图片验证码"
              clearable></el-input>
            <div class="code_img_container" @click="getCaptchaCode">
              <el-image :src="imgSrc" class="code_img"></el-image>
            </div>
            <!-- <el-image
              @click="getCaptchaCode"
              :src="imgSrc"
              class="code_img"></el-image> -->
          </div>
        </el-form-item>
      </div>
      <slot name="operate"></slot>
      <div
        v-show="tabsActive === 2"
        class="forget_password"
        v-gio-track="{ id: 'CZHT30166' }"
        @click="handleForgetPassword">
        忘记密码
      </div>
      <el-form-item class="login_btn_container">
        <ZjsjHomeButton
          size="large"
          class="login_btn"
          :loading="btnLoading"
          :disabled="loginBtnDisabled"
          :customStyle="{ width: '100%' }"
          @click="submitForm('ruleForm')">
          登录
        </ZjsjHomeButton>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import { serverUrl } from '@/utils/env';
  import request from '@/utils/axios';
  // import warmReminder from '@v/register/component/warmReminder';
  import { isMobile, isMgr } from '@/utils/mUtils';
  import { getToken } from '@/utils/auth';
  import { manageIndex } from '@/utils/beforeSetUp';
  import { copyCaseAndJump, jumpKjl } from '@/api/zjsjHome/zjsjHome.api';
  import { getCaptchaUserCenter } from '@/api/commApi';
  import { DESIGNER_DELETE_STATE } from '@/utils/resources';
  import { goToApplyRights, unbindTips } from '@/utils/goToAfterLogin';
  import goToAuth from '@/utils/goToAuth';

  export default {
    props: {
      tabsActive: {
        type: Number,
        default: 1, // 1，验证码登录；2，密码登录
      },
    },
    components: {
      // warmReminder,
    },
    watch: {
      tabsActive: {
        handler() {
          this.getCaptchaCode();
          Object.keys(this.validateResult).forEach((i) => {
            this.validateResult[i] = false;
          });
          this.$refs['ruleForm'].resetFields();
        },
      },
    },
    data() {
      // 校验手机号码
      let checkMobile = (rule, value, callback) => {
        if (value) {
          if (isMobile(value)) {
            callback();
          } else {
            callback(new Error(rule.message));
          }
        }
      };

      return {
        loginKeepCurrentPage: false,
        queryAction: null,
        btnLoading: false,
        ruleForm: {
          mobile: '', // 手机号
          mobileCode: '', // 短信验证码

          userName: '',
          password: '',

          captcha_answer: '', // 图片验证码
          captcha_token: '', // 图片验证码token
        },
        validateResult: {
          mobile: false,
          mobileCode: false,
          userName: false,
          password: false,
        },
        rules: {
          mobile: [
            { required: true, message: '请输入手机号码', trigger: 'blur' },
            {
              validator: checkMobile,
              message: '请输入正确的手机号',
              trigger: 'change',
            },
          ],
          mobileCode: [{ required: true, message: '请输入短信验证码', trigger: 'change' }],

          userName: [
            {
              required: true,
              message: '请输入三翼鸟平台账号密码进行登录',
              trigger: 'change',
            },
          ],
          password: [{ required: true, message: '请输入密码', trigger: 'change' }],
          captcha_answer: [
            // { required: true, message: '请输入图片验证码', trigger: 'blur' },
          ],
        },
        // 手机号定时器
        timePromisePhone: null,
        captchaFlagPhone: true,
        countDownText: null,
        //验证码
        verPhone: '获取验证码',
        secondPhone: 60,
        loginAfterAction: {
          COPY_CASE: copyCaseAndJump,
          ADD_CASE: jumpKjl,
        },
        imgSrc: '',
        captcha_token: '',
        canClickCode: true,
        showPwd: true,
        showCaptcha: false,
      };
    },
    computed: {
      loginBtnDisabled() {
        // 验证码登录，手机号或验证码校验没通过禁用
        if (this.tabsActive === 1) {
          return !this.validateResult.mobile || !this.validateResult.mobileCode;
        } else {
          // 账号密码录，账号或密码校验没通过禁用
          return (
            !this.validateResult.userName ||
            !this.validateResult.password ||
            (this.showCaptcha && !this.ruleForm.captcha_answer)
          );
        }
      },
    },
    destroyed() {
      clearInterval(this.timePromisePhone);
      this.captchaFlagPhone = true;
      this.countDownText = '';
      this.secondPhone = 60;
    },
    mounted() {
      // this.getCaptchaCode();
      this.loginKeepCurrentPage = this.$store.state.LoginRegistreStore.currentComponentQuery?.keepCurrentPage;
    },
    created() {
      this.queryAction = this.$store.state.LoginRegistreStore.currentComponentQuery || {};
    },
    methods: {
      gioTrack(id, payload) {
        if (payload) {
          gio('track', id, payload); // eslint-disable-line
        } else {
          gio('track', id); // eslint-disable-line
        }
      },
      // 获取图片验证码
      getCaptchaCode() {
        if (!this.canClickCode) {
          return;
        }
        if (!this.showCaptcha) {
          return;
        }
        this.canClickCode = false;
        getCaptchaUserCenter().then((res) => {
          this.canClickCode = true;
          if (res.status === '0' && res.data.captcha_image) {
            this.imgSrc = res.data.captcha_image;
            this.captcha_token = res.data.captcha_token;
          } else {
            this.$message.error('获取图片验证码失败');
          }
        });
      },
      // 更新图片验证码
      updateImgCode() {
        this.$emit('updateImgCode');
      },
      // 获取手机号验证码
      async getPhoneCode() {
        if (!this.ruleForm.mobile) {
          return this.$message.error('请输入手机号');
        }
        if (!isMobile(this.ruleForm.mobile)) {
          return this.$message.error('请输入正确的手机号');
        }

        let params = {
          captcha_answer: '',
          captcha_token: '',
          phone_number: this.ruleForm.mobile,
          scenario: 'login',
        };
        request({
          url: serverUrl + '/sendVerification',
          method: 'post',
          data: params,
        }).then((res) => {
          if (res.status === '0' && JSON.parse(res.data).success) {
            this.$message.success('发送验证码成功');
            this.timePromisePhone = setInterval(() => {
              if (this.secondPhone <= 0) {
                clearInterval(this.timePromisePhone);
                this.timePromisePhone = undefined;
                this.secondPhone = 60;
                this.countDownText = '';
                this.captchaFlagPhone = true;
                this.verPhone = '获取验证码';
              } else {
                this.verPhone = '';
                this.countDownText = '剩余' + this.secondPhone + '秒';
                this.captchaFlagPhone = false;
                this.secondPhone--;
              }
            }, 1000);
          } else {
            this.getCaptchaCode();
          }
        });
      },
      needReload() {
        this.$router.replace({
          path: '/zjsj/zjsjHome',
          query: {
            needReload: Math.random().toString(),
          },
        });
      },
      // 验证店铺合伙人
      getShopPartnerApplyStatus() {
        return new Promise((resolve, reject) => {
          request({
            url: serverUrl + '/api/userInfo/getShopPartnerApplyStatus',
            method: 'post',
          })
            .then((res) => {
              if (res.status === '0') {
                // 是店铺合伙人且未绑定门店
                let query = this.queryAction;
                if (query && query.action) {
                  if (unbindTips()) {
                    this.needReload();
                    return;
                  }
                  // 未认证
                  if (Number(this.$store.state.user.authFlag) !== 2) {
                    goToAuth();
                    this.needReload();
                    return;
                  }
                  // 如果用户已认证但无权益
                  if (!this.$store.state.user.userCurrentAuth) {
                    goToApplyRights();
                    this.needReload();
                    return;
                  }
                  if (res.data.isShopPartner && res.data.bindStatus !== '1') {
                    this.needReload();
                    return;
                  }
                  let event = this.loginAfterAction[query.action];
                  if (event) {
                    try {
                      event(query.fromPage, query.payload).finally(() => {
                        this.queryAction = null;
                        if (res.data.isShopPartner && res.data.bindStatus && res.data.bindStatus !== '1') {
                          // 3d后台
                          this.$router.push({ path: manageIndex() });
                          this.$store.dispatch('initLeftMenu'); //设置左边菜单始终为展开状态
                        }
                      });
                    } catch (error) {
                      console.log(error);
                    }
                  }
                }

                resolve();
              } else {
                reject();
              }
            })
            .catch(() => {
              reject();
            });
        });
      },
      async submitForm(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.btnLoading = true;
            let captcha_answer = '';
            let captcha_token = '';
            // 账号密码登录且输入错误5次
            if (this.showCaptcha && this.tabsActive === 2) {
              captcha_answer = this.ruleForm.captcha_answer;
              captcha_token = this.captcha_token;
            }
            // 验证码、密码登录
            let params = {
              username: this.tabsActive === 1 ? this.ruleForm.mobile : this.ruleForm.userName,
              password: this.tabsActive === 1 ? this.ruleForm.mobileCode : this.ruleForm.password,
              captcha_answer,
              captcha_token,
              type: this.tabsActive === 1 ? 'sms' : 'basic_password',
              activityChannel: this.$route.query.channel,
            };

            const loading = this.$loading({
              lock: true,
              text: '登录中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
              customClass: 'syn-fullscreen-text-loading',

              target: document.querySelector('.div1'),
            });
            this.$store.dispatch('Login', params).then(async (res) => {
              this.btnLoading = false;
              loading.close();
              if (res.type === 'delete') {
                this.$store.dispatch('setCurrentComponent', {
                  name: 'AUTH_ERROR',
                  query: {
                    title: '账号已删除',
                    content:
                      '尊敬的设计师您好，由于您长时间未使用，账号已被系统删除，如需继续使用可联系三翼鸟总监进行账号恢复',
                    confirmButtonText: '关闭',
                    confirmButtonStyle: { width: '112px', height: '44px' },
                    onConfirm: () => {
                      this.$store.dispatch('setCurrentComponent', null);
                    },
                  },
                });
                this.getCaptchaCode();
                return;
              }
              // if (res === 'error' || res === 'registering') {
              if (res.type === 'error') {
                if (res.data.status === '1011') {
                  this.showCaptcha = true;
                }
                if (this.showCaptcha) {
                  this.rules.captcha_answer = [
                    {
                      required: true,
                      message: '请输入图片验证码',
                      trigger: 'blur',
                    },
                  ];
                }
                this.getCaptchaCode();
                this.gioTrack('CZHT30165', {
                  roughly_classification_name: '正常登录',
                });
                if (this.tabsActive === 1) {
                  this.gioTrack('CZHT30165', {
                    detailed_classification_name: '验证码登录',
                    third_classification_name: '设计师登录',
                    failure_reason: res.data.msg,
                  });
                } else {
                  this.gioTrack('CZHT30165', {
                    detailed_classification_name: '密码登录',
                    third_classification_name: '设计师登录',
                    failure_reason: res.data.msg,
                  });
                }
                return;
              }
              if (this.tabsActive === 1) {
                this.gioTrack('CZHT30164', {
                  roughly_classification_name: '正常登录',
                  detailed_classification_name: '验证码登录',
                  third_classification_name: '设计师登录',
                });
              } else {
                this.gioTrack('CZHT30164', {
                  roughly_classification_name: '正常登录',
                  detailed_classification_name: '密码登录',
                  third_classification_name: '设计师登录',
                });
              }
              await this.$store.dispatch('GenerateRoutes', getToken()); // 获取路由信息
              await this.$store.dispatch('accessRouters', getToken()); // 有权限路由

              setTimeout(() => {
                this.getCaptchaCode();
              }, 1000);
              this.getShopPartnerApplyStatus()
                .then(() => {
                  let authFlag = this.$store.state.user.authFlag;
                  // 并且不是已解除身份
                  if (
                    isMgr() &&
                    authFlag === '2' &&
                    res.data.accreditVo.info.userProfile.state !== DESIGNER_DELETE_STATE
                  ) {
                    // 3d后台
                    this.$router.push({ path: manageIndex() });
                    this.$store.dispatch('initLeftMenu'); //设置左边菜单始终为展开状态
                    this.$store.dispatch('setCurrentComponent', null);
                  } else {
                    // 3d前台 - 首页
                    if (res.type !== 'error ' && (authFlag === '2' || authFlag === '1')) {
                      this.$store.dispatch('setCurrentComponent', null);
                    }
                    if (this.loginKeepCurrentPage) {
                      this.$router.replace({
                        path: this.$route.path,
                        query: {
                          ...this.$route.query,
                          needReload: Math.random().toString(),
                        },
                      });
                      this.$store.dispatch('setCurrentComponent', null);
                    } else {
                      // this.$router.push({
                      //   path: '/zjsj/zjsjHome',
                      //   query: { needReload: Math.random().toString() },
                      // });
                      window.location.reload();
                      this.$store.dispatch('setCurrentComponent', null);
                    }
                  }
                  loading.close();
                })
                .catch(() => {
                  loading.close();
                  window.location.reload();
                });
            });
          }
        });
      },
      handleValidateEvent(key, res) {
        console.log(key, res);
        this.validateResult[key] = res;
      },
      /**
       * @description 忘记密码
       */
      handleForgetPassword() {
        this.$emit('forgetPassword');
      },
    },
  };
</script>

<style lang="less" scoped>
  .login_code_part {
    .el-form-item {
      width: 360px;
      margin-bottom: 20px;
    }
    .el-input {
      width: 360px;
      /deep/ .el-input__inner {
        &:focus {
          border-color: #be965a !important;
        }
      }
    }
    /deep/ .pwd_input {
      .el-icon-circle-close.el-input__clear {
        position: absolute;
        right: 24px;
      }
    }
    .pwd_view {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 50%;
      transform: translateY(calc(-50% - 1px));
      right: 4px;
      z-index: 10;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      cursor: pointer;
    }
    .show_pwd {
      background: url('~@/assets/img/login/show_pwd.png');
    }
    .hide_pwd {
      background: url('~@/assets/img/login/hide_pwd.png');
    }
    .flex {
      display: flex;
      .code_img_container {
        height: 48px;
        .el-image {
          height: 48px;
        }
      }
    }
    .wid175 {
      width: 175px;
    }
    .code_img {
      margin-left: 10px;
      width: 175px;
      // height: 48px;
      cursor: pointer;
      border-radius: 8px;
    }
    .mobile_code {
      position: relative;
    }
    .get_captcha_code {
      color: #bbb;
      &:hover {
        color: #be965a;
      }
    }
    .ver_phone {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      color: #bbb;
    }
    .not_allowed {
      cursor: not-allowed;
    }
    .login_btn_container {
      margin-bottom: 16px;
      margin-top: 52px;
      .login_btn {
        width: 360px !important;
        height: 48px !important;
      }
    }
    /deep/.el-input--medium .el-input__inner {
      height: 48px;
      line-height: 48px;
      border-radius: 8px;
    }
    .forget_password {
      position: absolute;
      right: 40px;
      transform: translateY(-4px);
      color: #333333;
      font-size: 14px;
      cursor: pointer;
    }
  }
</style>
