<template>
  <el-drawer
    custom-class="zjsj_download_center_drawer"
    title=""
    :visible.sync="drawerDownload"
    :direction="direction"
    :before-close="closeDrawer"
    size="60%">
    <template slot="title">
      <div class="download_title">
        <h3>下载中心</h3>
        <el-link type="danger" :underline="false">
          下载中心每{{ refreshTimeMin }}分钟自动刷新一次,您也可以手动点击刷新按钮触发刷新
        </el-link>
        <el-button
          icon="el-icon-refresh"
          circle
          class="refresh_btn"
          @click="getDownInfo"
          :loading="fetchLoading"></el-button>
      </div>
    </template>
    <div class="content-table">
      <el-table :data="tableData" row-key="id" v-loading="fetchLoading">
        <el-table-column property="name" label="事项名称" show-overflow-tooltip></el-table-column>
        <el-table-column property="status" label="状态">
          <template slot-scope="scope">
            <span :class="[statusObj[scope.row.status], 'status']">{{ statusNameObj[scope.row.status] }}</span>
          </template>
        </el-table-column>
        <el-table-column property="taskType" label="导出类型"></el-table-column>
        <el-table-column property="userName" label="导出人" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.userName }}（{{ scope.row.haierNo }}）</template>
        </el-table-column>
        <el-table-column property="createTime" label="创建日期" :formatter="formatDate" width="150"></el-table-column>
        <el-table-column
          property="expirationTime"
          label="失效日期"
          :formatter="formatDate"
          width="150"></el-table-column>
        <el-table-column property="address" label="操作" width="90">
          <template slot-scope="scope">
            <el-button type="text" class="operation del" @click="delCaseLink(scope.row)" v-if="scope.row.status !== 4">
              删除
            </el-button>
            <el-button type="text" class="operation" v-if="scope.row.status === 3" @click="downCaseLink(scope.row)">
              下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页器-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"></el-pagination>
      </div>
    </div>
  </el-drawer>
</template>
<script>
  import { mapGetters } from 'vuex';
  import pagQuery from '@/mixins/pagQuery';
  import { serverUrl } from '@/utils/env';
  import request from '@/utils/axios';
  import { DOWNLOAD_CENTER_TYPE } from '@/config/downloadCenterConf';
  let tag = null;
  export default {
    name: 'downloadCenter',
    computed: {
      ...mapGetters(['drawerDownload']),
    },
    mixins: [pagQuery],
    data() {
      return {
        fetchLoading: false,
        refreshTimeMin: 1,
        tableData: [],
        direction: 'rtl',
        statusNameObj: {
          1: '生成中',
          2: '生成失败',
          3: '可下载',
          4: '已删除',
        },
        statusObj: {
          1: 'primary',
          2: 'warning',
          3: 'success',
          4: 'info',
        },
      };
    },

    watch: {
      drawerDownload: {
        handler(val) {
          if (val) {
            this.getDownInfo();
            let one_min = this.refreshTimeMin * 60 * 1000;
            tag = setInterval(() => {
              console.log(111, new Date());
              this.getDownInfo();
            }, one_min);
          } else {
            this.clearInterval();
          }
        },
        immediate: true,
        deep: true,
      },
    },
    beforeDestroy() {
      this.clearInterval();
    },
    mounted() {},
    methods: {
      clearInterval() {
        if (tag) {
          clearInterval(tag);
        }
      },
      findDownloadType() {
        let item = DOWNLOAD_CENTER_TYPE.find((item) => item.path === this.$route.path);
        return item?.type;
      },
      /**
       * @description: 获取下载中心数据
       */
      getDownInfo() {
        this.fetchLoading = true;
        let params = {};
        console.log(this.$route.path);
        let type = this.findDownloadType();
        this.query(
          `${serverUrl}/server/batch/download/task/page`,
          { ...params, type },
          {
            type: 'get',
          }
        )
          .then((res) => {
            if (res && res.status === '0' && res.data) {
              this.tableData = res['data']['records'];
            }
          })
          .finally(() => {
            this.fetchLoading = false;
          });
      },
      /**
       * @description: 关闭抽屉
       */
      closeDrawer() {
        this.$store.dispatch('setDrawer', false);
      },
      /**
       * @description: 删除
       * @param { Object } row
       */
      delCaseLink(row) {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            request({
              url: `${serverUrl}/server/batch/download/task/delete/${row.id}`,
              method: 'post',
            }).then((res) => {
              if (res.code === '0') {
                this.$message.success('删除成功');
                this.getDownInfo();
              } else {
                this.$message.error('删除失败');
              }
            });
          })
          .catch(() => {});
      },
      /**
       * @description: 下载
       * @param { Object } row
       */
      downCaseLink(row) {
        window.location.href = row.fileUrl;
      },
      /**
       * @description: 格式化日期
       */
      formatDate(row, column, cellValue) {
        const s = new Date(cellValue).toLocaleString();
        return s;
      },
    },
  };
</script>
<style scoped lang="less">
  .content-table {
    padding: 0 20px;
    .refresh_btn {
      position: absolute;
      top: 20px;
    }
    .block {
      text-align: right;
      margin-top: 30px;
    }
  }

  .status {
    padding: 2px 10px;
    border: 1px solid rgba(23, 62, 103, 0.2);
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
  }
  .del {
    color: rgb(255, 85, 0) !important;
    &:hover {
      color: rgb(255, 85, 0);
    }
  }
  .primary {
    color: #409eff;
    background: #ecf5ff;
    border-color: #b3d8ff;
  }
  .warning {
    color: #e6a23c;
    background: #fdf6ec;
    border-color: #f5dab1;
  }
  .success {
    color: #52c41a;
    background: #f6ffed;
    border-color: #b7eb8f;
  }
  .info {
    color: #909399;
    background: #f4f4f5;
    border-color: #d3d4d6;
  }
</style>
<style lang="less">
  .zjsj_download_center_drawer {
    .el-drawer__header {
      margin-bottom: 12px !important;
      .download_title {
        .el-link--inner {
          text-indent: 3px;
          margin-right: 15px;
        }
      }
    }
  }
</style>
