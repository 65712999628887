import { serverUrl } from '@/utils/env';
import request from '@/utils/axios';

/**
 * @description 获取字典值的结果
 * @param {String} type 字典类型
 * @returns Promise
 */
export function getDictResult(type) {
  return new Promise((resolve, reject) => {
    getDataDict(type)
      .then((res) => {
        if (res.status === '0') {
          resolve(res.data);
        } else {
          reject(res.message || '获取字典值失败请稍后重试');
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * @description 获取字典接口
 * @param {String} type 字典类型
 * @returns Promise
 */
export function getDataDict(type) {
  return request.get(`${serverUrl}/api/data_dict/${type}`);
}
/**
 * @description 获取新字典值的结果
 * @param {String} type 字典类型
 * @returns Promise
 */
export function getReDictResult(type) {
  return new Promise((resolve, reject) => {
    getReDict(type)
      .then((res) => {
        if (res.code === '0') {
          resolve(res.data);
        } else {
          reject(res.message || '获取字典值失败请稍后重试');
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
/**
 * @description 获取字典接口
 * @param {String} type 字典类型
 * @returns Promise
 */
export function getReDict(type) {
  return request.get(`${serverUrl}/server/zjsj/dict/${type}`);
}
// 埋点
export function buryingPoint(data) {
  return request.post(`${serverUrl}/server/base/buriedApi/report`, data);
}

export function getUserInfo() {
  return request.get(`${serverUrl}/api/user/profile`);
}

export function getNoDesensitionUserInfo() {
  return request.get(`${serverUrl}/api/user/profile/noDesensitization`);
}

export function getRoleList() {
  return request.get(`${serverUrl}/server/base/role/list?needNum=1&platform=1`);
}

/**
 * @description 获取验证码 筑巢设计自有
 * @returns Promise
 */
export function getCaptcha() {
  return request.post(`${serverUrl}/server/base/captcha/generateCaptcha`);
}

/**
 * @description 获取用户中心图形验证码
 * @returns Promise
 */
export function getCaptchaUserCenter() {
  return request.post(`${serverUrl}/captcha`);
}

/**
 * @description 校验验证码
 * @param {Object} params 验证码信息
 * @returns Promise
 */
export function checkCaptcha(params) {
  return request.post(`${serverUrl}/server/base/captcha/checkCaptcha`, params);
}

/**
 * @description 获取学习地图地址
 * @returns Promise
 */
export function getLearningMapUrl() {
  return request.get(`${serverUrl}/learningMapApi/generateRedirectUrl`);
}

/**
 * @description  跳转学习地图
 * @returns Promise
 */
export function jumpLearningMap() {
  return new Promise((resolve, reject) => {
    try {
      getLearningMapUrl()
        .then((res) => {
          if (res.status == 0) {
            resolve();
            window.open(res.data);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}
/*
 * @description 获取未读消息数
 */
export function getMessageNum() {
  return request.get(`${serverUrl}/api/user/getUnReadNotifications?userId=${window.localStorage.getItem('userId')}`);
}

/**
 * @description 获取酷家乐openid
 * @returns Promise
 */
export function fetchKjlOpenId() {
  let userId = window.localStorage.getItem('userId');
  return request.get(`${serverUrl}/server/zjsj/user/getKjlOpenId?userId=${userId}`);
}

/**
 * @description 敏感词校验接口
 * @param {String} words 待校验的文字
 * @returns Promise
 */
export function checkSensitiveWords(words) {
  return request({
    url: serverUrl + '/api/green_scan/textScan',
    method: 'post',
    data: {
      text: words,
    },
  });
}

/**
 * @description 获取用户信息
 * @returns Promise
 */
export function queryUserInfo() {
  return request({
    url: `${serverUrl}/register/queryUserInfo`,
    method: 'get',
  });
}

/**
 * @description 根据设计师用户id获取绑定的门店和店铺
 * @param userId
 * @returns Promise
 */
export function queryStoreInfo(userId) {
  // todo sfj mock数据，后续删除
  // return request.get(
  //   `https://stp.haier.net/mock/350/zjsj/user/getStoreInfo/${userId}`
  // );
  return request.get(`${serverUrl}/server/zjsj/user/getStoreInfo/${userId}`);
}

/**
 * @description 获取省市区全量 三级数据
 * @returns Promise
 */
export function allAreaData() {
  return request({
    url: `${serverUrl}/area/full`,
    method: 'get',
  });
}
