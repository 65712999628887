import { isIframeInNebula } from '@/utils/beforeSetUp';
import { getToken } from '@/utils/auth';

// http - header 拼装
export function getHeaders() {
  // 3d
  const ThreeDHeader = {
    Authorization: getToken() ? getToken() : null, //携带权限参数
    access_token: window.localStorage.getItem('access_token'),
    accessToken: window.localStorage.getItem('access_token'),
    platform: window.localStorage.getItem('platform'), // design:设计师平台, operation:运营平台
  };
  // 星云
  const nebulaHeader = {
    Authorization: null, //携带权限参数
    'X-Access-Token': window.localStorage.getItem('X-Access-Token'),
    'menu-id': window.localStorage.getItem('xy-menu-id'),
  };

  // 是否星云
  const header = isIframeInNebula() ? nebulaHeader : ThreeDHeader;
  return header;
}
