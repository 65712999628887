import LayoutMgr from '../layout/home'; // 页面整体布局 - 带header、leftMenu
import LayoutMgrBlank from '../Layouts/Blank/BlankLayout'; // 页面整体布局 - 不带header、leftMenu
import { isIframe } from '@/utils/mUtils';
import LayoutOperate from '../layout/homeOperate'; // 运营人员后台 - 页面整体布局 - 带header、leftMenu

// platform - design:设计师平台, operation:运营平台
let platFormLayout =
  window.localStorage.getItem('platform') === 'design'
    ? LayoutMgr
    : LayoutOperate;

// 区分是否是星云
export const Layout = isIframe() ? LayoutMgrBlank : platFormLayout;
