// 是否sim
const SIM_ORIGIN = [
  'zjsj-sim.qd-aliyun-dmz-ack-internal.haier.net',
  'sim.qd-aliyun-internal2.haier.net',
  'zcyy-sim.qd-aliyun-dmz-ack-internal.haier.net',
];
const SIM_URL = 'http://zjsj-sim.qd-aliyun-dmz-ack-internal.haier.net';
const SIM_OPERA_UTL = 'http://zcyy-sim.qd-aliyun-dmz-ack-internal.haier.net';

const isSim = SIM_ORIGIN.includes(window.location.host);

const serverUrl = isSim ? '' : process.env.VUE_APP_URL; // development和production环境是不同的
const zjhxUrl = process.env.VUE_APP_ZJHX_URL;
const zjsjUrl = isSim ? '' : process.env.VUE_APP_URL;
const nodeEnv = process.env.NODE_ENV;
const nesting = process.env.VUE_APP_NESTING_URL;
const EXIT_URL = isSim ? `${SIM_URL}/mgr` : process.env.VUE_APP_EXIT_URL;
export {
  serverUrl,
  zjhxUrl,
  nodeEnv,
  zjsjUrl,
  nesting,
  SIM_ORIGIN,
  isSim,
  SIM_URL,
  SIM_OPERA_UTL,
  EXIT_URL,
};
