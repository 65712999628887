import store from '@/store';
// authFlag - 设计师认证标识：0：未认证 1：审核中 2：认证成功 3：认证失败
function goToAuth() {
  let authFlag = Number(store.state.user.authFlag);
  let query = {
    content: '',
    confirmButtonStyle: { width: '112px', height: '44px' },
    confirmButtonText: '去认证',
  };
  if (authFlag === 3) {
    gio('track', 'CZHT30179'); // eslint-disable-line
    query = {
      ...query,
      content: '抱歉，您的设计师认证资料审核失败，请重新认证。',
      isReject: true,
      rejectRemark: '驳回原因：' + window.localStorage.getItem('rejectRemark'),
      onConfirm: () => {
        // eslint-disable-line
        gio('track', 'CZHT30180'); // eslint-disable-line
        openAuthDialog();
      },
    };
  } else if (authFlag === 0) {
    gio('track', 'CZHT30179'); // eslint-disable-line
    query = {
      ...query,
      content: '您未完成设计师认证，完成后可使用此功能',
      onConfirm: () => {
        gio('track', 'CZHT30180'); // eslint-disable-line
        openAuthDialog();
      },
    };
  } else if (authFlag === 1) {
    query = {
      ...query,
      content: '您的认证信息还在审核中，请耐心等待，认证通过后内容将全面开放。',
      confirmButtonText: '确定',
      onConfirm: () => {
        store.dispatch('setCurrentComponent', null);
      },
    };
  }
  store.dispatch('setCurrentComponent', {
    name: 'AUTH_ERROR',
    query,
  });
}

function openAuthDialog() {
  let retailStoreList = JSON.parse(window.localStorage.getItem('retailStoreList'));
  // 1 - 店铺/店铺负责人; 2 - 店铺合伙人；
  let partnerType = window.localStorage.getItem('partnerType');
  if ((partnerType === '1' || partnerType === '2') && !retailStoreList?.length) {
    store.dispatch('setCurrentComponent', null);
    setTimeout(() => {
      store.dispatch('setCurrentComponent', {
        name: 'AUTH_ERROR',
        query: {
          confirmButtonText: '确定',
          confirmButtonStyle: { width: '112px', height: '44px' },
          content: '您绑定的客户无三翼鸟门店，请到移动工作台注销身份后再认证设计师',
          onConfirm: () => {
            store.dispatch('setCurrentComponent', null);
          },
        },
      });
    }, 0);
    return;
  }
  if (Number(store.state.user.infoFlag) === 0) {
    store.dispatch('setCurrentComponent', {
      name: 'SELECT_DESIGNER_TYPE',
    });
  } else if (Number(store.state.user.infoFlag) === 1) {
    store.dispatch('setCurrentComponent', {
      name: 'COMPLETE_DESIGNER_INFO',
    });
  }
}
export default goToAuth;
