import store from '@/store/';
import { isIframe, GetQueryString } from '@/utils/mUtils';
import { Message } from 'element-ui';
import { removeToken } from '@/utils/auth';

// 是否在星云平台内
export function isIframeInNebula() {
  if (isIframe()) {
    // iframe且星云平台内
    if (
      GetQueryString('token') &&
      GetQueryString('menuId') &&
      GetQueryString('userName') &&
      GetQueryString('resource') === 'nebula'
    ) {
      return true;
    }
  }
  return false;
}

// 后台首页
export function manageIndex() {
  let mlist = store.getters.routers;
  if (mlist && mlist.length > 0) {
    if (mlist[0].children && mlist[0].children.length > 0) {
      return mlist[0].children[0].path;
    } else {
      return mlist[0].path;
    }
  }

  const isOperate = window.location.href.includes('/operateManagePlat');
  if (!isOperate) {
    Message({
      message:
        '您的账号暂未分配相应菜单权限，请联系管理员分配菜单后，再登录使用',
      type: 'error',
      duration: 3 * 1000,
    });
  }
  removeToken();
  return '/login';
}
