<template>
  <el-dialog
    :title="dialogTitle"
    :visible="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :custom-class="hasStore ? 'haistore' : 'haier_home_no_store_dialog'"
    :width="hasStore ? '30%' : '20%'"
    :before-close="handleClose">
    <div class="store_select_wrapper" v-if="hasStore">
      <span class="store_title">选择门店：</span>
      <el-select v-model="mdCode" placeholder="请选择" size="small" style="width: 100%">
        <el-option v-for="item in mdOptions" :key="item.code" :label="item.value" :value="item.code">
          <template v-if="item.value && item.value.length > 34">
            <div class="haier_home_store_select_ellipes">
              <el-tooltip class="item" effect="dark" :content="item.value" placement="top-start">
                <span>{{ item.value }}</span>
              </el-tooltip>
            </div>
          </template>
          <template v-else>
            <span>{{ item.value }}</span>
          </template>
        </el-option>
      </el-select>
    </div>
    <template v-else>
      <div class="store_warning">
        <div class="title"></div>
        <div class="content">请联系家居王旭升18561557298，开通家居采购权限。</div>
      </div>
    </template>
    <span slot="footer" class="dialog-footer">
      <template v-if="hasStore">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleConfirm" :disabled="!mdCode" :loading="confirmLoading">确 定</el-button>
      </template>
      <template v-else>
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </template>
    </span>
  </el-dialog>
</template>

<script>
  import request from '@/utils/axios';
  import { serverUrl } from '@/utils/env';
  import { getUserStoreList } from '@/api/haierHomeApi';
  export default {
    name: 'routerToHomeDialog',
    components: {},
    props: {},
    computed: {
      dialogVisible() {
        return this.$store.state.menu.routerToHomeDialog;
      },
    },
    data() {
      return {
        hasStore: false,
        dialogTitle: '选择门店', // 选择门店 提示信息
        mdCode: '',
        mdOptions: [],
        haierHomeUserId: null,
        storeInfo: {
          storeCode: '',
          storeName: '',
        },
      };
    },
    watch: {
      dialogVisible: {
        immediate: true,
        handler: function (val) {
          if (val) {
            this.getRetailStore();
          }
        },
      },
      mdCode: {
        immediate: true,
        handler: function (val) {
          if (val) {
            let item = this.mdOptions.find((item) => item.code === val);
            this.haierHomeUserId = item?.haierHomeUserId || '';
            this.storeInfo = {
              storeCode: item?.storeCode || '',
              storeName: item?.storeName || '',
            };
          }
        },
      },
    },
    methods: {
      close() {
        let menuList = window.localStorage.getItem('routers');
        let menuArr = JSON.parse(menuList || '[]');
        if (menuArr[0]) {
          if (menuArr[0].children) {
            this.$router.push({
              path: menuArr[0].children[0].path,
            });
          } else {
            this.$router.push({
              path: menuArr[0].path,
            });
          }
        } else {
          this.$router.push({
            path: '/zjsj/zjsjHome',
          });
        }
        // this.$emit('update:dialogVisible', false);
        this.$store.commit('setRouterToHomeDialog', false);
        this.mdCode = '';
        this.mdOptions = [];
        this.hasStore = true;
        this.dialogTitle = '选择门店';
      },
      handleConfirm() {
        if (!this.mdCode) {
          this.$message.error('请选择门店');
          return;
        }
        request({
          url: `${serverUrl}/server/zjsj/haierhome/sso/url?storeCode=${this.storeInfo.storeCode}&mdCode=${this.mdCode}&haierHomeUserId=${this.haierHomeUserId}`,
          method: 'get',
        })
          .then((res) => {
            if (res && res.code === '0') {
              console.log('res', res);
              window.open(res.data, 'toHome');
              this.close();
            }
          })
          .finally(() => {});
      },
      handleClose() {
        this.close();
      },
      getRetailStore() {
        let userId = window.localStorage.getItem('userId');
        getUserStoreList(userId)
          .then((res) => {
            this.mdOptions = res || [];
            if (this.mdOptions.length > 0) {
              this.hasStore = true;
              this.mdCode = this.mdOptions[0]?.code;
              this.haierHomeUserId = this.mdOptions[0]?.haierHomeUserId;
            } else {
              this.hasStore = false;
              this.dialogTitle = '提示信息：无家居采购权限';
            }
            if (this.mdOptions.length === 1) {
              this.storeInfo = {
                storeCode: this.mdOptions[0]?.storeCode || '',
                storeName: this.mdOptions[0]?.storeName || '',
              };
              this.handleConfirm();
            }
          })
          .catch((err) => {
            this.hasStore = false;
            this.dialogTitle = '提示信息：无家居采购权限';
            console.log('err', err);
          });
      },
    },
    beforeDestroy() {
      this.$store.commit('setRouterToHomeDialog', false);
    },
  };
</script>

<style scoped lang="less">
  .store_select_wrapper {
    display: flex;
    .store_mark {
      color: red;
      line-height: 32px;
    }
    .store_title {
      width: 90px;
      line-height: 32px;
    }
  }
  .haier_home_store_select_ellipes {
    width: 100%;
    max-width: 440px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .el-dialog__wrapper {
    /deep/.haier_home_no_store_dialog {
      min-width: 400px;
    }
  }
</style>
