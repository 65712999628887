const LoginRegistreStore = {
  state: {
    currentComponent: '',
    currentComponentQuery: null,
    selectDesignerType: null,
    authInfo: {
      identityType: 1,
    },
  },
  mutations: {
    SET_DESIGNER_TYPE(state, type) {
      state.selectDesignerType = type;
    },
    SetCurrentComponent(state, payload) {
      if (payload === null) {
        state.currentComponent = null;
      }
      state.currentComponent = payload?.name || null;
      state.currentComponentQuery = payload?.query || null;
    },
    SET_DESIGNER_AUTH_INFO(state, payload) {
      state.authInfo = payload;
    },
  },
  actions: {
    setDesignerType: ({ commit }, type) => {
      commit('SET_DESIGNER_TYPE', type);
    },
    setCurrentComponent: ({ commit }, payload) => {
      commit('SetCurrentComponent', payload);
    },
    setDesignerAuthInfo: ({ commit }, payload) => {
      commit('SET_DESIGNER_AUTH_INFO', payload);
    },
  },
};

export default LoginRegistreStore;
