<template>
  <div id="app">
    <router-view></router-view>
<!--    <el-button @click="sentryReplay"></el-button>-->
  </div>
</template>

<script>
  import { removeToken } from '@/utils/auth';
  import { EventBus } from '@/utils/event-bus.js';
  import { WINDOW_RESIZE } from '@/utils/const.js';
  import { isChrome } from './utils/tools';
  export default {
    mounted() {
      if (!isChrome()) {
        this.useChromeTips();
      }
      if (window.localStorage.getItem('userId')) {
        //
      } else if (window.location.href.indexOf('?') > -1 && window.location.href.indexOf('=') > -1) {
        //
      } else {
        removeToken();
      }
      window.addEventListener('resize', this.triggerResizeEvent, false);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.triggerResizeEvent);
      this.$EventBus.$off(WINDOW_RESIZE);
    },
    methods: {
      sentryReplay() {
        // eslint-disable-next-line no-undef
        console.log('process.env.NODE_ENV', process.env.NODE_ENV, a);
      },
      triggerResizeEvent() {
        EventBus.$emit(WINDOW_RESIZE);
      },
      useChromeTips() {
        const chromeImg = require('@/assets/img/chrome.png');
        this.$message({
          message:
            "<span class='divActionImg' style='font-size:16px;color:#4b4b4b;display: flex;flex-direction: row;align-items: center;justify-content: flex-start; padding:0 40px 0 10px;'><p style='margin-right:40px;display: flex;flex-direction: row;align-items: center;justify-content: flex-start;'><i class='icon icon-information1' style='font-size:20px;color: #d7466e; margin-right:20px;'></i>提醒：推荐使用谷歌浏览器操作</p><span style='display: flex;flex-direction: row;align-items: center;justify-content: flex-start;'><a href='https://google.cn/intl/zh-CN/chrome/' target='_blank'><img src=" +
            chromeImg +
            " style='width:40px;height:40px;padding-left:40px;border-left:1px solid #dcdcdc;margin-right:25px;' ></a><span>Chrome <p style='font-size:13px;'>谷歌浏览器</p></span></span></span>",
          duration: 5000,
          showClose: true,
          dangerouslyUseHTMLString: true,
          iconClass: 'iconAciton',
          offset: 74,
        });
      },
    },
  };
</script>

<style lang="less">
  @import './style/element-ui';
  @import './style/font-awesome.min.css';
  @import './style/common';
  @import './style/scrollBar';
  @import '@/assets/style/less/baseContentPage.less';
  #app,
  body {
    .el-loading-mask.syn-zjsj-front-loading {
      .el-loading-spinner {
        position: relative;
        .circular,
        .el-icon-loading {
          width: 0px !important;
          height: 0px !important;
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: calc(50% - 10px);
          left: calc(50% - 10px);
          width: 20px;
          height: 20px;
          background: url('~@/assets/img/zjsj/loading.png') 50% 50% no-repeat;
          animation: loading-rotate 2s linear infinite;
          @keyframes loading-rotate {
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
    .syn-fullscreen-text-loading.el-loading-mask {
      .el-loading-spinner {
        position: relative;
        .circular,
        .el-icon-loading {
          display: none;
          width: 0px !important;
          height: 0px !important;
        }
        .el-loading-text {
          font-size: 14px !important;
          position: absolute;
          left: 50%;
          top: 10px;
          transform: translateX(-50%);
          color: #be965a;
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: calc(50% - 10px);
          left: calc(50% - 10px);
          width: 20px;
          height: 20px;
          background: url('~@/assets/img/zjsj/loading.png') 50% 50% no-repeat;
          animation: loading-rotate 2s linear infinite;
          @keyframes loading-rotate {
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
</style>
