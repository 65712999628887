import Vue from 'vue';
import ZjsjHomeButton from '@/components/ui/homeButton/homeButton.vue';
import ZjsjImage from '@/components/ui/zjsjImage/zjsjImage.vue';
import ZjsjIcon from '@/components/ui/zjsjIcon/icon.vue';
import ZjsjVideo from '@/components/ui/videoCustomize/videoCustomize.vue';
import ZjsjLazyCascader from '@/components/ui/zjsjLazyCascader/zjsjLazyCascader.vue';
const components = [ZjsjHomeButton, ZjsjImage, ZjsjIcon, ZjsjVideo, ZjsjLazyCascader];

components.forEach((component) => {
  Vue.component(component.name, component);
});
