import { isObject } from '@/utils/tools';

/**
 * @description 把职能标签映射为接口所需数据
 * @param {Array} fun 职能标签选中项code
 * @param {Array} functionList 全量智能标签
 */
export function submitFunLabel(fun, functionList) {
  return fun.map((funCode) => {
    let fundItem = functionList.find((item) => item.code === funCode);
    return {
      code: fundItem.code,
      value: fundItem.name,
    };
  });
}

/**
 * @description 提交参数组合
 * @param {Object} params 提交参数
 * @param {Array} functionList 职能标签全量数据
 */
export function generatorParams(params, functionList, instance) {
  let submitParams = { ...params };
  let designScheme = submitParams.designScheme;
  let shopName = '';
  let shopCode = '';
  let retailStore = [];
  if (instance.retailStoreList && instance.retailStoreList.length > 0) {
    shopName = instance.retailStoreList[0].storeName;
    shopCode = instance.retailStoreList[0].storeCode;
    retailStore = instance.retailStoreList.map((e) => {
      return { code: e.mdCode, value: e.mdName };
    });
  }
  submitParams.userServiceArea = {
    provinceCode: instance.provinceCode,
    provinceName: instance.provinceName,
    city: instance.city,
    serviceArea: instance.serviceArea,
    province: instance.province || [],
  };

  submitParams.shopName = instance.isShop ? shopName : '';
  submitParams.shopCode = instance.isShop ? shopCode : '';
  submitParams.retailStore = instance.isShop ? retailStore : [];
  // 这里处理手动上传和回显的数据  手动上传为object 回显为String
  submitParams.designScheme =
    designScheme && designScheme.length
      ? designScheme
          .map((item) => {
            let itemType = isObject(item);
            return itemType ? item.url : item;
          })
          .join(',')
      : '';
  submitParams.fun = submitParams.designerType === '3' ? [] : submitFunLabel(params.fun, functionList);
  if (!submitParams.designFeeStandardMin) {
    submitParams.designFeeStandardMin = 80;
  }
  if (!submitParams.designFeeStandardMax) {
    submitParams.designFeeStandardMax = 200;
  }
  submitParams.type = submitParams.designerType;
  submitParams.haierNo = instance.isHaier ? submitParams.haierNo : '';
  submitParams.idCard = instance.isNeedId === 'Y' ? submitParams.idCard : ''; //	身份证，若needId='Y'，则此项必填
  submitParams.identityType = instance.isPartner ? instance.partnerType : '3'; // 设计师身份类型：1店铺负责人2店铺合伙人(有门店)，3平台合伙人, 类型为1和2必填
  // submitParams.industryType =
  //   submitParams.designerType === '3' ? '' : submitParams.industryType; // 共享设计师也可以选择产业 必填
  return { ...submitParams };
}
