/**
 * @description 主页相关接口
 */
import request from '@/utils/axios';
import router from '@/router';
import { buryingPointConfirm } from '@/utils/mUtils';
/**
 * @description 复制方案 免费使用
 * @param { String } kjlCaseId 酷家乐方案id
 */
export function designCopy(kjlCaseId) {
  return request.post(`/api/haier_kjl_case/designCopy/v2/${kjlCaseId}`);
}

export function jumpKjl(fromPage) {
  if (fromPage === 'BannerBTN') {
    buryingPointConfirm('INDEX', 'BannerBTN');
  } else if (fromPage === 'ZhuchaoBTN') {
    buryingPointConfirm('INDEX', 'ZhuchaoBTN');
  }
  let newpage = router.resolve({
    path: '/designIframe',
  });
  window.open(newpage.href, '_blank');
}
/**
 * @description 复制方案后进行跳转
 */
export function jumpKjlEdit(fromPage, id) {
  if (fromPage === 'HouseRoaming') {
    buryingPointConfirm('INDEX', 'INDEX-FuzhiBTN');
  } else if (fromPage === 'DesignCard') {
    buryingPointConfirm('SJLG', 'SJLG-FuzhiBTN');
  }
  let newpage = router.resolve({
    path: '/designIframeEdit',
    query: {
      id: id,
    },
  });
  window.open(newpage.href, '_blank');
}

/**
 * @description 复制方案并跳转
 * @param {String} kjlCaseId 酷家乐id
 * @param {String} fromPage  HouseRoaming-首页 DesignCard-设计灵感
 * @returns Promise
 */
export function copyCaseAndJump(fromPage, kjlCaseId) {
  return new Promise((resolve, reject) => {
    designCopy(kjlCaseId)
      .then((res) => {
        if (res.status === '0') {
          try {
            jumpKjlEdit(fromPage, res.data);
            resolve();
          } catch (error) {
            reject(error);
          }
        } else {
          reject();
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
