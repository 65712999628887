<template>
  <div class="blank_layout">
    <div class="left-fixed-right-auto">
      <div class="content_page" ref="content_page">
        <div class="content">
          <keep-alive :include="componentName">
            <router-view></router-view>
            <!--页面渲染入口-->
          </keep-alive>
        </div>
      </div>
    </div>
    <download-center></download-center>
    <routerToHomeDialog></routerToHomeDialog>
  </div>
</template>
<script>
  import { EventBus, setFixedHeight } from '@/utils/event-bus';
  import { WINDOW_RESIZE } from '@/utils/const';
  import DownloadCenter from '@/layout/downloadCenter.vue';
  import routerToHomeDialog from '@/layout/routerToHomeDialog';
  export default {
    name: 'BlankLayout',
    data() {
      return {
        setHeight: () => null,
        componentName: [
          'submissionReviewList',
          'quickDesign',
          'onlineDesignManage',
          'workOrderInfo',
          'designerAudit',
          'caselist',
        ],
      };
    },
    computed: {},
    components: { DownloadCenter, routerToHomeDialog },
    created() {},
    mounted() {
      this.setHeight = setFixedHeight(this, this.$refs.content_page, 0);
      this.setHeight();
      EventBus.$on(WINDOW_RESIZE, this.setHeight);
    },
    beforeDestroy() {
      EventBus.$off(WINDOW_RESIZE, this.setHeight);
    },
    methods: {},
  };
</script>
<style scoped lang="less">
  .blank_layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .left-fixed-right-auto {
    width: 100%;
    display: flex;
    flex: auto;
  }

  .content_page {
    width: calc(100% - 220px);
    flex: auto;
    background: #fff;
    display: flex;
    overflow-y: auto;
  }

  .content {
    width: 100%;
    flex: auto;
    background: #f6f7fc;
  }
</style>
