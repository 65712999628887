<template>
  <div class="home_footer">
    <div class="footer_left footer_item">
      <div :class="['text_item', item.class]" v-for="item in textLinkList" :key="item.name">
        <div :class="['parent', item.class]">{{ item.name }}</div>
        <div
          v-for="child in item.children"
          :key="child.name"
          :class="['text_link', child.class]"
          @click="linkClick(child)">
          {{ child.name }}
        </div>
      </div>
    </div>
    <div class="footer_middle footer_item">
      鲁ICP备09096283号-1 | © 2000-2022 Haier.com. All rights reserved 版权所有
    </div>
    <div class="footer_right footer_item">
      <!-- <div class="contanier_im" v-for="item in imgList" :key="item.src">
        <img :src="item.src" />
        <div v-if="item.content" class="text_content">{{ item.content }}</div>
      </div> -->
    </div>
  </div>
</template>

<script>
  let mini_programer = require('@/assets/img/zc_design_minip.png');
  export default {
    data() {
      return {
        textLinkList: [
          {
            name: '产品服务',
            link: null,
            linkType: '',
            class: 'text_white',
            show: () => true,
            children: [
              {
                name: '素材库',
                link: '/zjsj/modeldownload',
                linkType: 'inner',
                class: 'pointer',
                show: () => true,
              },
              {
                name: '方案库',
                link: '/zjsj/allcaseTxt',
                linkType: 'inner',
                class: 'pointer',
                show: () => true,
              },
              // {
              //   name: '翼起学习',
              //   link: null,
              //   linkType: 'outer',
              //   show: () => {
              //     return (
              //       getToken() &&
              //       window.localStorage.getItem('platform') === 'design' &&
              //       this.$store.state.user.userProfileState !== '9' &&
              //       this.$store.state.user.authFlag === '2'
              //     );
              //   },
              //   class: 'pointer',
              //   clickEvent: jumpLearningMap,
              // },
            ],
          },
          // 本期不上2020-06-14 for wangfpp  蔡美丽
          // {
          //   name: '帮助支持',
          //   link: null,
          //   linkType: '',
          //   class: 'text_white',
          //   show: () => true,
          //   children: [
          //     {
          //       name: '用户协议',
          //       link: null,
          //       linkType: '',
          //       class: 'pointer',
          //       show: () => true,
          //     },
          //     {
          //       name: '隐私条款',
          //       link: null,
          //       linkType: '',
          //       class: 'pointer',
          //       show: () => true,
          //     },
          //   ],
          // },
        ],
        imgList: [
          {
            src: mini_programer,
            content: '三翼鸟筑巢设计小程序',
          },
        ],
      };
    },
    mounted() {
      this.textLinkList = this.textLinkList.filter((item) => {
        item.children = item.children.filter((c) => c.show());
        return item.show();
      });
    },
    methods: {
      /**
       * @description 需要跳转的事件
       * @param {Object} item
       */
      linkClick(item) {
        let { linkType, link, clickEvent } = item;
        if (clickEvent) {
          clickEvent();
          return;
        }
        if (link) {
          if (linkType === 'inner') {
            this.$router.push(link);
          } else {
            window.open(link);
          }
        }
      },
    },
  };
</script>
<style lang="less" scoped>
  .home_footer {
    width: 100%;
    height: 202px;
    background: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;
    padding: 30px 0;
    box-sizing: border-box;
    .footer_item {
      flex: 1;
    }
    .footer_left {
      color: #fff;
      font-size: 14px;
      width: 385px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .text_item {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        color: rgba(255, 255, 255, 0.8);
        font-weight: 400;
        margin: 9px 20px;
      }
      .text_link,
      .parent {
        color: rgba(255, 255, 255, 0.8);
        font-weight: 400;
        margin: 9px 20px;
      }
      .text_white {
        color: #ffffff;
        font-weight: 500;
      }
      .pointer {
        cursor: pointer;
      }
    }
    .footer_middle {
      font-size: 14px;
      color: #ffffff;
      min-width: 507px;
      font-weight: 400;
      display: flex;
      align-items: center;
      height: 100%;
      opacity: 0.8;
      justify-content: center;
      text-align: center;
    }
    .footer_right {
      display: flex;
      justify-content: center;
      .contanier_im {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        width: 127px;
        height: 142px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        padding: 10px 0;
        border-radius: 8px;
        img {
          width: 100px;
          height: 100px;
        }
        .text_content {
          margin-top: 5px;
          color: #fff;
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
</style>
