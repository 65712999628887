<!-- 设计师审核信息弹窗 -->
<template>
  <div class="designer_review_auth_dialog">
    <div class="close_header">
      <Icon icon="close" size="24px" @iconClick="handleClose"></Icon>
    </div>
    <div class="tips_header padding-l-r" :style="headerStyle">{{ title }}</div>
    <div class="tips_content padding-l-r" :style="contentStyle">
      <div>
        {{ content }}
      </div>
      <div v-if="isReject" class="reject_mark">
        {{ rejectRemark }}
      </div>
    </div>
    <div class="bottom_confirm padding-l-r">
      <ZjsjHomeButton
        v-if="showCancelButton"
        @click="handleCancel"
        :loading="btnLoading"
        :customStyle="{ ...cancelButtonStyle }">
        {{ cancelButtonText }}
      </ZjsjHomeButton>
      <ZjsjHomeButton
        v-if="confirmButtonText"
        @click="handleConfirm"
        :loading="btnLoading"
        :customStyle="{ ...customeStyle, ...confirmButtonStyle }">
        {{ confirmButtonText }}
      </ZjsjHomeButton>
    </div>
  </div>
</template>
<script>
  import Icon from '@/components/ui/zjsjIcon/icon.vue';
  import { isFunction } from '@/utils/tools';
  export default {
    name: 'designerReviewDialog',
    props: {
      title: {
        type: String,
        default: '温馨提示',
      },
      content: {
        type: String,
        default: '',
      },
      confirmButtonText: {
        type: String,
        default: '确认',
      },
      confirmButtonStyle: {
        type: Object,
        default: () => {},
      },
      showCancelButton: {
        type: Boolean,
        default: false,
      },
      cancelButtonText: {
        type: String,
        default: '取消',
      },
      cancelButtonStyle: {
        type: Object,
        default: () => {
          return {
            backgroundColor: '#fff',
            color: '#666',
            borderColor: '#d1d1d1',
          };
        },
      },
      btnLoading: {
        type: Boolean,
        default: false,
      },
      headerStyle: {
        type: Object,
        default: () => {},
      },
      contentStyle: {
        type: Object,
        default: () => {},
      },
      onConfirm: {
        type: Function,
        default: () => {
          return;
        },
      },
      onClose: {
        type: Function,
        default: () => {
          return;
        },
      },
      isReject: {
        type: Boolean,
        default: false,
      },
      rejectRemark: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        customeStyle: {
          'font-size': '16px',
          color: '#FFFFFF',
          'text-align': 'center',
          'font-weight': 400,
        },
      };
    },
    components: {
      Icon,
    },
    methods: {
      handleClose() {
        this.$emit('handleClose');
        this.onClose();
        this.$store.dispatch('setCurrentComponent', null);
        console.log('close');
      },
      handleConfirm() {
        if (this.onConfirm && isFunction(this.onConfirm)) {
          this.onConfirm();
        }
        this.$emit('handleConfirm');
      },
      handleCancel() {
        this.$emit('handleCancel');
        this.$store.dispatch('setCurrentComponent', null);
      },
    },
  };
</script>
<style lang="less" scoped>
  .designer_review_auth_dialog {
    width: 424px;
    border-radius: 8px;
    height: auto;
    min-height: 190px;
    padding: 16px 0 32px 0;
    box-sizing: border-box;
    .close_header {
      text-align: end;
      padding-right: 16px;

      .zjsj-icon {
        cursor: pointer;
      }
    }
    .padding-l-r {
      padding: 0 32px 0 32px;
    }
    .tips_header {
      font-size: 16px;
      color: #333333;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .tips_content {
      font-size: 14px;
      color: #666666;
      line-height: 22px;
      font-weight: 400;
      margin-bottom: 32px;
      .reject_mark {
        margin-top: 8px;
      }
    }
    .bottom_confirm {
      text-align: end;
    }
  }
</style>
