const footerStore = {
  state: {
    show: false,
  },
  mutations: {
    setShowState: (state, show) => {
      state.show = show;
    },
  },
  actions: {
    setShowState: ({ commit }, show) => {
      console.log(show);
      commit('setShowState', show);
    },
  },
};
export default footerStore;
