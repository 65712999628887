//手动跳转的页面白名单  不重定向白名单 - 登录、注册、3d前台页面
export const whiteList = [
  // '/login',
  // '/register',
  // '/forgetpwd',
  '/operateManagePlat',
  '/zjsj/zjsjHome',
  '/zjsj/allcaseTxt',
  '/zjsj/modeldownload',
  '/zjsj/modelDetail',
  '/zjsj/cadDetail',
  '/zjsj/rendering',
  '/zjsj/list',
  '/zjsj/zjsjRealCase',
  '/zjsj/zjsjRealCaseDetails',
  '/zjsj/designerStar',
  '/zjsj/designerStarDetails',
  '/HouseTourIframe',
  '/zjsj/designerCompetition',
  '/zjsj/designerCompetitionDetail',
  '/zjsj/zjsjDesignerAwardDetail',
];
