import { Layout } from './layout';

export const CrmRouter = {
  path: '/crm',
  component: Layout,
  meta: {
    title: '到店系统',
    icon: 'el-icon-notebook-2',
  },
  redirect: '/crm/todolist',
  children: [
    {
      path: 'todolist',
      meta: {
        title: '待办',
        routerType: 'leftmenu',
      },
      component: () => import('@/layout/crmMenuLayout.vue'),
    },
    {
      path: 'crmclue',
      meta: {
        title: '线索',
        routerType: 'leftmenu',
      },
      component: () => import('@/layout/crmMenuLayout.vue'),
    },
    {
      path: 'crmcustomer',
      meta: {
        title: '客户',
        routerType: 'leftmenu',
      },
      component: () => import('@/layout/crmMenuLayout.vue'),
    },
    {
      path: 'crmchance',
      meta: {
        title: '商机',
        routerType: 'leftmenu',
      },
      component: () => import('@/layout/crmMenuLayout.vue'),
    },
  ],
};

export const CRM_ANALYSE_ROUTE = {
  path: '/crmanalyse',
  component: Layout,
  meta: {
    title: '分析',
  },
  redirect: '/crmanalyse/crmboardreport',
  children: [
    {
      path: 'crmboardreport',
      meta: {
        title: '数据驾驶舱',
        routerType: 'leftmenu',
      },
      component: () => import('@/layout/crmMenuLayout.vue'),
    },
    {
      path: 'salesreport',
      meta: {
        title: '销售分析',
        routerType: 'leftmenu',
      },
      component: () => import('@/layout/crmMenuLayout.vue'),
    },
    {
      path: 'drawreport',
      meta: {
        title: '客户画像分析',
        routerType: 'leftmenu',
      },
      component: () => import('@/layout/crmMenuLayout.vue'),
    },
  ],
};

export const CRM_SETTING = {
  path: '/crmsetting',
  component: Layout,
  meta: {
    title: '系统设置',
  },
  redirect: '/crmsetting/crmtag',
  children: [
    {
      path: 'crmtag',
      meta: {
        title: '标签管理',
        routerType: 'leftmenu',
      },
      component: () => import('@/layout/crmMenuLayout.vue'),
    },
    {
      path: 'crmprocess',
      meta: {
        title: '销售流程设置',
        routerType: 'leftmenu',
      },
      component: () => import('@/layout/crmMenuLayout.vue'),
    },
  ],
};
