import request from '@/utils/axios';
// import { appUrl } from '@/utils/env';
import { serverUrl } from '@/utils/env';
import axios from 'axios';

export function login(userInfo) {
  return axios.post(serverUrl + '/accredit', userInfo);
}
export function tokenlog() {
  return request({
    url: serverUrl + '/api/user/profile',
    method: 'get',
  });
}
export function tokenlogout() {
  return request({
    url: serverUrl + '/logout',
    method: 'get',
  });
}

export function getBtnList() {
  return axios.get(serverUrl + '/server/base/user/permission/button');
}
