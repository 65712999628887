import store from '@/store';
import { getToken } from '@/utils/auth';
const userType = [
  { type: '0', name: '官方设计师' },
  { type: '1', name: '店铺设计师' },
  { type: '3', name: '共享设计师' },
];
/**
 * @description 埋点
 * @param { String } id 埋点id
 * @param { Object } param 埋点传参
 **/
export function gioTrack(id, param, entrance) {
  if (localStorage.getItem('platform') === 'design') {
    let role_type = localStorage.getItem('userType');
    let role_name = '';
    let findRoleType = userType.find((u) => {
      return u.type === role_type;
    });
    if (findRoleType) {
      role_name = findRoleType?.name;
    }
    let authFlag = Number(store.state.user.authFlag) !== 2;
    if (authFlag) {
      role_name = '用户';
    }
    if (entrance === 'Home') {
      param.user_type = role_name;
    } else {
      param.role_type = role_name;
    }
    console.log('parameter', id, param);
    // eslint-disable-next-line no-undef
    gio('track', id, param);
  }
}

/**
 * @description 首页埋点上报
 * @params { String } id 事件id
 * @return
 */
export function gioTrackHome(id) {
  if (!getToken()) {
    const gioParam = {
      user_type: '未登录',
      user_label: '',
    };
    console.log('gioTrackHome', id, gioParam);
    // eslint-disable-next-line no-undef
    gio('track', id, gioParam);
  } else {
    const param = {
      user_label: window.localStorage.getItem('userLabel') || '',
    };
    gioTrack(id, param, 'Home');
  }
}
