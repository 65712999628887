<template>
  <div class="zjsj_header" :style="headerStyle">
    <div class="header_placeholder"></div>
    <div class="header_all" :class="headerClass">
      <div class="content_width header_center">
        <img :src="logoSrc" alt="设计师门户网站" class="logo" @click="toHome()" />
        <div class="nav-list">
          <div
            v-for="i in navList"
            :class="{
              'nav-item': true,
              header_nav: true,
              active: chooseId === i.id,
            }"
            v-show="i.isShow"
            :key="i.id">
            <span @click="goRouter(i)">
              {{ i.name }}
              <div class="new_flag" v-if="i.id === 9 && designCircleNewVisible">
                <img src="../assets/img/new_flag.png" alt="" />
              </div>
            </span>
            <div class="more_search" v-if="i.isShow && i.path === 'moreOperations'">
              <div class="more_list" v-for="e in moreList" :key="e.id" @click="moreRouter(e)">
                {{ e.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="user-info" v-if="userInfo.user">
          <div class="search_box">
            <div :class="['search_wid', isSearch ? 'search_show' : 'search_hide']">
              <div class="header_search" ref="searchBox">
                <el-select
                  popper-class="zjsj_header_select"
                  class="header_select"
                  v-model="select"
                  placeholder="请选择">
                  <el-option
                    v-for="item in selectOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
                <div class="shu"></div>
                <input
                  placeholder="请输入"
                  id="search_input"
                  v-model="input"
                  type="text"
                  autocomplete="off"
                  @keydown.enter="searchHandle()"
                  class="forn_control" />
                <div class="bgcIcon search_icon" @click="searchHandle()"></div>
                <div class="bgcIcon delete_icon" @click="changeHandle('isSearch', false)"></div>
              </div>
            </div>
          </div>
          <!-- <div
            class="bgcIcon search_is"
            ref="searchBtn"
            @click="changeHandle('isSearch', true)"></div> -->
          <div v-if="btnStart" class="flexs">
            <el-popover
              width="424px"
              trigger="manual"
              placement="bottom"
              popper-class="login_after_auth_popover"
              v-model="showAuthTips">
              <div class="login_after_auth_tips_content">
                <i class="el-icon-close" @click="handleCloseTips"></i>
                <!-- <div class="close_btn">
                  <img src="@/assets/img/zjsj/close2x.png" class="close_icon" />
                </div> -->
                <div class="title">权益申请提示</div>
                <div class="tips" v-html="authTips"></div>
                <div class="btn_wrapper">
                  <div class="go-sq" @click="handleToApply">去申请</div>
                </div>
              </div>
              <div slot="reference" class="flexs">
                <div class="go-ht login_head_item" @click="goHt('/manage3d', '1')">我的工作台</div>
                <div v-if="isDesigner" class="split_line_login">
                  <div></div>
                </div>
              </div>
            </el-popover>
            <div v-if="isDesigner" class="go-sj login_head_item" @click="goDesign()">开始设计</div>
          </div>
          <userInfoMenuVue></userInfoMenuVue>
        </div>
        <div class="login-btn" v-else>
          <div class="to_login login_btn_item" @click="goHt('/login', '0', 'LOGIN')">登录</div>
          <div class="to_register login_btn_item" @click="goHt('/register', '0', 'REGISTER')">注册</div>
        </div>
        <div class="right_logo">
          <img src="../assets/img/right_logo.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getToken, removeToken } from '@/utils/auth'; // 验权
  import { zjsjUrl } from '@/utils/env';
  import request from '@/utils/axios';
  import { serverUrl } from '@/utils/env';
  import logoFull from '@/assets/img/zjdz/logo-full.png';
  import whiteLogo from '@/assets/img/zjsj/logo.png';
  import headImg from '@/assets/img/zjsj/head.png';
  import iamInit from '@/utils/iam';
  import { buryingPointConfirm } from '@/utils/mUtils';
  import { throttle } from '@/utils/tools';
  import { jumpLearningMap } from '@/api/commApi';
  import userInfoMenuVue from '@/components/ui/userInfoMenu/userInfoMenu.vue';
  import goToAuth from '@/utils/goToAuth';
  import { goToApplyRights, unbindTips } from '@/utils/goToAfterLogin';
  import { identityTypeList, DESIGNER_DELETE_STATE } from '@/utils/resources';
  import { gioTrackHome } from '@/utils/gioTrack';
  let clearIframeTag = null;
  const platform3D = window.localStorage.getItem('platform'); // design:设计师平台, operation:运营平台
  if (platform3D === 'operation') {
    iamInit();
  }
  let throughTopStylePath = [
    '/zjsj/designerStar',
    '/zjsj/zjsjHome',
    '/zjsj/zjsjRealCase',
    '/zjsj/allcaseTxt',
    '/zjsj/modeldownload',
  ];
  export default {
    name: 'HeaderCom',
    components: {
      userInfoMenuVue,
    },
    data() {
      return {
        authTips:
          '您的账号目前为青铜VIP，49种专享设计特权等你来拿~\n' +
          '                  <br />\n' +
          '                  点击免费申请会员',
        headerStyle: {},
        isScrollTop: 0,
        logoFull,
        headImg,
        navList: [
          {
            name: '首页',
            path: '/zjsj/zjsjHome',
            id: 1,
            local: 1,
            isShow: true,
          },
          {
            name: '真实案例',
            path: '/zjsj/zjsjRealCase',
            id: 2,
            local: 1,
            isShow: true,
            child: [
              {
                path: '/zjsj/zjsjRealCaseDetails',
              },
            ],
          },
          {
            name: '设计灵感',
            path: '/zjsj/allcaseTxt',
            id: 3,
            local: 1,
            isShow: true,
            child: [
              {
                path: '/zjsj/rendering',
              },
            ],
          },
          {
            name: '素材下载',
            path: '/zjsj/modeldownload',
            id: 4,
            local: 1,
            isShow: true,
            child: [
              {
                path: '/zjsj/modelDetail',
              },
            ],
          },
          {
            name: '明星设计师',
            path: '/zjsj/designerStar',
            id: 5,
            local: 1,
            isShow: true,
            child: [
              {
                path: '/zjsj/designerStarDetails',
              },
            ],
          },
          // {
          //   name: '翼起学习',
          //   path: 'learningMap',
          //   id: 6,
          //   local: -1,
          //   isShow: false,
          // },
          {
            name: '赛事中心',
            path: '/zjsj/designerCompetition',
            id: 8,
            local: 1,
            isShow: true,
            child: [
              {
                path: '/zjsj/designerCompetitionDetail',
              },
              {
                path: '/zjsj/zjsjDesignerAwardDetail',
              },
            ],
          },
          {
            name: '设计圈',
            path: 'designCircle',
            id: 9,
            local: 9,
            isShow: true,
          },
          // {
          //   name: '更多应用',
          //   path: 'moreOperations',
          //   id: 7,
          //   local: 2,
          //   isShow: false,
          // },
          // {
          //   name: '帮助',
          //   path: 'https://www.kujiale.com/college?kpm=9V8.6c261dc1484f94b4.0e24a57.1573806166924',
          //   id: 7,
          //   local: 0,
          //   isShow: true,
          // },
        ],
        moreList: [
          {
            name: '全屋智能设计',
            path: 'https://master.haieruhome.com/',
            id: 1,
          },
        ],
        chooseId: 1,
        select: 'keywords',
        isSearch: false,
        menuShow: false,
        userInfo: {},
        userProfile: {},
        user: {},
        bimShow: false,
        vipShow: false,
        settingShow: true,
        btnStart: true,
        input: '',
        loginAfterAuthTips: false,
        privilegeExpiredFlag: false,
        selectOptions: [
          { label: '案例', value: 'keywords' },
          { label: '模型', value: 'name' },
        ],
        isDesigner: window.localStorage.getItem('platform') === 'design', // design:设计师平台, operation:运营平台
        designCircleUrl: '',
        // { userType: '0', name: '官方设计师' },
        // { userType: '1', name: '店铺设计师' },
        // { userType: '3', name: '共享设计师' },
        userTypeMap: {
          0: '官方设计师',
          1: '店铺设计师',
          3: '共享设计师',
        },
        designCircleNewVisible: false,
      };
    },
    watch: {
      '$route.path': function (val) {
        if (this.$route.path === throughTopStylePath[0]) {
          this.headerStyle = {
            position: 'absolute',
          };
        } else {
          this.headerStyle = {};
        }
        if (throughTopStylePath.includes(this.$route.path)) {
          this.isScrollTop = 0;
          window.addEventListener('scroll', (e) => {
            this.handleHeaderStyle(e);
          });
        } else {
          this.isScrollTop = -1;
          window.removeEventListener('scroll', this.handleHeaderStyle);
        }
        this.gochooseId(val);
      },
      '$store.state.menu.search': function (val) {
        if (this.select === 'keywords') {
          this.input = val;
        }
      },
      '$store.state.menu.modelName': function (val) {
        if (this.select === 'name') {
          this.input = val;
        }
      },
      '$route.query': function (query, old) {
        if (query.needReload !== old.needReload) {
          this.instanceInit();
        }
      },
      loginAfterAuthTips(val) {
        if (!val) {
          window.localStorage.setItem('loginAfterAuth', '1');
        }
      },
    },
    computed: {
      /**
       * 显示授权提示
       * @returns {boolean}
       */
      showAuthTips: {
        get() {
          return Boolean(this.privilegeExpiredFlag || this.loginAfterAuthTips);
        },
        set(val) {
          // pass
          console.log(val);
        },
      },
      logoSrc() {
        return whiteLogo;
      },
      headerClass() {
        /*if (this.isScrollTop === 0) {
          return 'scrollTop';
        } else if (this.isScrollTop > 0) {
          return 'scrolling';
        } else if (this.isScrollTop < 0) {
          return 'otherpage';
        }*/
        return 'scrollTop';
      },
    },
    created() {
      this.instanceInit();
      this.createIframe();
    },
    mounted() {
      console.log('mounted', 111);
      if (this.$route.path === throughTopStylePath[0]) {
        this.headerStyle = {
          position: 'absolute',
        };
      } else {
        this.headerStyle = {};
      }
      document.addEventListener('click', this.autoHideSearchBox);
      if (throughTopStylePath.includes(this.$route.path)) {
        window.addEventListener('scroll', this.handleHeaderStyle);
      } else {
        this.isScrollTop = -1;
      }
    },
    destroyed() {
      document.removeEventListener('click', this.autoHideSearchBox);
      document.removeEventListener('scroll', this.handleHeaderStyle);
      this.destoryIframe();
    },
    methods: {
      /**
       * 销毁iframe
       */
      destoryIframe() {
        if (clearIframeTag) {
          clearTimeout(clearIframeTag);
        }
        let iframes = document.querySelectorAll('#kujiale-cache-iframe');
        if (iframes) {
          [...iframes].forEach((iframe) => {
            console.log('header销毁删除Iframe', iframe);
            document.body.removeChild(iframe);
          });
        }
      },
      createIframe() {
        if (getToken() && this.isDesigner) {
          request({
            url: serverUrl + '/kjllogin',
            ignoreError: true,
            method: 'get',
          }).then((res) => {
            let hasSameIframe = document.querySelectorAll('#kujiale-cache-iframe');
            if (res && Number(res.status) === 0 && hasSameIframe.length <= 0) {
              let iframe = document.createElement('iframe');
              iframe.src = res.data;
              iframe.id = 'kujiale-cache-iframe';
              iframe.frameborder = '0';
              iframe.style.width = '100%';
              iframe.style.height = '100%';
              iframe.style.border = '0';
              iframe.setAttribute('style', 'user-select:none;width:0;height:0;border:0;');
              iframe.addEventListener(
                'load',
                () => {
                  clearIframeTag = setTimeout(() => {
                    if (document.body.contains(iframe)) {
                      document.body.removeChild(iframe);
                    }
                  }, 10000);
                },
                false
              );
              document.body.appendChild(iframe);
            }
          });
        }
      },
      instanceInit() {
        this.isDesigner = window.localStorage.getItem('platform') === 'design';
        this.init();
        this.gochooseId(this.$route.path);
        this.designCirCleNewVisible();
      },
      handleHeaderStyle(e) {
        throttle(this.headerStyleEvent, 200)(e);
      },
      headerStyleEvent(e) {
        if (throughTopStylePath.includes(this.$route.path)) {
          let scrollTop = e.target.scrollingElement.scrollTop;
          this.isScrollTop = scrollTop;
        }
      },
      /**
       * @description 点击界面其他区域自动隐藏搜索框
       * @param { Event } e 点击的DOM事件
       */
      autoHideSearchBox(e) {
        let clickTarget = e.target;
        let searchBtn = this.$refs.searchBtn;
        let searchBox = this.$refs.searchBox;
        if (this.userInfo && this.userInfo.user) {
          if (searchBtn != clickTarget && !searchBox.contains(clickTarget) && this.isSearch) {
            this.isSearch = false;
          }
          if (searchBtn === clickTarget) {
            this.isSearch = true;
          }
        }
      },

      /**
       * @description 搜索
       * @params
       * @return
       */
      searchHandle() {
        if (this.input === '') {
          // this.$message.error('请输入搜索内容');
          return;
        }
        if (this.select === 'keywords') {
          this.$router.push({
            path: '/zjsj/allcaseTxt',
          });
          this.$store.commit('setSearch', this.input);
        } else {
          this.$router.push({
            path: '/zjsj/modeldownload',
          });
          this.$store.commit('setName', this.input);
        }
      },
      init() {
        let userType = window.localStorage.getItem('userType');
        if (getToken()) {
          this.btnStart = true;
          if (this.isDesigner) {
            // 设计师
            this.getUserInfo();

            if (userType == '5' || userType == '50') {
              this.settingShow = false;
            }
          } else {
            // 运营人员
            this.btnStart = true;
            this.userInfo.user = '运营人员';
            this.userProfile = {
              avatarFpath: window.localStorage.getItem('avatar') || this.headImg,
              mobile: window.localStorage.getItem('name') || '',
            };
          }
        } else {
          this.btnStart = false;
          this.userInfo = {};
          removeToken();
        }
      },
      /**
       * @description 退出登录
       * @params
       * @return
       */
      logout() {
        const platform3D = window.localStorage.getItem('platform'); // design:设计师平台, operation:运营平台
        if (platform3D === 'operation') {
          window.__USERCENTER__.logout().then(() => {
            removeToken();
          });
        } else {
          this.$store.dispatch('LogOut').then(() => {
            let url = window.location.origin;
            window.location.replace(url);
            location.reload();
          });
        }
      },
      gochooseId(val) {
        for (let i = 0; i < this.navList.length; i++) {
          if (this.navList[i].path === val) {
            this.chooseId = this.navList[i].id;
            return;
          } else if (this.navList[i].child && this.navList[i].child.length) {
            if (this.getChild(this.navList[i].child, val)) {
              this.chooseId = this.navList[i].id;
              return;
            }
          }
        }
        this.chooseId = 0;
      },
      getChild(list, val) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].path === val) {
            return true;
          }
        }
        return false;
      },
      /**
       * @description 设置缓存
       * @params res
       */
      setCache(res) {
        this.userInfo = res.data;
        this.userProfile = res.data.userProfile;
        this.user = res.data.user;
        this.$store.dispatch('setAuthFlag', res.data.userProfile.authFlag);
        this.$store.dispatch('setInfoFlag', res.data.userProfile.infoFlag);
        this.$store.commit('setUserProfileState', res.data.userProfile.state);
        this.$store.commit('setUserCurrentAuth', res.data?.userCurrentAuthVo?.auth);
        window.localStorage.setItem('rejectRemark', res.data.userProfile.rejectRemark || '');
        window.localStorage.setItem('operationUserName', this.userInfo?.operationUser?.name || '');
        window.localStorage.setItem('operationUserMobile', this.userInfo?.operationUser?.mobile || '');
        window.localStorage.setItem('registerUserType', this.user?.registerUserType || '');
      },
      /**
       * @description 设置导航菜单
       * @param res
       */
      handleNavMenu(res) {
        for (let i = 0; i < this.navList.length; i++) {
          // 学习地图 || 设计师大赛
          if (this.navList[i].path === 'learningMap' || this.navList[i].path === '/zjsj/designercompetition') {
            // 已认证并且没有被解除身份的用户才显示
            this.navList[i].isShow =
              res.data.userProfile.authFlag === '2' && res.data.userProfile.state !== DESIGNER_DELETE_STATE;
          }
        }
      },
      /**
       * @description 权益到期逻辑
       * @param res
       */
      privilegeExpried(res) {
        if (res.data?.userProfile?.authFlag === '2') {
          this.$nextTick(() => {
            let loginAfterAuth = window.localStorage.getItem('loginAfterAuth');
            this.loginAfterAuthTips = loginAfterAuth === '0';
            if (this.loginAfterAuthTips) {
              console.log('CZHT30177');
              gio('track', 'CZHT30177', {roughly_classification_name: identityTypeList.find(item => item.value == this.userInfo.user.type).label}); // eslint-disable-line
              window.localStorage.setItem('loginAfterAuth', '1');
              this.hidePrivilegeModal(1);
            }
          });
        }
      },
      /**
       * @description 隐藏权益弹窗
       * @param type
       */
      hidePrivilegeModal(type) {
        request({
          url: `${serverUrl}/api/user/changeSign?type=${type}`,
          method: 'post',
        }).catch((err) => {
          console.log(err);
        });
      },
      /**
       * @description 权益即将到期
       * @param res
       */
      privilegeWillExprie(res) {
        if (Number(res?.data?.privilegeExpiredDay) > 0) {
          this.privilegeExpiredFlag = true;
          this.authTips = `您的钻石VIP权益${res.data.privilegeExpiredDay}天后到期，<br/>请尽快续费，以免影响您的尊贵体验！`;
          this.hidePrivilegeModal(2);
        } else {
          this.privilegeExpiredFlag = false;
        }
      },
      /**
       * @description 获取用户信息
       * @params
       * @return
       */
      getUserInfo() {
        request({
          url: `${zjsjUrl}/api/user/profile`,
          method: 'GET',
        }).then((res) => {
          if (res.status === '0') {
            // if (res.data.userProfile.authFlag !== '2') {
            //   // 设计师认证标识：0：未认证  1：审核中  2：认证成功  3：认证失败
            //   return;
            // }
            this.setCache(res); // 设置localStorage
            this.handleNavMenu(res); // 设置顶部菜单
            this.privilegeWillExprie(res); // 权益即将到期
            this.privilegeExpried(res); // 权益到期逻辑
          }
        });
      },
      goHt(path, needToken, storeType) {
        if (!getToken() && needToken === '1') {
          this.$store.dispatch('setCurrentComponent', { name: 'LOGIN' });
          return;
        }
        if (needToken === '0') {
          if (storeType === 'REGISTER') {
            gio('track', 'CZHT30167'); // eslint-disable-line
          }
          if (storeType === 'LOGIN') {
            gio('track', 'CZHT30163'); // eslint-disable-line
          }
          this.$store.dispatch('setCurrentComponent', { name: storeType });
        } else {
          // 解绑提示
          if (unbindTips()) {
            return;
          }
          if (
            path === '/manage3d' &&
            Number(this.$store.state.user.authFlag) !== 2 &&
            window.localStorage.getItem('platform') === 'design'
          ) {
            // gio('track', 'CZHT30179'); // eslint-disable-line
            goToAuth();
            return false;
          }
          this.$router.push({
            path: path,
          });
          if (path === '/manage3d' && needToken === '1') {
            gioTrackHome('CZHT30198');
          }
        }
      },
      goDesign() {
        this.destoryIframe();
        if (!getToken()) {
          this.$store.dispatch('setCurrentComponent', { name: 'LOGIN' });
          return;
        }
        // 已解绑状态
        if (unbindTips()) {
          return;
        }
        // 未认证
        if (Number(this.$store.state.user.authFlag) !== 2) {
          goToAuth();
          return;
        }
        // 如果用户已认证但无权益
        if (!this.$store.state.user.userCurrentAuth) {
          goToApplyRights();
          return;
        }
        buryingPointConfirm('INDEX', 'KSSJ');
        gioTrackHome('CZHT30205');
        gioTrackHome('CZHT30199');
        let newpage = this.$router.resolve({
          path: '/designIframe',
        });
        window.open(newpage.href, '_blank');
      },
      toHome() {
        this.$router.push({ path: '/' });
      },
      gioTrack(id) {
        if (id === 1) {
          gioTrackHome('CZHT30191');
        } else if (id === 2) {
          gioTrackHome('CZHT30192');
        } else if (id === 3) {
          gioTrackHome('CZHT30193');
        } else if (id === 4) {
          gioTrackHome('CZHT30194');
        } else if (id === 5) {
          gioTrackHome('CZHT30195');
        } else if (id === 6) {
          gioTrackHome('CZHT30197'); // 翼起学习
        } else if (id === 8) {
          gioTrackHome('CZHT30310'); // 设计师大赛
        }
      },
      /**
       * @description 头部路由跳转
       * @params i { Object } 点击的数据
       * @return
       */
      goRouter(i) {
        if (i.local === 1) {
          if (i.path === this.$route.query.path) return;
          this.$router.push({
            path: i.path,
          });
          this.chooseId = i.id;
          this.gioTrack(i.id);
        } else if (i.local === 0) {
          // 帮助
          open('https://www.kujiale.com/college?kpm=9V8.6c261dc1484f94b4.0e24a57.1573806166924');
        } else if (i.local === -1) {
          // 学习地图/accredit
          this.goStudyCenter();
          this.gioTrack(i.id);
        } else if (i.local === 2) {
          // 更多应用
        } else if (i.local === 9) {
          let authFlag = this.authValidate();
          if (!authFlag) return;
          // 设计圈
          let newTab = this.$router.resolve({
            path: '/designCircleIframe',
          });
          window.open(newTab.href, '_blank');
        }
      },
      /**
       * 更多应用
       */
      moreRouter(val) {
        window.open(val.path);
      },
      /**
       * @description 跳转学习地图
       * @params
       * @return
       */
      goStudyCenter() {
        if (!getToken()) {
          this.$store.dispatch('setCurrentComponent', { name: 'LOGIN' });
          return;
        }
        jumpLearningMap().catch(() => {
          this.$message.error('跳转学习地图失败');
        });
      },
      handleCloseTips() {
        this.loginAfterAuthTips = false;
        this.privilegeExpiredFlag = false;
      },
      handleToApply() {
        gio('track', 'CZHT30178', {roughly_classification_name: identityTypeList.find(item => item.value == this.userInfo.user.type).label}); // eslint-disable-line
        this.$router.push({ path: '/person/authApply' });
      },
      // 点击设计圈检查当前登录人 是否登录——>是否解绑设计师——>是否认证——>是否有权益
      authValidate() {
        // 是否登录
        if (!getToken()) {
          this.$store.dispatch('setCurrentComponent', { name: 'LOGIN' });
          this.designCirCleGio('未登录');
          return false;
        }
        let platform = window.localStorage.getItem('platform');
        if (platform === 'operation') {
          let query = {
            confirmButtonStyle: { width: '112px', height: '44px' },
            confirmButtonText: '确定',
            content: '运营人员无设计工具权益，无法进入设计圈。',
            onConfirm: () => {
              this.$store.dispatch('setCurrentComponent', null);
            },
          };
          this.$store.dispatch('setCurrentComponent', {
            name: 'AUTH_ERROR',
            query,
          });
          return false;
        }
        // 已解绑状态
        if (unbindTips()) {
          return false;
        }
        let userType = window.localStorage.getItem('userType');
        // 未认证
        if (Number(this.$store.state.user.authFlag) !== 2) {
          let registerUserType = window.localStorage.getItem('registerUserType');
          this.designCirCleGio(this.userTypeMap[registerUserType]);
          if (!registerUserType) {
            this.designCirCleGio('暂无角色');
          }
          goToAuth();
          return false;
        }
        this.designCirCleGio(this.userTypeMap[userType]);
        // 如果用户已认证但无权益
        if (!this.$store.state.user.userCurrentAuth) {
          goToApplyRights();
          return false;
        }
        return true;
      },
      designCirCleGio(userType) {
        // eslint-disable-next-line no-undef
        gio('track', 'CZHT30369', {
          user_type: userType,
        });
      },
      designCirCleNewVisible() {
        request({
          url: serverUrl + '/openApi/queryDictionary?type=design_circle_new',
          method: 'get',
        }).then((res) => {
          if (res.status === '0') {
            if (res.data && res.data.length) {
              this.designCircleNewVisible = res.data[0]?.name === 'show';
            }
          }
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  @scrollTopColor: #fff;
  @scrollTopActiveColor: #efc991;
  @scrollingColor: #fff;
  @scrollingActiveColor: #efc991;
  @headHeight: 72px;
  .zjsj_header {
    .header_placeholder {
      height: @headHeight;
    }
    .content_width {
      margin: 0 auto;
      // width: 100%;
      padding-left: 41px;
      padding-right: 26px;
    }
    .header_all {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 20;
      width: 100%;
      .header_center {
        height: @headHeight;
        font-family: 'pingFangSC-Medium';
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
          // width: 173px;
          height: 38px;
          cursor: pointer;
          transform: translateY(-3px);
        }

        .nav-list {
          flex: 1;
          display: flex;
          align-items: center;

          .nav-item {
            cursor: pointer;
            margin-left: 2vw;
            height: @headHeight;
            line-height: @headHeight;
            font-size: 16px;
            color: @scrollTopColor;
            font-weight: 400;
            position: relative;
            &:hover {
              .more_search {
                display: block;
              }
            }
            .more_search {
              position: absolute;
              bottom: -25px;
              left: 0;
              display: none;
              padding: 4px 0;
              width: 108px;
              text-align: center;
              background: #fff;
              box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
              border-radius: 8px;
              font-size: 14px;
              color: #333;
              font-weight: 400;
              overflow: hidden;
              .more_list {
                height: 32px;
                line-height: 32px;
                cursor: pointer;
                &:hover {
                  color: #efc991;
                  background: rgba(190, 150, 90, 0.08);
                }
              }
            }
            .new_flag {
              position: absolute;
              top: -8px;
              right: -48px;
              animation: new_flag_scale 1.4s infinite;
              animation-timing-function: ease-in-out;
            }
            @keyframes new_flag_scale {
              0% {
                transform: scale(0.7, 0.7);
              }
              50% {
                transform: scale(0.9, 0.9);
              }
              67% {
                transform: scale(0.9, 0.9);
              }
              100% {
                transform: scale(0.7, 0.7);
              }
            }
          }
          @media screen and(min-width: 1920px) {
            .nav-item {
              margin-left: 2vw;
            }
          }
          @media screen and(min-width: 3000px) {
            .nav-item {
              margin-left: 1vw;
            }
          }
          .active {
            color: @scrollTopActiveColor;
            font-weight: 500;
          }
        }
        .header_nav:hover {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
          color: @scrollTopActiveColor !important;
          font-weight: 500 !important;
        }
        .login-btn {
          display: flex;
          font-size: 16px;
          color: #fff;
          width: 153px;
          height: 36px;
          font-weight: 500;
          align-items: center;
          position: relative;
          overflow: hidden;
          &::after {
            position: absolute;
            left: -100px; /*改变left的值，让其相对box影藏*/
            top: 0;
            width: 30%;
            height: 100%;
            content: '';
            //animation: autoanimate 3s linear infinite; /* 图二效果*/
            //background: linear-gradient(
            //  to right,
            //  rgba(255, 255, 255, 0) 0,
            //  rgba(255, 255, 255, 0.3) 50%,
            //  rgba(255, 255, 255, 0) 100%
            //);
            //z-index: 1;
            //transform: skewX(-45deg);
          }
          @keyframes autoanimate {
            0% {
              left: -100px;
            }
            100% {
              left: 100%;
            }
          }
          &:before {
            content: '';
            width: 1px;
            height: 16px;
            position: absolute;
            top: 10px;
            left: 50%;
            background: #fff;
            opacity: 0.4;
            border-radius: 0.5px;
            z-index: 1;
          }
          .login_btn_item {
            font-size: 16px;
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            position: absolute;
            width: 50%;
            cursor: pointer;
            z-index: 2;
            &:hover {
              color: #efc991;
            }
          }
          .to_login {
            left: 0;
          }
          .to_register {
            right: 0;
          }
          .split_line {
            padding-top: 3px;
            div {
              margin: 0 20px;
              width: 1px;
              height: 16px;
              opacity: 0.6;
              background: #fff;
              border-radius: 0.5px;
            }
          }
        }
        .right_logo {
          height: 24px;
          margin-left: 78px;
          img {
            height: 24px;
          }
        }
        .user-info {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: relative;
          user-select: none;
          .search_box {
            position: relative;
            height: @headHeight;
            .search_wid {
              height: calc(100% - 16px);
              padding-top: 16px;
              box-shadow: 0 6px 16px 2px rgb(7 12 20 / 8%);
              opacity: 0;
              overflow: hidden;
              position: absolute;
              right: -24px;
              top: 0;
              transition: width 0.2s ease-out;
              width: 0;
              will-change: width;
              z-index: 101;
            }
            .search_show {
              opacity: 1;
              width: 265px;
            }
            .search_hide {
              transition: none;
            }
          }

          .header_search {
            background: #fff;
            border-radius: 8px;
            display: flex;
            align-items: center;
            overflow: hidden;
            .shu {
              position: absolute;
              top: 24px;
              left: 76px;
              width: 1px;
              height: 24px;
              background: #eeeeee;
            }
          }

          .header_select {
            /deep/ .el-input--mini .el-input__inner {
              padding-left: 12px;
              width: 76px;
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              color: #333;
              letter-spacing: 0;
              font-weight: 400;
              border: none;
              outline: none;
            }
            /deep/ .el-select__caret {
              color: rgba(0, 0, 0, 0);
              width: 24px;
              background: url('~@/assets/img/zjsj/home_icon.png');
              background-size: 1424px 215px;
              background-repeat: no-repeat;
              background-position: -183px 9px;
            }
          }

          .forn_control {
            padding-right: 68px;
            width: 125px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            color: #333333;
            letter-spacing: 0;
            font-weight: 400;
            outline: none;
            border: none;
            text-indent: 12px;
          }

          .search_icon {
            position: absolute;
            right: 32px;
            top: 24px;
            cursor: pointer;
            width: 24px;
            height: 24px;
            background-position: -976px 0;
          }

          .search_is {
            cursor: pointer;
            width: 24px;
            height: 24px;
            &:hover {
              background-position: -61px 0;
            }
          }

          .delete_icon {
            position: absolute;
            right: 8px;
            top: 24px;
            cursor: pointer;
            width: 24px;
            height: 24px;
            background-position: -610px 0;
          }

          .flexs {
            display: flex;
            align-items: center;
          }
          .login_head_item {
            padding: 0 24px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 18px;
          }
          .go-ht {
            margin-left: 20px;
            font-size: 16px;
            color: #ffffff;
            font-weight: 500;
            cursor: pointer;
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid rgba(255, 255, 255, 0.5);
            &:hover {
              background: rgba(255, 255, 255, 0.2);
            }
          }
          .split_line_login {
            div {
              margin: 0 10px;
              height: 16px;
              opacity: 0.6;
              background: #fff;
              border-radius: 0.5px;
            }
          }
          .go-sj {
            font-size: 16px;
            color: #ffffff;
            font-weight: 500;
            cursor: pointer;
            background: rgba(190, 150, 90, 0.3);
            border: 1px solid #be965a;
            &:hover {
              background: rgba(190, 150, 90, 0.8);
            }
          }
        }
        @media screen and(max-width: 1420px) {
          // .login-btn {
          //   position: absolute;
          //   right: 160px;
          // }
          // .user-info {
          //   position: absolute;
          //   right: 160px;
          // }
          // .right_logo {
          //   position: absolute;
          //   right: 5px;
          // }
        }
      }
    }
    @media screen and(max-width: 1500px) {
      .login-btn {
        position: absolute;
        right: 160px;
      }
      .user-info {
        position: absolute;
        right: 160px;
      }
      .right_logo {
        position: absolute;
        right: 5px;
        margin-left: 20px !important;
      }
      .nav-item {
        margin-left: 20px !important;
      }
    }
    .header_all.otherpage {
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(10px);
      .header_center {
        .nav-list {
          .nav-item {
            font-weight: 400;
            font-family: PingFangSC-Regular;
            color: @scrollingColor;
          }
          .header_nav.active {
            color: @scrollingActiveColor;
            font-weight: 500;
          }
          .header_nav:hover {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            color: @scrollingActiveColor !important;
            font-weight: 500 !important;
          }
        }
      }
      .user-info {
        .header_nav {
          color: @scrollingColor;
        }
        .go-ht {
          border: 1px solid rgba(190, 150, 90, 0.5);
          color: @scrollingActiveColor;
        }
      }
    }
    .header_all.scrollTop {
      background: #000;
      color: #fff;
      .header_center {
        .nav-list {
          .nav-item {
            font-weight: 400px;
            font-family: PingFangSC-Regular;
            color: @scrollTopColor;
          }
          .header_nav.active {
            color: @scrollTopActiveColor;
            font-weight: 500;
          }
          .header_nav:hover {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            color: @scrollTopActiveColor !important;
            font-weight: 500 !important;
          }
        }
      }
    }
    .header_all.scrolling {
      background: rgba(0, 0, 0, 1);
      // backdrop-filter: blur(10px);
      .header_center {
        .nav-list {
          .nav-item {
            font-weight: 400;
            font-family: PingFangSC-Regular;
            color: @scrollingColor;
          }
          .header_nav.active {
            color: @scrollingActiveColor;
            font-weight: 500;
          }
          .header_nav:hover {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            color: @scrollingActiveColor !important;
            font-weight: 500 !important;
          }
        }
        .user-info {
          .header_nav {
            color: @scrollingColor;
          }
          .go-ht {
            border: 1px solid rgba(190, 150, 90, 0.5);
            color: @scrollingActiveColor;
            &:hover {
              color: rgba(190, 150, 90, 0.9);
              background: rgba(190, 150, 90, 0.1);
            }
          }
        }
      }
    }
  }

  @keyframes shine {
    0% {
      background-position-x: 400%;
    }

    50% {
      background-position-x: 0%;
    }

    100% {
      background-position-x: -400%;
    }
  }
</style>
<style lang="less">
  .zjsj_header_select {
    margin-top: 5px !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1) !important;
    background: #fff;
    .hover {
      background: rgba(51, 51, 51, 0.04) !important;
    }
    .selected {
      color: #efc991 !important;
      font-weight: 400 !important;
      background: rgba(190, 150, 90, 0.08) !important;
    }
    .el-select-dropdown__list {
      padding: 4px 0;
    }
    .el-select-dropdown__item {
      padding: 0;
      width: 76px;
      height: 32px;
      padding-left: 12px;
      line-height: 32px;
    }
    .popper__arrow,
    .popper__arrow::after {
      display: none !important;
    }
  }
  .login_after_auth_popover {
    background: rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: blur(10px);
    border-width: 0 !important;
    padding: 32px 32px 20px !important;
    width: 360px !important;
    z-index: 20 !important;
    top: 60px !important;
    .login_after_auth_tips_content {
      color: #fff;
      // position: relative;
      .el-icon-close {
        font-size: 16px;
        color: #fff;
        font-weight: 550;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
      .title {
        font-size: 16px;
        color: #ffffff;
        line-height: 24px;
        font-weight: 600;
      }
      .tips {
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
        font-weight: 400;
      }
      .btn_wrapper {
        display: flex;
        justify-content: flex-end;
      }
      .go-sq {
        width: 112px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 22px;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        font-weight: 400;
        cursor: pointer;
        background: #be965a;
        margin-top: 15px;
        &:hover {
          background-color: #b48a4b;
        }
      }
    }
    .popper__arrow {
      border-width: 18px !important;
      // border-color: rgba(0, 0, 0, 0.5) !important;
      border-top-width: 0 !important;
      top: -18px !important;
      border-bottom-color: rgba(0, 0, 0, 0.46) !important;
      backdrop-filter: blur(10px);
    }
    .popper__arrow::after {
      border-width: 0px !important;
    }
  }
  @media screen and (max-width: 1588px) {
    .login_after_auth_popover {
      right: -5px;
      will-change: right;
    }
  }
</style>
