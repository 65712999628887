<template>
  <div class="open-info" @mouseenter="changeHandle('menuShow', true)" @mouseleave="changeHandle('menuShow', false)">
    <div class="header_avatar">
      <el-avatar class="avatar" :size="32" :src="userProfile.avatarFpath"></el-avatar>
      <div v-if="messageNum > 0" class="unread_msg"></div>
    </div>
    <ul class="dropdown-menu" v-if="menuShow">
      <li class="userinfo-show">
        <el-avatar class="user-head" :size="40" :src="userProfile.avatarFpath"></el-avatar>
        <div class="text_info">
          <span class="info_item">{{ userProfile.mobile }}</span>
          <span class="info_item" v-if="isDesigner && openID">
            <span class="uuid_text">ID: {{ openID }}</span>
            <span class="copy_direct" v-copy-text="openID" @click="gioTrack">复制</span>
          </span>
        </div>
      </li>
      <div v-if="isDesigner">
        <li role="separator" class="divider" v-if="isAuthCompleteDesigner"></li>
        <li class="zhqy" v-if="isAuthCompleteDesigner">
          <div class="z-text">账号权益:</div>
          <div class="tq">
            <div :class="['bgcIcon', 'tq_img', bimShow ? 'bim_show' : 'bim_hide']" title="铂金VIP(高清渲染权限)"></div>
            <div
              :class="['bgcIcon', 'tq_img', vipShow ? 'vip_show' : 'vip_hide']"
              title="钻石VIP(高清渲染+水电暖设计权限)"></div>
          </div>
        </li>
        <li role="separator" class="pad24">
          <div class="divider"></div>
        </li>
        <li class="setting">
          <div class="setting-item" @click="goHt('/person/info', '1')" v-if="isAuthCompleteDesigner">
            <span>账号设置</span>
          </div>
          <div
            class="setting-item"
            @click="goHt('/person/authApply', '1')"
            v-if="settingShow && isAuthCompleteDesigner">
            <span>权益设置</span>
          </div>
          <div class="setting-item" @click="goHt('/message/inboxlist', '1')" v-if="isAuthCompleteDesigner">
            <div class="my_msg">
              <span>我的消息</span>
              <div v-if="messageNum > 0" class="message_num"></div>
            </div>
          </div>
          <div class="setting-item" v-if="showDesignerAuthBtn" @click="handleDesignAuth">
            <span>设计师认证</span>
          </div>
        </li>
        <li role="separator" class="pad24">
          <div class="divider"></div>
        </li>
      </div>
      <li class="setting">
        <div class="setting-item" @click="logout">
          <span>退出登录</span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
  import { getToken, removeToken } from '@/utils/auth'; // 验权
  import { fetchKjlOpenId, getMessageNum, getUserInfo } from '@/api/commApi';
  import { unbindTips } from '@/utils/goToAfterLogin';
  import { DESIGNER_DELETE_STATE } from '@/utils/resources';

  export default {
    data() {
      return {
        messageNum: 0,
        userProfile: {},
        bimShow: false,
        vipShow: false,
        settingShow: false,
        isDesigner: window.localStorage.getItem('platform') === 'design', // design:设计师平台, operation:运营平台
        openID: null,
        menuShow: false,
      };
    },
    created() {
      this.isDesigner = window.localStorage.getItem('platform') === 'design';
      if (getToken() && this.isDesigner) {
        this.getMsg();
      }
      // authFlag 0:未认证 1:认证中 2:已认证 3:认证失败
      if (this.isDesigner && this.$store.state.user.authFlag === '2') {
        this.fetchOpenId();
      }
      this.init();
    },
    '$route.query': function (query) {
      if (query.needReload) {
        this.init();
      }
    },
    computed: {
      isAuthCompleteDesigner() {
        // 设计师角色且已认证且没被解除身份
        return (
          this.isDesigner &&
          this.$store.state.user.authFlag === '2' &&
          this.$store.state.user.userProfileState !== DESIGNER_DELETE_STATE
        );
      },
      showDesignerAuthBtn() {
        return (
          this.$store.state.user.authFlag !== '2' &&
          (this.$store.state.user.infoFlag === '0' || this.$store.state.user.infoFlag === '1') &&
          this.$store.state.user.userProfileState !== DESIGNER_DELETE_STATE
        );
      },
    },
    methods: {
      /**
       * @description 埋点上报
       */
      gioTrack() {
        gio('track', 'CZHT30069'); // eslint-disable-line
      },
      /**
       * @description 获取酷家乐协作ID
       */
      fetchOpenId() {
        fetchKjlOpenId().then((res) => {
          if (res.code === '0') {
            this.openID = res.data;
            this.$store.dispatch('setOpenId', res.data);
          }
        });
      },
      /**
       * @description 获取用户信息
       */
      getUserInfo() {
        getUserInfo().then((res) => {
          if (res.status === '0') {
            this.userProfile = res.data.userProfile;
            this.$store.dispatch('setAuthFlag', res.data.userProfile.authFlag);
            this.$store.dispatch('setInfoFlag', res.data.userProfile.infoFlag);
            this.$store.commit('setUserProfileState', res.data?.userProfile?.state);
            this.$store.commit('setUserCurrentAuth', res.data?.userCurrentAuthVo?.auth);
            if (res.data.userProfile.authFlag !== '2') {
              // 设计师认证标识：0：未认证  1：审核中  2：认证成功  3：认证失败
              return;
            }
            if (res.data.user && res.data.user.kjlAuth == 3) {
              this.bimShow = true;
              this.vipShow = false;
            } else if (res.data.user && res.data.user.kjlAuth == 4) {
              this.bimShow = false;
              this.vipShow = true;
            } else {
              this.bimShow = false;
              this.vipShow = false;
            }
          }
        });
      },
      init() {
        this.isDesigner = window.localStorage.getItem('platform') === 'design';
        let userType = window.localStorage.getItem('userType');
        if (getToken()) {
          if (this.isDesigner) {
            // 设计师
            this.getUserInfo();
            if (userType == '5' || userType == '50') {
              this.settingShow = false;
            }
          } else {
            // 运营人员
            this.userProfile = {
              avatarFpath: window.localStorage.getItem('avatar') || this.headImg,
              mobile: window.localStorage.getItem('name') || '',
            };
          }
        } else {
          removeToken();
        }
      },
      // 消息
      getMsg() {
        getMessageNum().then((res) => {
          if (res.status === '0') {
            this.messageNum = parseInt(res.data);
          }
        });
      },
      changeHandle(type, show) {
        this[type] = show;
      },
      backHome() {
        this.$store.dispatch('setCurrentComponent', null);
        this.$router.push({
          path: '/zjsj/zjsjHome',
          query: {
            needReload: Math.random().toString(),
          },
        });
      },
      completeIgnoreBackHome() {
        this.$store.dispatch('setCurrentComponent', {
          name: 'AUTH_ERROR',
          query: {
            title: '温馨提示',
            content: '您的认证信息还在审核中，请耐心等待，认证通过后内容将全面开放。',
            confirmButtonText: '确认',
            confirmButtonStyle: { width: '112px', height: '44px' },
            onConfirm: () => {
              this.$store.dispatch('setCurrentComponent', null);
            },
          },
        });
      },
      // 当店铺合伙人/店铺负责人无法查询到所属三翼鸟门店时，不能进入认证
      openAuthDialog() {
        let retailStoreList = JSON.parse(window.localStorage.getItem('retailStoreList'));
        // 1 - 店铺/店铺负责人; 2 - 店铺合伙人；
        let partnerType = window.localStorage.getItem('partnerType');
        if ((partnerType === '1' || partnerType === '2') && !retailStoreList?.length) {
          this.$store.dispatch('setCurrentComponent', null);
          setTimeout(() => {
            this.$store.dispatch('setCurrentComponent', {
              name: 'AUTH_ERROR',
              query: {
                confirmButtonText: '确定',
                confirmButtonStyle: { width: '112px', height: '44px' },
                content: '您绑定的客户无三翼鸟门店，请到移动工作台注销身份后再认证设计师',
                onConfirm: () => {
                  this.$store.dispatch('setCurrentComponent', null);
                },
              },
            });
          }, 0);
          return false;
        } else {
          return true;
        }
      },
      handleDesignAuth() {
        if (!getToken()) {
          this.$store.dispatch('setCurrentComponent', { name: 'LOGIN' });
          return;
        }
        let infoFlag = this.$store.state.user.infoFlag;
        let authFlag = this.$store.state.user.authFlag;
        if (authFlag === '1') {
          this.completeIgnoreBackHome();
        } else {
          // 已解绑状态
          if (unbindTips()) {
            return;
          }
          if (!this.openAuthDialog()) {
            return;
          }
          if (infoFlag === '0') {
            this.$store.dispatch('setCurrentComponent', {
              name: 'SELECT_DESIGNER_TYPE',
            });
          } else if (infoFlag === '1') {
            this.$store.dispatch('setCurrentComponent', {
              name: 'COMPLETE_DESIGNER_INFO',
            });
          }
        }
      },
      goHt(path, needToken) {
        if (!getToken() && needToken === '1') {
          this.$store.dispatch('setCurrentComponent', { name: 'LOGIN' });
          return;
        }
        this.$router.push({
          path: path,
        });
      },
      /**
       * @description 退出登录
       */
      logout() {
        const platform3D = window.localStorage.getItem('platform'); // design:设计师平台, operation:运营平台
        if (platform3D === 'operation') {
          window.__USERCENTER__.logout().then(() => {
            removeToken();
          });
        } else {
          this.$store.dispatch('LogOut').then(() => {
            let url = window.location.origin;
            window.location.replace(url);
            location.reload();
          });
        }
      },
    },
  };
</script>
<style lang="less" scoped>
  .open-info {
    height: 72px;
    padding-left: 20px;
    border: none;
    display: flex;
    align-items: center;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    position: relative;
  }

  .header_avatar {
    padding-top: 5px;
    position: relative;
    .avatar {
      border: 1px solid #fff;
    }
    .unread_msg {
      position: absolute;
      top: 1px;
      right: -4px;
      width: 8px;
      height: 8px;
      background: #f05345;
      border-radius: 50%;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 62px;
    right: 0;
    z-index: 2001;
    width: 240px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .userinfo-show {
    padding: 14px 10px;
    display: flex;
    align-items: center;
    .text_info {
      display: flex;
      flex-direction: column;
      span.info_item {
        display: flex;
        flex: 1;
        font-size: 14px;
        color: #333;
        font-weight: 400;
        margin: 5px 0;
        cursor: default;
        .uuid_text {
          font-size: 12px;
        }
        .copy_direct {
          color: #409eff;
          font-size: 12px;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
  }

  .user-head {
    margin-right: 10px;
  }

  .zhqy {
    padding: 17px 24px 10px;
    font-weight: 400;
  }

  .z-text {
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
  }

  .tq {
    display: flex;
    align-items: center;
  }

  .tq_img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    display: inline-block;
    cursor: default;
  }
  .bim_show {
    background-position: -122px -61px;
  }
  .bim_hide {
    background-position: -671px 0;
  }
  .vip_show {
    background-position: -183px -61px;
  }
  .vip_hide {
    background-position: -732px 0;
  }
  .divider {
    height: 1px;
    background: #eeeeee;
    overflow: hidden;
  }
  .pad24 {
    padding: 0 24px;
  }

  .setting {
    padding: 8px 0;
    font-size: 14px;
    font-weight: 400;
    &:empty {
      padding: 0;
    }
  }

  .setting-item {
    text-indent: 24px;
    height: 40px;
    line-height: 40px;
    color: #333;
    cursor: pointer;
    &:hover {
      background: rgba(51, 51, 51, 0.04);
    }
    .my_msg {
      position: relative;
      .message_num {
        position: absolute;
        top: 8px;
        left: 80px;
        width: 8px;
        height: 8px;
        background: #f05345;
        border-radius: 50%;
      }
    }
  }
</style>
