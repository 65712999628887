<template>
  <div class="dialog_header">
    <div
      class="left_back cursor"
      @click="back"
      v-if="showBack"
      v-loading="backLoading"
      element-loading-spinner="el-icon-loading">
      <slot name="left_back">
        <Icon icon="left" size="24px"></Icon>
      </slot>
    </div>
    <div class="header_title">
      {{ title }}
    </div>
    <div class="ignore_step cursor" @click="ignore" v-if="showIgnore">
      <slot name="ignore">
        <ZjsjHomeButton
          type="text"
          :loading="ignoreLoading"
          :customStyle="{
            'font-size': '16px',
            color: '#333333',
            'font-weight': 400,
            background: 'transparent',
            'border-color': 'none',
          }">
          跳过
        </ZjsjHomeButton>
        <span></span>
      </slot>
    </div>
  </div>
</template>
<script>
  import Icon from '@/components/ui/zjsjIcon/icon.vue';
  export default {
    name: 'dialogHeader',
    components: {
      Icon,
    },
    props: {
      showBack: {
        type: Boolean,
        default: false,
      },
      showIgnore: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '提示',
      },
      ignoreLoading: {
        type: Boolean,
        default: false,
      },
      backLoading: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      /**
       * @description header的左上角返回事件
       */
      back() {
        this.$emit('handleBack');
      },
      /**
       * @description header右上角跳过事件
       */
      ignore() {
        this.$emit('handleIgnore');
      },
    },
  };
</script>
<style lang="less" scoped>
  .dialog_header {
    display: flex;
    align-items: center;
    padding: 20px 30px 20px 30px;
    .left_back {
      /deep/ .el-loading-mask {
        width: 100%;
        height: 100%;
        top: 50%;
        left: 100%;
        .el-loading-spinner {
          .el-icon-loading {
            &::before {
              font-size: 16px;
              color: rgb(51, 51, 51);
            }
          }
        }
      }
    }
    .header_title {
      font-size: 28px;
      color: #333;
      font-weight: 500;
      flex: 1;
      justify-content: center;
      display: flex;
      align-items: center;
    }
    .ignore_step {
      font-size: 16px;
      color: #333333;
      font-weight: 400;
    }
    .cursor {
      cursor: pointer;
    }
  }
</style>
