const Verify = {
  //是否为数字
  isNumber(num) {
    var reg = /^\d+(\.\d+)?$/; //判断字符串是否为数字 ，判断正整数用/^[1-9]+[0-9]*]*$/
    if (!reg.test(num)) {
      return false;
    } else {
      return true;
    }
  },
  //是否为正整数
  isIntNumber(num) {
    var reg = /^[1-9]+[0-9]*]*$/;
    if (!reg.test(num)) {
      return false;
    } else {
      return true;
    }
  },
  /**
   * @description 校验是否是整数或两位小数
   * @param { Number | String } num 待验证的值
   * @returns Boolean
   */
  isNumOrTworadix(num) {
    let pattern = /^\d+(\.\d{1,2})?$/;
    return pattern.test(num);
  },
  //是否为两位小数
  isTwoRadix(num) {
    var reg = /^(\d+|\d+\.\d{1,2})$/;
    if (!reg.test(num)) {
      return false;
    } else {
      return true;
    }
  },
  //是否手机号
  isPhoneNumber(phone) {
    var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (!myreg.test(phone)) {
      return false;
    } else {
      return true;
    }
  },
  /**
   * @description 检查是否是手机号或座机号
   * @param {String} num 手机号或座机号
   * @returns Boolean
   */
  isMobileOrPhone(num) {
    let pattern =
      /^((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)$/;
    return pattern.test(num);
  },
  //是否是邮箱
  isEmail(mail) {
    var reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //正则表达式
    if (!reg.test(mail)) {
      //正则验证不通过，格式不对
      return false;
    } else {
      return true;
    }
  },
  //是否是URL链接
  isUrl(url) {
    var reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .?%&=]*)?/; //正则表达式
    return reg.test(url);
  },
  /**
   * @description 验证是否是银行卡号
   * @param { String } cardNum 银行卡号
   * @return { Boolean }
   */
  isBankNum(cardNum) {
    let pattern = /^[1-9]\d{9,29}$/;
    return pattern.test(cardNum);
  },
  /**
   * @description 验证是否是香港身份证
   * @param { String } cardId 身份证号
   * @returns Boolean
   */
  isHkIdCard(cardId) {
    let pattern = /^[a-zA-Z]\d{6}\([\dA]\)$/;
    return pattern.test(cardId);
  },
  /**
   * @description 校验澳门身份证号是否符合格式
   * @param { String } cardId 身份证号
   * @returns Boolean
   */
  isMacaoIdCard(cardId) {
    let pattern = /^[1|5|7]\d{6}[(\d)]{3}$/;
    return pattern.test(cardId);
  },
  /**
   * @description 验证台湾身份证号是否符合格式
   * @param { String } cardId 身份证号
   * @returns Boolean
   */
  isTaiWanIdCard(cardId) {
    let pattern = /^[a-zA-Z][0-9]{9}$/;
    return pattern.test(cardId);
  },
  /**
   * @description 验证中国大陆身份证号是否符合格式
   * @param { String } cardId 身份证号
   * @returns Boolean
   */
  isChinesemainlandIdCard(cardId) {
    let pattern =
      /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
    return pattern.test(cardId);
  },
  /**
   * @description 校验是否是中国的身份证是否符合格式
   * @param { String } cardId 身份证号
   * @returns Boolean
   */
  isChinaIdCard(cardId) {
    let { isHkIdCard, isMacaoIdCard, isTaiWanIdCard, isChinesemainlandIdCard } =
      Verify;
    return (
      isHkIdCard(cardId) ||
      isMacaoIdCard(cardId) ||
      isTaiWanIdCard(cardId) ||
      isChinesemainlandIdCard(cardId)
    );
  },
  /**
   * @description 校验人名
   * @param {String} name 人名
   * @returns Boolean
   */
  validateRegistorName(name) {
    let reg = /^[\u4E00-\u9FA5]{1,20}([.·]?[\u4E00-\u9FA5]{1,20}){0,2}$/;
    return reg.test(name);
  },
  /**
   *@description 是否是汉字
   */
  isZhcn(value) {
    let pattern =
      /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/;
    return pattern.test(value);
  },
  /**
   * @description 检测邮箱是否是海尔邮箱
   * @param { String } email 邮箱
   */
  isHaierEmail(email) {
    let isEmail = Verify.isEmail(email);
    if (isEmail && email.endsWith('@haier.com')) {
      return true;
    }
    return false;
  },
  /**
   * 校验不能为表情符号的正则
   *     当为表情时，返回true
   *     其他情况返回false
   */
  isEmoticonsRegExp(world) {
    const pattern = /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g;
    const result = pattern.test(world);
    pattern.lastIndex = 0;
    return result;
  },
  // 输入表情符号时，清空输入
  emojiClear(val) {
    return val.replace(
      /(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f])|(\ud83d[\ude80-\udeff])/g,
      ''
    );
  },
  /**
   * 校验是否为空格或特殊符号的正则
   *     当存在特殊符号或者表情时，返回true
   *     其他情况返回false
   */
  isSpecialWordRegExp(world) {
    const pattern =
      /[`~#$%^&*@()/+_+=<>{}|~#￥%……&*@（）={}|《》【】\s+]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g;
    const result = pattern.test(world);
    pattern.lastIndex = 0;
    return result;
  },
  /**
   * @description 检测是否含有特殊字符
   */
  hasSpecialCharacter(value) {
    let pattern = /[`~#$%^&*@()/+_+=<>{}|~#￥%……&*@（）={}|《》【】\s+]/g;
    return pattern.test(value);
  },
  // 是否为昵称
  isNickName(value) {
    // 昵称必须含有中文或者英文、不能使用空格；字数限制4-10字；
    const nickNameReg = /[\u4e00-\u9fa5a-zA-Z]+/;
    const notBlankReg = /^\S+$/;
    const countReg = /^\S{4,10}$/;
    if (
      !nickNameReg.test(value) ||
      !notBlankReg.test(value) ||
      !countReg.test(value)
    ) {
      return false;
    } else {
      return true;
    }
  },
  nameCheck(value) {
    // 只能输入中文或者英文；字数限制2-10字；
    const nickNameReg = /^[A-Za-z\u4e00-\u9fa5]+$/gi;
    const countReg = /^\S{2,10}$/;
    if (!nickNameReg.test(value) || !countReg.test(value)) {
      return false;
    } else {
      return true;
    }
  },
};

export default Verify;
export const isTwoRadix = Verify.isTwoRadix;
export const isNumOrTworadix = Verify.isNumOrTworadix;
export const isBankNum = Verify.isBankNum;
export const isChinaIdCard = Verify.isChinaIdCard;
export const isMobileOrPhone = Verify.isMobileOrPhone;
export const isEmail = Verify.isEmail;
export const isUrl = Verify.isUrl;
export const isIntNumber = Verify.isIntNumber;
export const isHaierEmail = Verify.isHaierEmail;
export const isEmoticonsRegExp = Verify.isEmoticonsRegExp;
export const emojiClear = Verify.emojiClear;
export const isSpecialWordRegExp = Verify.isSpecialWordRegExp;
export const isNickName = Verify.isNickName;
export const nameCheck = Verify.nameCheck;
export const isZhcn = Verify.isZhcn;
export const validateRegistorName = Verify.validateRegistorName;
