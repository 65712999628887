import { Message } from 'element-ui';
import Vue from 'vue';
import ScrollAnimate from './3d-scroll-animate';
import { jumpHouseTour } from '@/api/houseTourApi';
Vue.directive('authority', {
  inserted(el, binding, vnode) {
    let menuList = JSON.parse(localStorage.menuList);
    let crmMenuList = [];
    if (menuList) {
      crmMenuList = menuList.crm || [];
      menuList.sys = menuList.sys || [];
      crmMenuList = crmMenuList.concat(menuList.sys);
    }
    let flag = ~crmMenuList.indexOf(binding.value);
    if (!flag) {
      if (el.parentNode) {
        el.parentNode.removeChild(el);
      } else {
        el.innerHTML = '';
        el.style.display = 'none';
        // 解除组件的事件
        vnode.elm.__vue__ && vnode.elm.__vue__.$off();
        // 解除链接跳转
        el.tagName === 'A' && el.setAttribute('href', 'javascript:void(0)');
        // 解除元素点击事件
        if (vnode.data.on && vnode.data.on.click) {
          vnode.data.on.click.fn = () => {
            return;
          };
        }
        if (vnode.data.nativeOn && vnode.data.nativeOn.click) {
          vnode.data.nativeOn.click.fn = () => {
            return;
          };
        }
      }
    }
  },
});

Vue.directive('preventReClick', {
  inserted: function (el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 3000);
      }
    });
  },
});

// 星云平台按钮权限控制
Vue.directive('btnPermission', {
  //被绑定元素插入父节点时调用
  inserted: function (el, binding, vnode) {
    configBtn(el, binding, vnode);
  },
  //所在组件的 VNode 更新时调用
  update: function (el, binding, vnode) {
    configBtn(el, binding, vnode);
  },
});

// el-select滚动加载
Vue.directive('loadMore', {
  bind(el, binding) {
    const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
    SELECTWRAP_DOM.addEventListener('scroll', function () {
      const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
      if (condition) {
        binding.value();
      }
    });
  },
});

// 复制的指令
Vue.directive('copyText', {
  inserted(el, binding) {
    el.targetContent = binding.value;
    el.addEventListener('click', () => {
      if (!el.targetContent) return console.warn('没有需要复制的目标内容');
      // 创建textarea标签
      const textarea = document.createElement('textarea');
      // 设置相关属性
      textarea.readOnly = 'readonly';
      textarea.style.position = 'fixed';
      textarea.style.top = '-99999px';
      // 把目标内容赋值给它的value属性
      textarea.value = el.targetContent;
      // 插入到页面
      document.body.appendChild(textarea);
      // 调用onselect()方法
      textarea.select();
      // 把目标内容复制进剪贴板, 该API会返回一个Boolean
      const res = document.execCommand('Copy');
      res && Message.success('复制成功');
      // 移除textarea标签
      document.body.removeChild(textarea);
    });
  },
  updated(el, binding) {
    // 实时更新最新的目标内容
    el.targetContent = binding.value;
  },
  unmounted(el) {
    el.removeEventListener('click', () => {});
  },
});

/**
 * @description 注册tooltip气泡自定义指令
 * @param { Object } binding 气泡的配置项 { placement:
 *   [top/top-start/top-end/bottom/bottom-start/bottom-end/left/left-start/left-end/right/right-start/right-end],
 *   content: String}
 */
Vue.directive('tooltip', {
  inserted(el, binding) {
    let { placement = 'top', content = '', force = false } = binding.value;
    if (!/^(top|bottom|left|right)(-start|-end)?$/g.test(placement)) {
      console.warn('tooltip placement不符合规则将使用默认样式');
      placement = 'top';
    }

    el.addEventListener('mouseover', (e) => {
      const isWrap = el.scrollWidth > el.clientWidth;
      if (isWrap || force) {
        let { clientY } = e;
        let top = clientY - 70;
        // tooltip节点
        let tootipDom = document.createElement('div');
        tootipDom.innerHTML = content;
        let style = `position: fixed;top: ${top}px;visibility:visible;background: #fff;padding:12px;border-radius:8px;box-shadow: 1px 1px 10px 3px #d3d3d6;`;
        tootipDom.setAttribute('style', `${style}`);
        tootipDom.setAttribute('class', `zjsj_tooltip`);

        // 小三角形节点
        let deltaDom = document.createElement('div');
        let deltStyle =
          'position:absolute;width:8px;height:8px;background:#fff;top:100%;left:50%;transform:translate(-50%, -50%) rotate(45deg)';
        deltaDom.setAttribute('style', deltStyle);

        tootipDom.appendChild(deltaDom);
        let havedToolip = el.querySelector('.zjsj_tooltip');
        if (!havedToolip) {
          // 已经存在就不再插入节点
          el.appendChild(tootipDom);
        } else {
          havedToolip.setAttribute('style', style);
        }
        el.addEventListener('mouseover', (e) => {
          let { clientX, clientY, offsetX, offsetY } = e;
          calTooltipPosition(
            el,
            placement,
            tootipDom,
            style,
            { clientX, clientY, offsetX, offsetY },
            deltaDom,
            deltStyle
          );
        });
        el.addEventListener('mouseout', () => {
          tootipDom.setAttribute('style', `${style}visibility:hidden`);
        });
      }
    });
  },
  update(el, binding) {
    let { placement = 'top', content = '', force = false } = binding.value;
    if (!/^(top|bottom|left|right)(-start|-end)?$/g.test(placement)) {
      console.warn('tooltip placement不符合规则将使用默认样式');
      placement = 'top';
    }

    el.addEventListener('mouseover', (e) => {
      const isWrap = el.scrollWidth > el.clientWidth;
      if (isWrap || force) {
        let { clientY } = e;
        let top = clientY - 70;
        // tooltip节点
        let tootipDom = document.createElement('div');
        tootipDom.innerHTML = content;
        let style = `position: fixed;top: ${top}px;visibility:visible;background: #fff;padding:12px;border-radius:8px;box-shadow: 1px 1px 10px 3px #d3d3d6;`;
        tootipDom.setAttribute('style', `${style}`);
        tootipDom.setAttribute('class', `zjsj_tooltip`);

        // 小三角形节点
        let deltaDom = document.createElement('div');
        let deltStyle =
          'position:absolute;width:8px;height:8px;background:#fff;top:100%;left:50%;transform:translate(-50%, -50%) rotate(45deg)';
        deltaDom.setAttribute('style', deltStyle);

        tootipDom.appendChild(deltaDom);
        let havedToolip = el.querySelector('.zjsj_tooltip');
        if (!havedToolip) {
          // 已经存在就不再插入节点
          el.appendChild(tootipDom);
        }
        el.addEventListener('mouseover', (e) => {
          let { clientX, clientY, offsetX, offsetY } = e;
          calTooltipPosition(placement, tootipDom, style, { clientX, clientY, offsetX, offsetY }, deltaDom, deltStyle);
        });
        el.addEventListener('mouseout', () => {
          tootipDom.setAttribute('style', `${style}visibility:hidden`);
        });
      }
    });
  },
});

function calTooltipPosition(placement, tootipDom, style, { clientX, clientY, offsetX }, deltaDom, deltStyle) {
  let { clientWidth, clientHeight } = tootipDom;
  let isTop = /^top/.test(placement);
  let isBottom = /^bottom/.test(placement);
  let isLeft = /^left/.test(placement);
  let isRight = /^right/.test(placement);
  if (isTop || isBottom) {
    let top = isTop ? clientY - clientHeight - 30 : clientY + 30;
    if (/start$/.test(placement)) {
      tootipDom.setAttribute('style', `${style};top:${top}px;left:${clientX - clientWidth - offsetX - 30}px;`);
      deltaDom.setAttribute(
        'style',
        `${deltStyle};top:${isBottom ? '30%' : '70%'};left:${clientWidth}px;transform: translate(0, 0) rotate(45deg)`
      );
    } else if (/end$/.test(placement)) {
      tootipDom.setAttribute('style', `${style};top:${top}px;left:${clientX}px`);
      deltaDom.setAttribute(
        'style',
        `${deltStyle};top:${isBottom ? '30%' : '70%'};left:0px;transform: translate(0, 0) rotate(45deg)`
      );
    } else {
      tootipDom.setAttribute('style', `${style};top:${top}px;left:${clientX - clientWidth / 2}px`);
      deltaDom.setAttribute('style', isBottom ? `${deltStyle};top:0` : `${deltStyle};`);
    }
  } else if (isLeft || isRight) {
    let top = clientY - clientHeight / 2;
    let leftPos = isLeft ? clientX - clientWidth - offsetX : clientX + 30;
    if (/start$/.test(placement)) {
      tootipDom.setAttribute('style', `${style};top:${top}px;left:${clientX - clientWidth - offsetX}px`);
      deltaDom.setAttribute(
        'style',
        `${deltStyle};top:30%;left:${clientWidth}px;transform: translate(0, 0) rotate(45deg)`
      );
    } else if (/end$/.test(placement)) {
      tootipDom.setAttribute('style', `${style};top:${top}px;left:${clientX + 30}px`);
      deltaDom.setAttribute('style', `${deltStyle};top:30%;left:0px;transform: translate(0, 0) rotate(45deg)`);
    } else {
      tootipDom.setAttribute('style', `${style};top:${top}px;left:${leftPos}px`);
      deltaDom.setAttribute(
        'style',
        `${deltStyle};top:30%;right:${isLeft ? clientWidth : 0}px;transform: translate(0, 0) rotate(45deg)`
      );
    }
  }
}

/**
 *
 * @param {*} el 绑定的元素，用来直接操作 DOM
 * @param {*} binding 对象
 * @param {*} vnode Vue 编译生成的虚拟节点
 */
function configBtn(el, binding) {
  let action = binding.value.action;
  if (getBtnPermission(action)) {
    return;
  }
  el.remove();
  //是嵌入在星云系统中
}

function getBtnPermission(action) {
  if (window.self !== window.top) {
    return true;
  }
  if (!window.localStorage.getItem('btnList')) {
    return false;
  }
  if (!action) {
    return false;
  }
  const btnList = JSON.parse(window.localStorage.getItem('btnList')) || [];
  return btnList.includes(action);
}

Vue.directive('zjsj-scroll-animate', {
  inserted(el, binding) {
    const scrollAnimate = ScrollAnimate(Date.now());
    const params = binding.value;
    const useParent = el.parentNode.childNodes.length == 1;
    const offset = parseInt(el.dataset.animateOnscrollOffset || 0);
    let previousClassName = el.className;
    let lastScrollTop = window.pageYOffset;
    previousClassName = previousClassName.replace(params.in || params, '').replace(params.out, '');
    window.addEventListener(
      'scroll',
      function () {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const isUpwards = scrollTop < lastScrollTop;
        scrollAnimate.run(el, binding, {
          useParent,
          offset,
          isUpwards,
          previousClassName,
        });
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
      },
      false
    );
  },
});

/**
 * @description 埋点的自定义指令
 * @使用方法 <div v-gio-track="{id: 埋点事件ID, payload: 需要额外传递的参数 没有要求就不填此字段}"></div>
 */
Vue.directive('gio-track', {
  inserted(el, binding) {
    let trackInfo = binding?.value;
    if (!trackInfo) {
      console.error('埋点必须绑定信息', el, binding);
      return;
    }
    el.setAttribute('gio_info', JSON.stringify(trackInfo));
    el.addEventListener('click', () => {
      let trackInfoStr = el.getAttribute('gio_info');
      let trackInfo = JSON.parse(trackInfoStr || '{}');
      let { id, payload } = trackInfo;
      /* eslint-disable */
      if (id && payload) {
        gio('track', id, payload);
      } else if(id && !payload){
        gio('track', id);
      }
      /* eslint-enable */
    });
  },
  update(el, binding) {
    el.setAttribute('gio_info', JSON.stringify(binding.value));
  },
});

Vue.directive('viewVr', {
  inserted(el, binding) {
    let info = binding?.value;
    if (!info) {
      console.error('全景VR必须绑定信息', el, binding);
      return;
    }
    el.setAttribute('viewVr-title', info.title);
    el.setAttribute('viewVr-url', info.url);
    el.addEventListener('click', () => {
      console.log('info:', info);
      jumpHouseTour(el.getAttribute('viewVr-title'), el.getAttribute('viewVr-url'));
    });
  },
  update(el, binding) {
    el.setAttribute('viewVr-title', binding.value.title);
    el.setAttribute('viewVr-url', binding.value.url);
  },
});

export { getBtnPermission };
