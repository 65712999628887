const designIframe = {
  state: {
    designId: '',
  },
  mutations: {
    setDesignId: (state, designId) => {
      localStorage.setItem('designId', designId);
      state.designId = designId;
    },
  },
  actions: {
    setDesignId: ({ commit }, designId) => {
      commit('setDesignId', designId);
    },
  },
};
export default designIframe;
