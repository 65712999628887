import Layout from '../layout/home'; // 页面整体布局
export default function getIframeRouter(params, router) {
  let urlArr = [];
  //递归树结构将iframe形式的链接存储和添加路由
  function recursion(params, router) {
    if (Array.isArray(params) && params.length) {
      params.forEach((item) => {
        if (item.menuType === 1) {
          let urlObj = {
            path: item.path,
            url: item.url,
          };
          urlArr.push(urlObj);
          setRouter(item, router);
        }
        if (item.children) {
          recursion(item.children, router);
        }
      });
    }
  }
  recursion(params, router);
  localStorage.setItem('urlArr', JSON.stringify(urlArr));
}

function setRouter(item, router) {
  //添加路由
  const routerObj = {
    path: item.path,
    name: item.name,
    meta: {
      title: item.name,
      routerType: 'leftmenu',
      url: item.name,
    },
    noDropdown: true,
    component: () => import('@/page/iframeContainer/iframeContainer'),
  };
  let str = item.path;
  let fatherUrl = '/' + str.split('/')[1];
  let fatherObj = {};
  let isHave = false;
  let isRepetition = false;
  let routesArr = router.options.routes;
  for (let i = 0; i < routesArr.length; i++) {
    //判断父级路由是否重复
    if (routesArr[i].path === fatherUrl) {
      //判断父级路由下子路由有没有重复
      routesArr[i].children.forEach((items) => {
        if (items.path === routerObj.path) {
          isRepetition = true;
        }
      });
      isHave = true;
      fatherObj = routesArr[i];
      break;
    } else {
      fatherObj = {
        path: fatherUrl,
        component: Layout,
        meta: {
          title: fatherUrl,
        },
        noDropdown: true,
        children: [routerObj],
      };
    }
  }
  //如果有父路由将父路由删除添加新的拼接好的路由
  if (isHave) {
    //如果没有重复的push进去
    if (!isRepetition) {
      fatherObj.children.push(routerObj);
    }
    const index = router.options.routes.findIndex((items) => items.path === fatherUrl);
    router.options.routes.splice(index, 1);
  }
  router.options.routes.push(fatherObj);
  router.$addRoutes(router.options.routes);
}
