<template>
  <div class="car">
    <el-carousel
      ref="elCarousel"
      :height="carData.height"
      :arrow="carData.arrow"
      :initial-index="index"
      :interval="carData.interval"
      :indicator-position="carData.indicator"
      @change="changeCarousel">
      <el-carousel-item v-for="(item, index) in imgList" :key="item.id" :name="index + ''" @click.native="bannerClick">
        <template v-if="item.imgUrlType === '1'">
          <!-- <video
            :src="returnImg(item.fpath)"
            autoplay
            muted
            loop
            class="car-video"
            v-if="!fadeIn"></video> -->
          <VideoCustomize
            v-if="!fadeIn"
            ref="video"
            class="car-video"
            :controls="false"
            :show-bg="false"
            :src="returnImg(item.fpath)"></VideoCustomize>
        </template>
        <template v-else>
          <div
            class="car-img"
            v-if="!fadeIn"
            :style="{
              background: 'url(' + returnImg(item.fpath) + ') 50% 50% no-repeat',
              'background-size': 'cover',
            }"></div>
        </template>
      </el-carousel-item>
    </el-carousel>
    <!-- 淡入淡出 -->
    <div v-if="fadeIn">
      <div class="carousel_fade" v-for="(item, i) in imgList" :key="item.id">
        <template v-if="item.imgUrlType === '1'">
          <VideoCustomize
            v-if="fadeIn"
            ref="video"
            :class="['fade_wid', index === i ? 'fade_show' : 'fade_hide']"
            :controls="false"
            :show-bg="false"
            :src="returnImg(item.fpath)"></VideoCustomize>
        </template>
        <template v-else>
          <div
            :class="['fade_wid', index === i ? 'fade_show' : 'fade_hide']"
            :style="{
              background: 'url(' + returnImg(item.fpath) + ') 50% 50% no-repeat',
              'background-size': 'cover',
            }"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { returnImg } from '@/utils/mUtils';
  import VideoCustomize from '@/components/ui/videoCustomize/videoCustomize.vue';
  export default {
    name: 'carouselHand',
    components: {
      VideoCustomize,
    },
    props: {
      carData: {
        type: Object,
        default: () => {
          return {};
        },
      },
      imgList: {
        type: Array,
        default: () => {
          return [];
        },
      },
      index: {
        type: Number,
        default: 0,
      },
      fadeIn: {
        // 淡入淡出
        type: Boolean,
        default: false,
      },
      changeIndex: {
        type: Number,
      },
    },
    watch: {
      changeIndex() {
        // 切换幻灯片
        // this.$refs.elCarousel.setActiveItem(this.changeIndex + '');
      },
    },
    methods: {
      changeCarousel(i) {
        this.$emit('carouselIndex', i);
      },
      bannerClick() {
        this.$emit('bannerClick');
      },
      returnImg,
    },
  };
</script>

<style lang="less" scoped>
  .car {
    height: 100%;
    width: 100%;
    position: relative;
    .car-img {
      height: 100%;
      width: 100%;
    }
    .car-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .carousel_fade {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      .fade_wid {
        height: 100%;
        width: 100%;
      }
      .fade_show {
        // display: block;
        animation: fadeInOut 0.5s 1; // 动画名称 动画时长 动画运动速度 延迟时间 执行次数 往返动画
      }
      .fade_hide {
        display: none;
      }
      @keyframes fadeInOut {
        0% {
          opacity: 0.2;
        }
        30% {
          opacity: 0.5;
        }
        50% {
          opacity: 0.7;
        }
        100% {
          opacity: 1;
        }
      }
    }
    /deep/.el-carousel__indicators--horizontal {
      bottom: 20px;
      display: flex;
      align-items: center;
    }
    /deep/.el-carousel__indicator--horizontal {
      padding: 0;
    }
    /deep/.el-carousel__indicator {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
    /deep/.el-carousel__button {
      width: 28px;
      height: 3px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 2px;
      opacity: 1;
      cursor: pointer;
    }
    /deep/.is-active .el-carousel__button {
      width: 28px;
      height: 3px;
      background: #ffffff;
    }
    // 左右按钮
    /deep/.el-carousel__arrow {
      height: 48px;
      width: 48px;
      color: rgba(0, 0, 0, 0);
      background: rgba(0, 0, 0, 0);
      background: url('~@/assets/img/zjsj/model_icon.png');
      background-repeat: no-repeat;
      background-position: -183px -61px;
    }
    /deep/.el-carousel__arrow--left {
      left: 20px;
    }
    /deep/.el-carousel__arrow--right {
      right: 20px;
      background-position: -183px 0;
    }
  }
</style>
