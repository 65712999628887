<template>
  <header class="head-nav rflex" id="header_container">
    <div class="logo-container">
      <img class="title" src="../assets/img/logo-full-v2.png" alt="" />
    </div>
    <div class="right-nav" ref="rightNav">
      <div class="userinfo-right">
        <div class="userinfo">
          <label @click="goFrontEnd" class="labelStyle">返回门户</label>
          <el-popover v-if="isDesigner" placement="bottom" width="277" trigger="hover">
            <div class="labelStyleContact">
              <i class="el-icon-my-phone"></i>
              <span>咨询热线：</span>
              {{ mobile }}
            </div>
            <div class="labelStyleContact">
              <i class="el-icon-my-email"></i>
              <span>咨询邮箱：</span>
              {{ mail }}
            </div>
            <label class="labelStyle divide-vertical" slot="reference">
              <span>联系客服</span>
            </label>
            <label class="labelStyle divide-vertical" slot="reference">
              <span @click="openDoc">排查手册</span>
            </label>
          </el-popover>
          <template v-if="isDesigner">
            <userInfoMenuVue></userInfoMenuVue>
          </template>
          <template v-else>
            <span class="username">
              <el-dropdown @command="setDialogInfo">
                <span class="el-dropdown-link">
                  <div class="avatar"></div>
                  <div class="welcome">
                    <p class="name avatarname">{{ username }}</p>
                  </div>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    style="line-height: 30px !important; padding: 3px 20px 3px 10px; font-size: 14px"
                    command="logout">
                    <i class="el-icon-caret-bottom el-icon-s-release"></i>
                    退出登录
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </template>
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </header>
</template>

<script>
  import request from '@/utils/axios';
  import { serverUrl } from '@/utils/env';
  import { removeToken } from '@/utils/auth';
  import userInfoMenuVue from '@/components/ui/userInfoMenu/userInfoMenu.vue';

  import iamInit from '@/utils/iam'; // 调用集团portal
  const platform3D = window.localStorage.getItem('platform'); // design:设计师平台, operation:运营平台
  if (platform3D === 'operation') {
    iamInit();
  }

  export default {
    name: 'head-nav',
    components: {
      userInfoMenuVue,
    },
    data() {
      return {
        username: '',
        userIcon: '',
        isShowNotification: false,
        mobile: '',
        mail: '',
        isDesigner: window.localStorage.getItem('platform') === 'design', // design:设计师平台, operation:运营平台
      };
    },
    computed: {},
    created() {
      this.username = window.localStorage.getItem('name');
      this.userIcon = window.localStorage.getItem('avatar') || '';
      this.getData();
      // if (this.isDesigner) {
      //   this.getUnReadNotifications();
      // }
    },
    mounted() {},
    watch: {
      '$store.state.operationInfo.operationInfo.mobile': function (val) {
        this.mobile = val;
      },
      '$store.state.operationInfo.operationInfo.mail': function (val) {
        this.mail = val;
      },
    },
    methods: {
      openDoc() {
        window.open('https://ihaier.feishu.cn/docx/QDstdEMCRoBeJlxRZpIcNVCdn7d');
      },
      //获取运营信息
      getData() {
        request({
          url: serverUrl + '/server/zjsj/contactCustomerServiceApi/noDesensitization',
          method: 'get',
        }).then((res) => {
          if (res && res.code === '0' && res.data && res.data.length > 0) {
            this.mobile = res.data[0].mobile;
            this.mail = res.data[0].mail;
            let params = {
              mobile: res.data[0].mobile,
              mail: res.data[0].mail,
            };
            this.$store.dispatch('setOperationInfo', params);
          }
        });
      },
      // 退出
      logout() {
        if (this.isDesigner) {
          this.$store.dispatch('LogOut').then(() => {
            let url = window.location.origin;
            window.location.replace(url);
            location.reload();
          });
        } else {
          window.__USERCENTER__.logout().then(() => {
            removeToken();
          });
        }
      },
      // 返回门户
      goFrontEnd() {
        // 3d前台 - 首页
        this.$router.push({ path: '/' });
      },
      // 消息列表
      goInboxList() {
        this.$router.push({ path: '/message/inboxlist' });
      },
      /**
       * 弹出框-系统设置
       * @param {string} cmditem 弹框类型
       */
      setDialogInfo(cmditem) {
        if (!cmditem) {
          this.$message('菜单选项缺少command属性');
          return;
        }
        switch (cmditem) {
          case 'logout':
            this.logout();
            break;
          case 'goFrontEnd':
            this.goFrontEnd();
            break;
        }
      },
      // 获取未读消息
      getUnReadNotifications() {
        const userId = localStorage.getItem('userId');
        request({
          url: `${serverUrl}/api/user/getUnReadNotifications?userId=${userId}`,
          method: 'get',
        })
          .then((res) => {
            if (res) {
              const { status, data } = res;
              if (status === '0') {
                this.isShowNotification = data > 0;
              }
            }
          })
          .catch(() => {
            // do nothing
          });
      },
    },
  };
</script>

<style scoped lang="less">
  .el-icon-my-service {
    background: url('../assets/img/contactService/service.png') center no-repeat;
  }
  .el-icon-my-phone {
    background: url('../assets/img/contactService/phone.png') center no-repeat;
    vertical-align: middle;
  }
  .el-icon-my-email {
    background: url('../assets/img/contactService/email.png') center no-repeat;
    vertical-align: middle;
  }
  .el-icon-my-service::before,
  .el-icon-my-phone::before,
  .el-icon-my-email::before {
    content: '\ad3a';
    font-size: 13px;
    visibility: hidden;
  }
  .labelStyle {
    font-size: 16px;
    color: #333333;
    cursor: pointer;
    &:hover {
      color: #2878ff;
    }
  }
  .unread {
    position: relative;
    &::after {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #ff2e2e;
      position: absolute;
      top: 0;
      right: -8px;
    }
  }
  .divide-vertical {
    &::before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 16px;
      background-color: #333333;
      opacity: 0.2;
      vertical-align: middle;
      margin: 0 20px;
    }
  }
  .labelStyleContact {
    font-size: 14px;
    color: #606266;
    cursor: pointer;
    span {
      vertical-align: middle;
      margin-left: 9px;
    }
  }
  .labelStyleContact:first-child {
    padding-bottom: 8px;
  }
  .logo-container {
    padding: 6px 0 6px 20px;
    // min-width: 220px;
    display: flex;
    height: 72px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    box-sizing: border-box;
    box-shadow: 0px 2px 5px 0px rgba(230, 224, 224, 0.5);
    .logo {
      height: 50px;
      width: 50px;
      vertical-align: middle;
      display: inline-block;
    }
    .title {
      height: 44px;
      // max-width: 250px;
    }
  }
  .right-nav {
    width: calc(100% - 180px);
    background-color: white;
  }
  .fa-user {
    position: relative;
    top: -2px;
    margin-right: 4px;
  }
  .head-nav {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    min-width: 600px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
    .logout {
      vertical-align: middle;
      cursor: pointer;
    }
  }
  .middle {
    align-items: center;
    border: 1px solid;
  }
  .userinfo-right {
    min-width: 330px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .userinfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    vertical-align: middle;
    display: inline-block;
    background: url('~@/assets/img/zjsj/head.png');
    background-size: cover;
  }
  .welcome {
    display: inline-block;
    width: auto;
    vertical-align: middle;
    padding: 0 5px;
    .name {
      line-height: 20px;
      text-align: center;
      font-size: 16px;
      &:hover {
        color: #2878ff;
      }
    }
    .avatarname {
      font-size: 16px;
      color: #333333;
    }
  }
  .username {
    cursor: pointer;
    margin: 0 5px 0 20px;
  }
  .border {
    border: 1px solid;
  }
  .notify-row {
    line-height: 60px;
    margin-right: 20px;
    margin-top: 5px;
  }
  ul.top-menu > li {
    float: left;
    margin-right: 20px;
    position: relative;
    .wechat-area {
      position: absolute;
      z-index: 8;
      top: 55px;
      left: -30px;
      border: 1px solid #a9d86e;
      border-radius: 4px;
      text-align: center;
      .titles {
        font-size: 14px;
        background: #a9d86e;
        color: #fff;
        width: 100%;
        height: 30px;
        line-height: 30px;
      }
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
  ul.top-menu > li > a {
    color: #3bc5ff;
    font-size: 16px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    border: 1px solid #f0f0f8 !important;
    padding: 2px 6px 4px 6px;
  }
  .clear {
    clear: both;
  }
</style>
