import router from '@/router';
import store from '@/store';
import NProgress from 'nprogress'; // Progress 进度条
import 'nprogress/nprogress.css'; // Progress 进度条样式
import { Message, MessageBox } from 'element-ui';
import { getToken } from '@/utils/auth'; // 验权
import { setTitle } from '@/utils/mUtils'; // 设置浏览器头部标题
import request from '@/utils/axios';
import { serverUrl } from '@/utils/env';
import { removeToken } from '@/utils/auth';
// import axios from "axios";
import { isIframe, GetQueryString } from '@/utils/mUtils';
import { whiteList } from '@/router/router.whitelist.js';
import { isIframeInNebula, manageIndex } from '@/utils/beforeSetUp';
// import { getUserJobNum } from '@/utils/auth';
// import { getBtnList } from '@/api/login';

// const resource = 'designerplatform'; // 埋点标识
window.localStorage.setItem('storageAside', '0');

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  // 设置浏览器头部标题
  const SET_BROWSER_TITLE_ROUTE = ['/HouseTourIframe', '/designIframeEdit'];
  if (SET_BROWSER_TITLE_ROUTE.includes(to.path)) {
    to.meta.title = to.query.title;
  }
  const browserHeaderTitle = to.meta.title;
  store.commit('SET_BROWSERHEADERTITLE', {
    browserHeaderTitle: browserHeaderTitle,
  });

  if (isIframe()) {
    // iframe内，且星云平台内
    if (GetQueryString('resource') === 'nebula') {
      next();
    } else {
      next({ path: '/404' });
    }
    NProgress.done();
    return;
  }
  // 点击登录时，拿到了token并存入了vuex;
  if (getToken()) {
    // 案例发布
    if (to.path === '/jumpShdp') {
      jumpShdp();
      // 跳转后台首页
      next({ path: manageIndex() });
      return;
    }
    // 单点跳转海尔家居
    if (to.path === '/jumpHaierHome') {
      jumpHaierHome();
      // 跳转后台首页
      // next({ path: manageIndex() });
      next(false);
      return;
    }
    // 跳转设计圈
    if (to.path === '/designCircleIframe' && from.path !== '/') {
      NProgress.done();
      if (!store.state.user.userCurrentAuth) {
        // 如果用户已认证但无权益
        MessageBox.alert(
          '您的设计工具权益已失效，若需使用权益，可以到“我的工作台-个人中心-权益申请”页续约，或点此申请~',
          '温馨提示',
          {
            confirmButtonText: '去申请',
            customClass: 'apply_rights_confirm',
            callback: () => {
              router.push({ path: '/person/authApply' });
            },
          }
        );
      } else {
        // 设计圈
        let newTab = router.resolve({
          path: '/designCircleIframe',
        });
        window.open(newTab.href, '_blank');
        // next({ path: manageIndex() });
      }
      next(false);
      return;
    }
    // 未完成注册流程 - 不在星云内
    if (window.localStorage.getItem('isRegisterComplete') === '0' && !isIframe()) {
      if (to.path === '/' || whiteList.indexOf(to.path) === -1) {
        // 是否完成注册流程，0否，1是
        next({ path: '/zjsj/zjsjHome' });
        NProgress.done();
        return;
      }
    }

    // 跳转后台首页
    if (to.path === '/manage3d') {
      next({ path: manageIndex() });
      NProgress.done();
      return;
    }

    // 默认路由 - 访问有权限路由的第一个url
    if (to.path === '/' || to.path === '/zjsj') {
      next({ path: '/zjsj/zjsjHome' });
      NProgress.done();
    } else {
      if (window.localStorage.getItem('routers') && window.localStorage.getItem('userId')) {
        // 有权限菜单
        if (store.getters.access_routers && store.getters.access_routers.indexOf(to.path) === -1) {
          // 404
          next({ path: '/404' });
        } else {
          // // 按钮权限
          // if (getToken()) {
          //   let btnList = await getBtnList();
          //   let btn = btnList.data.map((b) => {
          //     return b.code;
          //   });
          //   window.localStorage.setItem('btnList', JSON.stringify(btn));
          // }

          // if (window.localStorage.getItem('storageAside') === '0') {
          //   let r = JSON.parse(window.localStorage.getItem('routers'));
          //   router.addRoutes(r);
          //   window.localStorage.setItem('storageAside', '1');
          // }

          next();
          NProgress.done();
        }
      } else {
        // 点击退出时,会定位到这里
        removeToken();
        next({ path: '/401' });
        NProgress.done();
      }
      next();
    }
    next();
  } else {
    if (whiteList.indexOf(to.path) > -1 || isIframeInNebula()) {
      next();
    } else {
      // 点击退出时,会定位到这里
      // 无token，且不在白名单内
      if (to.path === '/case/inventory' && to.query?.entrance === 'design') {
        // 从深化设计工具跳转过来，并且未登录
        Message.error('登录超时，请重新登录');
        store.dispatch('setCurrentComponent', { name: 'LOGIN' });
      }
      next('/zjsj/zjsjHome');
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done(); // 结束Progress
  setTimeout(() => {
    const browserHeaderTitle = store.getters.browserHeaderTitle;
    setTitle(browserHeaderTitle);
  }, 0);
});

// 案例发布
function jumpShdp() {
  let accessToken = window.localStorage.getItem('access_token').replace('Bearer ', '');
  accessToken = accessToken.replace('Bearer%20', '');
  // 设计师说跳转
  request({
    url: serverUrl + '/api/user/docking_user_center/' + window.localStorage.getItem('access_token'),
    method: 'get',
  }).then(() => {
    const env = process.env.VUE_APP_URL;
    if (env.indexOf('https://zjsj.haier.net') > -1 || env.indexOf('https://new3d.haier.net') > -1) {
      window.open(
        'https://zjrs.haier.net/shdp/index.html?accountToken=' +
          accessToken +
          '&multiportflag=9630AB0B-8D04-4AA9-A4E3-F9E449825386#/login/index'
      );
    } else {
      window.open(
        'https://ys-zjrs.haier.net/shdpNew/index.html?accountToken=' +
          accessToken +
          '&multiportflag=9630AB0B-8D04-4AA9-A4E3-F9E449825386#/login/index'
      );
    }
  });
}
function jumpHaierHome() {
  store.commit('setRouterToHomeDialog', true);
  NProgress.done();
}
