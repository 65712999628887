<template>
  <div
    class="select_designer_type"
    v-loading="fetchUserTypeLoading"
    element-loading-custom-class="syn-zjsj-front-loading">
    <el-radio-group v-model="designerType" @input="changeType">
      <div
        class="select_type_item"
        v-for="item in designerTypeList"
        :key="item.value"
        @click="handleDesignerTypeSelect(item.value)">
        <div class="img_container" :style="designerType === item.value ? item.style : { background: '#fafafa' }">
          <ZjsjImage
            :src="item.image"
            :customStyle="{
              ...customStyle,
              ...{ background: 'transparent' },
            }"></ZjsjImage>
        </div>
        <el-radio :label="item.value" @click.native="radioClick" :disabled="item.disabled">
          <span class="label_title">{{ item.label }}</span>
          <el-tooltip class="item" effect="dark" :content="item.tips" placement="top">
            <Icon icon="question"></Icon>
          </el-tooltip>
        </el-radio>
      </div>
    </el-radio-group>
    <div class="confirm_button">
      <ZjsjHomeButton
        :style="{ width: '360px', 'text-align': 'center' }"
        size="large"
        :disabled="!designerType"
        :loading="saveTypeLoading"
        @click="nextHandle">
        下一步
      </ZjsjHomeButton>
    </div>
  </div>
</template>
<script>
  import Icon from '@/components/ui/zjsjIcon/icon.vue';
  import storeDesignerImage from '@/assets/img/login/store_designer.png';

  import commDesignerImage from '@/assets/img/login/comm_designer.png';

  import haierDesignerImage from '@/assets/img/login/haier_designer.png';
  import { choseUserType } from '@/api/register';
  import { queryUserInfo } from '@/api/commApi';
  export default {
    name: 'designerTypeSelect',
    components: {
      Icon,
    },
    data() {
      return {
        fetchUserTypeLoading: false,
        saveTypeLoading: false,
        designerType: null,
        partnerType: null, // 1 - 店铺/店铺负责人; 2 - 店铺合伙人；3 - 共享设计师
        customStyle: {
          width: '110px',
          height: '150px',
        },
        designerTypeList: [
          {
            label: '店铺设计师',
            tips: '门店设计师',
            value: '1',
            image: storeDesignerImage,
            disabled: false,
            style: {
              background: 'rgba(242, 233, 253, 0.6)',
            },
          },
          {
            label: '共享设计师',
            tips: '与海尔无雇佣关系，外部的设计师',
            value: '3',
            image: commDesignerImage,
            disabled: false,
            style: {
              background: 'rgba(235,239,246, 0.6)',
            },
          },
          {
            label: '官方设计师',
            tips: '岗位为设计师的海尔员工',
            value: '0',
            image: haierDesignerImage,
            disabled: false,
            style: {
              background: 'rgba(247,241,238, 0.6)',
            },
          },
        ],
      };
    },
    computed: {
      isPartner() {
        // 1 2  店铺负责人 店铺合伙人  只能选择店铺设计师
        // 隐藏真实姓名、身份选择、加入门店
        return this.partnerType === '1' || this.partnerType === '2';
      },
    },
    watch: {
      isPartner: function (value) {
        if (value) {
          this.designerType = '1';
          this.designerTypeList.forEach((item) => {
            if (item.value !== 1) {
              item.disabled = true;
            }
          });
        }
      },
      designerType: function (val) {
        this.$emit('changeType', val);
      },
    },
    async mounted() {
      await this.queryUserInfo();
      this.partnerType = window.localStorage.getItem('partnerType'); // 1 - 店铺/店铺负责人; 2 - 店铺合伙人；3 - 共享设计师
      let ignoreGio = this.$store.state.LoginRegistreStore?.currentComponentQuery?.ignoreGio;
      if (!ignoreGio) {
        this.gioTrack('CZHT30170');
      }
    },
    methods: {
      gioTrack(id, payload) {
        if (payload) {
          gio('track', id, payload); // eslint-disable-line
        } else {
          gio('track', id); // eslint-disable-line
        }
      },
      async queryUserInfo() {
        this.fetchUserTypeLoading = true;
        await queryUserInfo()
          .then((res) => {
            if (res.status === '0') {
              this.designerType = res.data.userInfo.registerUserType;
              window.localStorage.setItem('registerUserType', res.data?.userInfo?.registerUserType || '');
            }
          })
          .finally(() => {
            this.fetchUserTypeLoading = false;
          });
      },
      handleDesignerTypeSelect(val) {
        if (this.isPartner) {
          this.$message.warning('店铺负责人或店铺合伙人，身份默认为店铺设计师，其他两个选项不可修改');
          return;
        }
        this.designerType = val;
      },
      changeType(val) {
        this.designerType = val;
      },
      radioClick() {
        // if (this.isPartner) {
        //   this.$message.warning(
        //     '店铺负责人或店铺合伙人，身份默认为店铺设计师，其他两个选项不可修改'
        //   );
        // }
      },
      /**
       * @description 下一步保存信息
       */
      nextHandle() {
        this.saveTypeLoading = true;
        choseUserType(this.designerType, 1)
          .then((res) => {
            if (res.status === '0') {
              this.$store.dispatch('setCurrentComponent', {
                name: 'COMPLETE_DESIGNER_INFO',
              });
              window.localStorage.setItem('userType', this.designerType || '');
            }
            if (res.status === '1020') {
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
          })
          .finally(() => {
            this.saveTypeLoading = false;
          });
      },
    },
  };
</script>
<style lang="less" scoped>
  @primaryColor: #be965a;

  .select_designer_type {
    padding: 0 157px 30px 157px;
    box-sizing: border-box;
    margin-top: 85px;
    height: 640px;
    /deep/ .el-radio-group {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 98px;
      .select_type_item {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .img_container {
          width: 182px;
          height: 231px;
          background-color: #000;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 46px;
        }
        .el-radio {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .el-radio__label {
            display: flex;
            align-items: center;
            padding-left: 8px !important;
          }
          .el-radio__input.is-checked {
            .el-radio__inner {
              &:hover {
                border-color: @primaryColor !important;
              }
              color: @primaryColor !important;
              border-color: @primaryColor;
              background-color: @primaryColor !important;
            }
          }
          .el-radio__input {
            .el-radio__inner {
              width: 16px !important;
              height: 16px !important;
              &::after {
                width: 8px !important;
                height: 8px !important;
                border: 3px solid #fff !important;
                background-color: @primaryColor;
              }
              &:hover {
                border-color: @primaryColor !important;
              }
            }
          }
          span.label_title {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 4px;
            font-size: 14px;
            color: #333333;
            text-align: left;
            line-height: 22px;
            font-weight: 400;
          }
        }
      }
      .select_type_item:nth-of-type(2) {
        margin: 0 40px;
      }
    }
    .confirm_button {
      text-align: center;
    }
  }
</style>
