var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"car"},[_c('el-carousel',{ref:"elCarousel",attrs:{"height":_vm.carData.height,"arrow":_vm.carData.arrow,"initial-index":_vm.index,"interval":_vm.carData.interval,"indicator-position":_vm.carData.indicator},on:{"change":_vm.changeCarousel}},_vm._l((_vm.imgList),function(item,index){return _c('el-carousel-item',{key:item.id,attrs:{"name":index + ''},nativeOn:{"click":function($event){return _vm.bannerClick.apply(null, arguments)}}},[(item.imgUrlType === '1')?[(!_vm.fadeIn)?_c('VideoCustomize',{ref:"video",refInFor:true,staticClass:"car-video",attrs:{"controls":false,"show-bg":false,"src":_vm.returnImg(item.fpath)}}):_vm._e()]:[(!_vm.fadeIn)?_c('div',{staticClass:"car-img",style:({
            background: 'url(' + _vm.returnImg(item.fpath) + ') 50% 50% no-repeat',
            'background-size': 'cover',
          })}):_vm._e()]],2)}),1),(_vm.fadeIn)?_c('div',_vm._l((_vm.imgList),function(item,i){return _c('div',{key:item.id,staticClass:"carousel_fade"},[(item.imgUrlType === '1')?[(_vm.fadeIn)?_c('VideoCustomize',{ref:"video",refInFor:true,class:['fade_wid', _vm.index === i ? 'fade_show' : 'fade_hide'],attrs:{"controls":false,"show-bg":false,"src":_vm.returnImg(item.fpath)}}):_vm._e()]:[_c('div',{class:['fade_wid', _vm.index === i ? 'fade_show' : 'fade_hide'],style:({
            background: 'url(' + _vm.returnImg(item.fpath) + ') 50% 50% no-repeat',
            'background-size': 'cover',
          })})]],2)}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }