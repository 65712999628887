<template>
  <i class="image-icon" :class="iconClass" :style="iconStyle" @click="handleIconClick">
    <slot name="number"></slot>
  </i>
</template>
<script>
  export default {
    name: 'ZjsjIcon',
    props: {
      icon: {
        type: String,
        required: true,
      },
      size: {
        type: [String, Number],
        default: '16px',
      },
      color: {
        type: String,
      },
    },
    data() {
      return {};
    },
    watch: {
      icon() {
        this.$forceUpdate();
      },
    },
    computed: {
      isElIcon() {
        let partten = /^el-icon/g;
        return partten.test(this.icon);
      },
      iconStyle: {
        get() {
          let styleStr = `height:${this.size};width:${this.size};`;
          if (!this.size) {
            return '';
          }
          if (!this.isElIcon) {
            let img = require(`@/assets/icon/${this.icon}.png`);
            styleStr += `background:url(${img}) 0 0 / contain no-repeat;`;
          }
          if (this.color) {
            styleStr += `color: ${this.color};`;
          }
          return styleStr;
        },
      },
      iconClass() {
        return this.isElIcon ? this.icon : `zjsj-icon ${this.icon}`;
      },
    },
    methods: {
      handleIconClick() {
        this.$emit('iconClick');
      },
    },
  };
</script>
<style lang="less">
  .zjsj-icon {
    display: inline-block;
    background-size: contain;
  }
</style>
