import designOperaLayout from '@/layout/designerOperateLayout.vue';
import { DesignerCompetitionRouter } from './designerCompetition.router.js';
export default [
  { path: '/zjsj', component: designOperaLayout, redirect: '', hidden: true },
  {
    path: '/zjsj',
    name: 'zjsj',
    component: designOperaLayout,
    meta: {
      title: '三翼鸟定制平台',
    },
    noDropdown: true,
    children: [
      {
        path: 'zjsjHome',
        name: 'zjsjHome',
        component: () => import('@v/zjsj/zjsjHome'),
        meta: {
          title: '三翼鸟定制平台',
        },
      },
      {
        path: 'allcaseTxt',
        name: 'allcaseTxtZjsj',
        component: () => import('@v/zjsj/allcaseTxt'),
        meta: {
          title: '设计灵感',
        },
      },
      {
        path: 'modeldownload',
        name: 'modeldownloadZjsj',
        component: () => import('@v/zjsj/modeldownload'),
        meta: {
          title: '素材下载',
        },
      },
      {
        path: 'modelDetail',
        name: 'modelDetailZjsj',
        component: () => import('@v/zjsj/modelDetail'),
        meta: {
          title: '模型详情',
        },
      },
      {
        path: 'cadDetail',
        name: 'cadDetailZjsj',
        component: () => import('@v/zjsj/cadDetail'),
        meta: {
          title: '图纸详情',
        },
      },
      {
        path: 'rendering',
        name: 'renderingZjsj',
        component: () => import('@v/zjsj/rendering'),
        meta: {
          title: '案例详情',
        },
      },
      {
        path: 'list',
        name: 'listZjsj',
        component: () => import('@v/zjsj/list'),
        meta: {
          title: '物料清单',
        },
      },
      {
        path: 'zjsjRealCase',
        name: 'zjsjRealCase',
        component: () => import('@v/zjsj/zjsjRealCase'),
        meta: {
          title: '真实案例',
        },
      },
      {
        path: 'zjsjRealCaseDetails',
        name: 'zjsjRealCaseDetails',
        component: () => import('@v/zjsj/zjsjRealCaseDetails'),
        meta: {
          title: '真实案例详情',
        },
      },
      {
        path: 'designerStar',
        name: 'designerStar',
        component: () => import('@v/zjsj/designerStar'),
        meta: {
          title: '明星设计师',
        },
      },
      {
        path: 'designerStarDetails',
        name: 'designerStarDetails',
        component: () => import('@v/zjsj/designerStarDetails'),
        meta: {
          title: '明星设计师详情',
        },
      },
      ...DesignerCompetitionRouter,
    ],
  },
];
