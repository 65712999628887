<template>
  <div class="base_login">
    <div class="left_login login_item">
      <!-- <img class="cover" src="../../assets/img/login/login_img.png" alt="" /> -->
      <div class="left_carousel" v-if="bannerList && bannerList.length > 0">
        <carousel-hand :imgList="bannerList" :carData="carData"></carousel-hand>
      </div>
      <ZjsjImage v-else :src="require('@/assets/img/login/login_img.png')" fit="fill"></ZjsjImage>
    </div>
    <div class="right_login login_item">
      <div class="right_login_content">
        <div class="title">设计师登录</div>
        <i class="el-icon-close" @click="handleClose"></i>
        <div class="select_login_type">
          <div
            :class="['select_login_type_item', currentLoginType === 'captcha' ? 'home_them_text_color' : '']"
            @click="changeLoginType('captcha')">
            <span class="type_name">验证码登录</span>
          </div>
          <div class="separator"></div>
          <div
            :class="['select_login_type_item', currentLoginType === 'password' ? 'home_them_text_color' : '']"
            @click="changeLoginType('password')">
            <span class="type_name">密码登录</span>
          </div>
        </div>
        <div class="login_component">
          <!-- 这里面区分是验证码登录 还是密码登录  封装两个基础组件 -->
          <loginType
            class="login_com"
            :tabsActive="currentLoginType === 'captcha' ? 1 : 2"
            @forgetPassword="handleForgetPassword"></loginType>
          <div class="yunying_login">
            <el-button type="text" size="medium" class="home_them_text_color" @click="handleOperateLogin">
              运营人员登录
            </el-button>
          </div>
          <div class="bottom_tips">
            <span>没有账号？</span>
            <el-button
              type="text"
              v-gio-track="{ id: 'CZHT30167' }"
              size="medium"
              class="home_them_text_color"
              @click="handleRegister">
              立即注册
            </el-button>
            <!-- <span>立即注册</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import iamInit from '@/utils/iam'; // 调用集团portal
  import loginType from './component/loginType';
  import carouselHand from '../zjsj/components/carousel.vue';
  import { serverUrl } from '@/utils/env';
  import request from '@/utils/axios';
  iamInit();
  export default {
    name: 'LoginBase',
    components: {
      loginType,
      carouselHand,
    },
    data() {
      return {
        currentLoginType: 'captcha',
        loginTypeList: [
          {
            label: '验证码登录',
            componentType: 'captcha',
          },
          {
            label: '密码登录',
            componentType: 'password',
          },
        ],
        bannerList: [],
        carData: {
          arrow: 'never',
          interval: 3000,
          height: '564px',
        },
      };
    },
    mounted() {
      this.getBannerData();
    },
    methods: {
      /**
       * @description 切换登录类型
       * @param {String} type 登录类型
       */
      changeLoginType(type) {
        this.currentLoginType = type;
      },
      /**
       * @description 切换Store
       * @param {String} payload 切换dialog的类型
       */
      baseStoreDispatch(payload) {
        this.$store.dispatch('setCurrentComponent', { name: payload });
      },
      /**
       * @description 忘记密码
       */
      handleForgetPassword() {
        this.baseStoreDispatch('FORGET_PASSWORD');
      },
      /**
       * @description 去注册账号
       */
      handleRegister() {
        this.baseStoreDispatch('REGISTER');
      },
      handleOperateLogin() {
        // portal方法调用
        window.__USERCENTER__.login({ invalidateToken: true });
      },
      handleClose() {
        this.baseStoreDispatch(null);
      },
      getBannerData() {
        request({
          url: serverUrl + '/newPageController/binners?inColumn=4', //  inColumn :1.官方首页轮播；2.工作台弹窗 ;3.明星轮播图 ;4.登录轮播图
          method: 'get',
        })
          .then((res) => {
            if (res.status === '0') {
              this.bannerList = (res.data || []).map((e) => {
                // inColumn	所属栏目： 1.官方首页轮播；2.工作台弹窗 ; 3.明星轮播图 ;4.登录轮播图
                // isEnable	是否可用
                if (e.inColumn === '4' && e.isEnable) {
                  return {
                    id: e.id,
                    fpath: e.imgUrl,
                    clickUrl: e.clickUrl,
                    type: e.type,
                    caseId: e.caseId,
                    menuUrl: e.menuUrl,
                    imgUrlType: e.imgUrlType,
                  };
                }
              });
            }
          })
          .finally(() => {});
      },
    },
  };
</script>
<style lang="less" scoped>
  @home-primary-color: #be965a;
  .base_login {
    display: flex;
    height: 564px;
    overflow: hidden;
    border-radius: 8px;
    .login_item {
      display: flex;
      height: 100%;
      overflow: hidden;
    }
    .left_login {
      width: 500px;
    }
    .left_carousel {
      width: 500px;
    }
    .right_login {
      position: relative;
      .right_login_content {
        display: flex;
        padding: 40px 40px 0;
        flex-direction: column;
        align-items: center;
      }
      .title {
        font-size: 28px;
        font-weight: 500;
        color: #333333;
        height: 40px;
        line-height: 40px;
        text-align: center;
      }
      .select_login_type {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 24px 0;
        .separator {
          width: 1px;
          height: 16px;
          background-color: #d8d8d8;
        }
        .select_login_type_item {
          cursor: pointer;
          margin: 0 10px;
          .type_name {
            font-size: 20px;
            line-height: 28px;
            font-weight: 500;
          }
        }
        .select_login_type_item.active {
          color: @home-primary-color;
        }
      }
      .el-icon-close {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 20px;
        cursor: pointer;
      }
      .el-button--medium {
        padding: 0;
      }
      .yunying_login {
        text-align: center;
        .el-button {
          font-size: 16px;
        }
      }
    }
    .bottom_tips {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 56px;
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
