const types = {
  // LOAD_ROUTES : 'LOAD_ROUTES',  //确定是否第一次加载route
  HANDLE_LEFT_MENU: 'HANDLE_LEFT_MENU', // 收缩左侧菜单
  INIT_LEFT_MENU: 'INIT_LEFT_MENU', // 初始化左侧菜单
  SET_LEFT_COLLAPSE: 'SET_LEFT_COLLAPSE', // 改变左边菜单的收缩宽度
  SET_SYS_NAME: 'SET_SYS_NAME', // 外部系统来源
};
const menu = {
  state: {
    minLeftMenuWidth: '35px',
    maxLeftMenuWidth: '220px',
    sidebar: {
      opened: true,
      width: '220px',
    },
    isCollapse: false,
    search: '',
    modelName: '',
    drawerDownload: false, // 下载中心抽屉
    routerToHomeDialog: false, // 单点跳转海尔家居选门店弹窗
  },
  getters: {
    sidebar: (state) => state.sidebar,
    isCollapse: (state) => state.isCollapse,
    search: (state) => state.search,
    modelName: (state) => state.modelName,
    drawerDownload: (state) => state.drawerDownload,
  },
  mutations: {
    [types.HANDLE_LEFT_MENU](state) {
      if (state.sidebar.opened) {
        //true
        state.sidebar.width = state.minLeftMenuWidth;
      } else {
        state.sidebar.width = state.maxLeftMenuWidth;
      }
      state.sidebar.opened = !state.sidebar.opened;
    },
    [types.INIT_LEFT_MENU](state) {
      console.log('sidebar', state.sidebar);
      // state.sidebar = state.sidebar;
    },
    [types.SET_LEFT_COLLAPSE](state) {
      state.isCollapse = !state.isCollapse;
    },
    setSearch(state, data) {
      state.search = data;
    },
    setName(state, data) {
      state.modelName = data;
    },
    setDrawer(state, data) {
      state.drawerDownload = data;
    },
    setRouterToHomeDialog(state, data) {
      state.routerToHomeDialog = data;
    },
    // [types.SET_SYS_NAME](state, payload) {},
  },
  actions: {
    handleLeftMenu: ({ commit }) => {
      commit(types.HANDLE_LEFT_MENU);
    },
    initLeftMenu: ({ commit }) => {
      commit(types.INIT_LEFT_MENU);
    },
    setLeftCollapse: ({ commit }) => {
      commit(types.SET_LEFT_COLLAPSE);
    },
    setSearch: ({ commit }, data) => {
      commit('setSearch', data);
    },
    setName: ({ commit }, data) => {
      commit('setName', data);
    },
    setDrawer: ({ commit }, data) => {
      commit('setDrawer', data);
    },
  },
};

export default menu;
