<template>
  <div class="designer_auth_step">
    <DialogHeader
      title="设计师认证"
      :show-ignore="showIgnore"
      :show-back="showBack"
      :ignoreLoading="ignoreLoading"
      :back-loading="backLoading"
      @handleBack="handleBack"
      @handleIgnore="handleIgnore"></DialogHeader>
    <div class="select_designer_type" v-if="currentStore === 'SELECT_DESIGNER_TYPE'">
      <DesignerTypeSelect @changeType="handleChangeType"></DesignerTypeSelect>
    </div>
    <div class="complete_designer_info" v-else-if="currentStore === 'COMPLETE_DESIGNER_INFO'">
      <DesignerInfoComplete @changeInfo="handleChangeInfo" ref="completeInstance"></DesignerInfoComplete>
    </div>
  </div>
</template>
<script>
  import DialogHeader from './component/dialogHeader.vue';
  import DesignerTypeSelect from './component/designerTypeSelect.vue';
  import DesignerInfoComplete from './component/designerInfoComplete.vue';
  import { choseUserType, registerDict, registerFillUserInfoTemp } from '@/api/register';
  import { queryUserInfo } from '@/api/commApi';
  import { identityTypeList } from '@/utils/resources';
  import { generatorParams } from './component/designerSubmit';

  export default {
    name: 'designerAuthStep',
    components: {
      DialogHeader,
      DesignerTypeSelect,
      DesignerInfoComplete,
    },
    data() {
      return {
        showIgnore: true,
        showBack: false,
        designerType: null,
        ignoreLoading: false,
        backLoading: false,
        designerInfo: {},
        functionList: [],
      };
    },
    computed: {
      currentStore() {
        return this.$store.state.LoginRegistreStore.currentComponent;
      },
    },
    watch: {
      '$store.state.LoginRegistreStore.currentComponent': function (value) {
        this.watchStore(value);
      },
    },
    mounted() {
      this.watchStore(this.currentStore);
    },
    methods: {
      watchStore(value) {
        if (value === 'COMPLETE_DESIGNER_INFO') {
          this.showBack = true;
          console.log('获取字典值,,,,');
          registerDict('all_label').then((res) => {
            if (res.status === '0') {
              let functionList = [];
              (res.data || []).forEach((item) => {
                if (item.type === 'fun_label') {
                  functionList.push(item);
                }
              });
              this.functionList = functionList;
            }
          });
        } else {
          this.showBack = false;
        }
      },
      handleChangeInfo(info) {
        this.designerInfo = info;
      },
      /**
       * @description 修改设计师类型
       * @param {String} type 设计师类型
       */
      handleChangeType(type) {
        this.designerType = type;
      },
      backHome() {
        this.$store.dispatch('setCurrentComponent', null);
        // this.$router.push({
        //   path: '/zjsj/zjsjHome',
        //   query: {
        //     needReload: Math.random().toString(),
        //   },
        // });
      },
      gioTrack(id) {
        let type = this.$store.state.LoginRegistreStore.selectDesignerType;
        gio('track', id, {roughly_classification_name: identityTypeList.find(item => item.value == type).label}); // eslint-disable-line

      },
      handleBack() {
        this.saveDesignerInfo(1);
      },
      completeIgnoreBackHome() {
        this.backHome();
        this.$store.dispatch('setCurrentComponent', {
          name: 'AUTH_ERROR',
          query: {
            title: '温馨提示',
            content: '后续您可点击右上角【头像】-【设计师认证】继续完成认证',
            confirmButtonText: '确认',
            confirmButtonStyle: { width: '112px', height: '44px' },
            onConfirm: () => {
              this.$store.dispatch('setCurrentComponent', null);
            },
          },
        });
      },
      handleIgnore() {
        if (this.currentStore === 'SELECT_DESIGNER_TYPE') {
          gio('track', 'CZHT30171'); // eslint-disable-line
          if (this.designerType) {
            this.ignoreLoading = true;
            choseUserType(this.designerType, 0)
              .then((res) => {
                if (res.status === '0') {
                  window.localStorage.setItem('userType', this.designerType || '');
                  this.backHome();
                }
                if (res.status === '1020') {
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                }
                this.queryUserInfoRequest();
              })
              .finally(() => {
                this.ignoreLoading = false;
              });
          } else {
            this.backHome();
          }
        } else {
          this.saveDesignerInfo(0);
        }
      },
      // 查询个人信息，更新authFlag，infoFlag
      queryUserInfoRequest() {
        queryUserInfo()
          .then((res) => {
            if (res.status === '0') {
              console.log('res.data', res.data);
              this.$store.dispatch('setAuthFlag', res.data.userInfo.authFlag);
              this.$store.dispatch('setInfoFlag', res.data.userInfo.infoFlag);
              window.localStorage.setItem('registerUserType', res.data?.userInfo?.registerUserType || '');
            }
          })
          .finally(() => {});
      },
      /**
       * @description 保存设计师已填信息
       * @param {Number}  type 0 跳过 1返回
       */
      saveDesignerInfo(type) {
        if (this.backLoading || this.ignoreLoading) {
          return;
        }
        this.backLoading = true;
        this.ignoreLoading = true;
        if (type === 0) {
          this.gioTrack('CZHT30173');
        }
        if (Object.keys(this.designerInfo)) {
          let params = { ...this.designerInfo };
          let completeInstance = this.$refs['completeInstance'];
          let submitParams = generatorParams(params, this.functionList, completeInstance);
          registerFillUserInfoTemp(submitParams)
            .then((res) => {
              console.log(res);
              window.localStorage.setItem('userType', submitParams?.type || '');
            })
            .finally(() => {
              this.backLoading = false;

              this.ignoreLoading = false;
              if (type === 0) {
                this.completeIgnoreBackHome();
              } else if (type === 1) {
                this.designerInfoBack();
              }
              this.queryUserInfoRequest();
            });
        } else {
          this.backLoading = false;
          this.ignoreLoading = false;
          if (type === 0) {
            this.completeIgnoreBackHome();
          } else if (type === 1) {
            this.designerInfoBack();
          }
        }
      },
      designerInfoBack() {
        this.$store.dispatch('setCurrentComponent', {
          name: 'SELECT_DESIGNER_TYPE',
          query: {
            ignoreGio: true,
          },
        });
        if (this.designerInfo.designerType) {
          this.$store.dispatch('setDesignerType', this.designerInfo.designerType);
        }
        this.gioTrack('CZHT30174');
      },
    },
  };
</script>
<style lang="less" scoped>
  @primaryColor: #be965a;
  .designer_auth_step {
    height: 640px;
    .complete_designer_info {
      height: calc(100% - 80px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
    }
  }
</style>
