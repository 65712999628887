/**
 * @deprecated 样板间投稿数据 事件传递
 */

export const SampleRoomSubmissionStore = {
  state: {
    submissionList: [],
    currentActivated: 1,
  },
  mutations: {
    SET_ACTIVE: (state, { data }) => {
      state.currentActivated = data;
    },

    SET_DATA_LIST: (state, { data, isClear }) => {
      if (isClear) {
        state.submissionList = [];
      } else {
        state.submissionList = duplicateCase([
          ...state.submissionList,
          ...data,
        ]);
      }
    },
  },
  actions: {
    setSubmissionAction: ({ commit }, { data: dataList, isClear }) => {
      commit('SET_DATA_LIST', { data: dataList, isClear });
    },
    setCurrentActive: ({ commit }, currentActived) => {
      commit('SET_ACTIVE', { data: currentActived });
    },
  },
};

/**
 *
 * @param { Array} arr 待去重的数据
 */
function duplicateCase(arr) {
  let mapId = [];
  let result = [];
  arr.forEach((item) => {
    let { id } = item;
    if (!mapId.includes(id)) {
      mapId.push(id);
      result.push(item);
    }
  });
  return result;
}
