import { Layout } from './layout';

export const ISMROUTER = {
  path: '/ism',
  component: Layout,
  redirect: '/ism/ismGoodsManager',
  meta: {
    title: '商品管理',
    icon: 'el-icon-notebook-2',
  },
  children: [
    {
      path: 'ismGoodsManager',
      meta: {
        title: '商品管理',
        routerType: 'leftmenu',
      },
      component: () => import('@/layout/ismMenuLayout.vue'),
    },
  ],
};
