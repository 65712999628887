import router from '@/router';
import { Base64 } from 'js-base64';
export const jumpHouseTour = (title, url) => {
  let newPage = router.resolve({
    path: '/HouseTourIframe',
    query: {
      title: title,
      url: Base64.encodeURL(url),
    },
  });
  window.open(newPage.href, '_blank');
};
