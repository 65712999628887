<template>
  <!-- 注册 - 门店 -->
  <div class="store_table">
    <el-table :data="retailStoreList" border size="small">
      <el-table-column
        prop="storeCode"
        label="店铺编码"
        align="center"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="storeName"
        label="店铺名称"
        align="center"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="mdCode"
        label="门店编码"
        align="center"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="mdName"
        label="门店名称"
        align="center"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="操作" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <ZjsjHomeButton
            type="text"
            @click="remove(scope.row)"
            :customStyle="{
              'font-size': '12px',
              color: '#BE965A',
              'text-align': 'justify',
              'font-weight': 400,
              background: 'transparent',
              'border-color': 'none',
            }"
            >移除</ZjsjHomeButton
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  export default {
    props: {
      storeInfo: {
        type: Array,
        default: () => [],
      },
    },
    components: {},
    watch: {
      storeInfo: {
        handler(val) {
          this.retailStoreList = val;
        },
        immediate: true,
        deep: true,
      },
    },
    data() {
      return {
        retailStoreList: [],
      };
    },
    mounted() {},
    methods: {
      remove(val) {
        this.retailStoreList = this.retailStoreList.filter(
          (e) => e.mdCode !== val.mdCode
        );
        this.$emit('storeChanges', this.retailStoreList);
      },
    },
  };
</script>

<style lang="less" scoped>
  .store_table {
    color: #666;
    font-weight: 500;
    /deep/ th {
      background-color: rgba(51, 51, 51, 0.04) !important;
      border: none;
    }
    /deep/ .el-checkbox.is-checked {
      .el-checkbox__inner {
        border-color: #be965a;
        background: #be965a;
      }
    }
    /deep/ .el-checkbox {
      .el-checkbox__input.is-indeterminate {
        .el-checkbox__inner {
          border-color: #be965a;
          background: #be965a;
        }
      }
      .el-checkbox__inner {
        &:hover {
          border-color: #be965a;
        }
      }
    }
  }
</style>
