import { buryingPoint, getRoleList, getUserInfo } from '@/api/commApi';

/**
 * 获取文件是图片还是压缩文件 1：图片 2：压缩文件
 */
export const getAttachmentType = (url) => {
  const imgArr = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG'];
  const zipArr = ['rar', 'RAR', 'zip', 'ZIP'];
  let type = url.substring(url.lastIndexOf('.') + 1);
  if (imgArr.filter((item) => item === type).length > 0) {
    return 1;
  }
  if (zipArr.filter((item) => item === type).length > 0) {
    return 2;
  }
};
/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== 'string') {
    content = JSON.stringify(content);
  }
  window.localStorage.setItem(name, content);
};

/**
 * 获取localStorage
 */
export const getStore = (name) => {
  if (!name) return;
  var value = window.localStorage.getItem(name);
  if (value !== null) {
    try {
      value = JSON.parse(value);
    } catch (e) {
      // value = value;
    }
  }
  return value;
};

/**
 * 删除localStorage
 */
export const removeStore = (name) => {
  if (!name) return;
  window.localStorage.removeItem(name);
};

/**
 * @param {date} 标准时间格式:Fri Nov 17 2017 09:26:23 GMT+0800 (中国标准时间)
 * @param {type} 类型
 *   type == 1 ---> "yyyy-mm-dd hh:MM:ss"
 *   type == 2 ---> "yyyy-mm-dd hh:MM"
 *   type == 3 ---> "yyyy-mm-dd"
 *   type == '' ---> "yyyy-mm-dd hh:MM:ss"
 */
export const formatDates = (data, type) => {
  var date = new Date(data);
  var year = date.getFullYear(); //年
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1; //月
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(); //日
  var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); //时
  var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes(); //分
  var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds(); //秒
  if (type == 1) {
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
  } else if (type == 2) {
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes;
  } else if (type == 3) {
    return year + '-' + month + '-' + day;
  } else {
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
  }
};
/**
 * 时间转换：20150101010101 --> '2015-01-01 01:01:01'
 */
export const parseToDate = (timeValue) => {
  var result = '';
  var year = timeValue.substr(0, 4);
  var month = timeValue.substr(4, 2);
  var date = timeValue.substr(6, 2);
  var hour = timeValue.substr(8, 2);
  var minute = timeValue.substr(10, 2);
  var second = timeValue.substr(12, 2);
  result = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second;
  return result;
};
/**
 * 判断空值
 */
export const isEmpty = (keys) => {
  if (typeof keys === 'string') {
    keys = keys.replace(/"|&nbsp;|\\/g, '').replace(/(^\s*)|(\s*$)/g, '');
    if (keys == '' || keys == null || keys == 'null' || keys === 'undefined') {
      return true;
    } else {
      return false;
    }
  } else if (typeof keys === 'undefined') {
    // 未定义
    return true;
  } else if (typeof keys === 'number') {
    return false;
  } else if (typeof keys === 'boolean') {
    return false;
  } else if (typeof keys == 'object') {
    if (JSON.stringify(keys) == '{}') {
      return true;
    } else if (keys == null) {
      // null
      return true;
    } else {
      return false;
    }
  }

  if (keys instanceof Array && keys.length == 0) {
    // 数组
    return true;
  }
};

/**
 * 返回两位的小数的字符串
 */
export const toFixedNum = (num) => {
  const tonum = Number(num).toFixed(2);
  return tonum;
};

/**
 * 读取base64
 */
export const readFile = (file) => {
  console.log(file);
  //var file = this.files[0];
  //判断是否是图片类型
  if (!/image\/\w+/.test(file.raw.type)) {
    alert('只能选择图片');
    return false;
  }
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function (e) {
    alert(e.target.result);
  };
};

/**
 * 动态插入css
 */
export const loadStyle = (url) => {
  const link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = url;
  const head = document.getElementsByTagName('head')[0];
  head.appendChild(link);
};
/**
 * 设置浏览器头部标题
 */
export const setTitle = (title) => {
  title = title ? `${title}` : '设计工具';
  window.document.title = title;
};

export const param2Obj = (url) => {
  const search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
  );
};

//是否为手机号
export const isMobile = (phone) => {
  let reg = /^1[3456789]\d{9}$/;
  return reg.test(phone);
};

//是否为邮箱
export const isEmail = (mail) => {
  let reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(mail);
};

// 邮箱脱敏
export const emailDes = (string, str) => {
  var str_before = string.split(str)[0];
  var str_after = string.split(str)[1];

  if (str_before.length >= 6) {
    // 大于等于6位，后四位脱敏
    return str_before.substring(0, str_before.length - 4) + '****@' + str_after;
  } else if (str_before.length >= 3) {
    // 大于等于3位，小于6位，后2位脱敏
    return str_before.substring(0, str_before.length - 2) + '**@' + str_after;
  }

  return string;
};

// 手机号码脱敏
export const phoneDes = (num) => {
  const pho = /(\d{3})\d*(\d{4})/;
  return num.replace(pho, '$1****$2');
};

//姓名脱敏
export const nameDes = (name) => {
  let list = name.split('');
  return hideStr(name, 0, list.length - 2, '*');
};
/**
 * 字符串替换，数据脱敏
 * @param {str} 被处理的字符串
 * @param {start} 开始下标
 * @param {end} 结束下标
 * @param {gapStr} 替换字符
 */
export const hideStr = (str, start, end, gapStr) => {
  let idList = str.split('');
  for (let i = 0; i < idList.length; i++) {
    if (i >= start && i <= end) {
      idList[i] = gapStr;
    }
  }
  return idList.join('');
};
// 校验不能为空格或特殊符号的正则
export const normalWordRegExp =
  /[`~#$%^&*@()/+_+=<>{}|~#￥%……&*@（）={}|《》【】\s+]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g;

export const randomId = () => {
  return Math.random().toString(36).substring(8, 16);
};
export function randomString(len = '32') {
  var $chars = 'ABCDEFGHJKMNOPQRSTWXYZabcdefhijkmnopqrstwxyz1234567890';
  var maxPos = $chars.length;
  var pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}
// url是否包含mgr
export const isMgr = () => {
  const url = window.location.href;
  const str = url.includes('?') ? url.split('?')[0] : window.location.href;
  return str.includes('/mgr/');
};

// 判断是否在iframe中
export const isIframe = () => {
  return window.top !== window.self;
};

// 地址栏取参
export const GetQueryString = (name, url) => {
  url = url || window.location.href;
  let reg = new RegExp('[?&]' + name + '=([^&]+)', 'g');

  let urlData = reg.exec(url);
  if (urlData != null) return urlData[1];
  return null;
};

/**
 * @description 把图片的汉字url进行编码
 * @param {String} url 图片URL
 * @returns
 */
export const returnImg = (url) => {
  if (!url) {
    return null;
  }
  let arr = url.split('/');
  let reg = /[\u4e00-\u9fa5]/g;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].match(reg)) {
      let str = encodeURIComponent(arr[i]);
      arr[i] = str;
    }
  }
  let imgUrl = arr.join('/');
  return imgUrl;
};

export const buryingPointConfirm = async (pageId, pointId) => {
  let roleList = await getRoleList();
  getUserInfo().then((res) => {
    if (res.status === '0') {
      let storeInfo = res.data.user.retailStore;
      let storeList = [];
      if (storeInfo) {
        storeInfo = JSON.parse(storeInfo);
        if (storeInfo.length > 0) {
          storeInfo.forEach((s) => {
            storeList.push({ shopCode: s.code, shopName: s.value });
          });
        }
      }
      let designerType = res.data.user.type;
      let designerName = '';
      roleList.data.forEach((r) => {
        if (res.data.user.type === r.code) {
          designerName = r.name;
        }
      });
      const data = {
        appId: 'ZJSJ',
        pageId: pageId,
        pointLocation: 'BTN',
        pointId: pointId,
        pointMsg: JSON.stringify({
          userId: window.localStorage.getItem('userId'),
          storeList: storeList,
          designerType: designerType,
          designerName: designerName,
        }),
        userId: window.localStorage.getItem('uacUserId'),
      };
      buryingPoint(data);
    }
  });
};

/**
 * @description 把对象转化为参数拼接方式
 * @param {Object} params 所需参数
 * @returns String
 */
export const params2Query = (params) => {
  if (!params || typeof params !== 'object' || Array.isArray(params)) {
    return ''; // 空对象或非对象直接返回空字符串
  }
  const keys = Object.keys(params);
  if (keys.length === 0) {
    return ''; // 空对象直接返回空字符串
  }
  const queryArr = [];
  for (const key of keys) {
    const value = params[key];
    // if (value === undefined || value === null || typeof value === 'function') {
    //   continue; // 忽略值为 undefined、null 或 function 的键值对
    // }
    const encodedKey = encodeURIComponent(key);
    if (typeof value === 'string') {
      const encodedValue = encodeURIComponent(value);
      queryArr.push(`${encodedKey}=${encodedValue}`);
    } else {
      // 非字符串类型需要转成字符串再编码
      const encodedValue = encodeURIComponent(String(value));
      queryArr.push(`${encodedKey}=${encodedValue}`);
    }
  }
  return queryArr.join('&');
};

export default {
  formatDates,
  randomId,
  params2Query,
};
