<template>
  <div class="menu_page el-scrollbar" ref="menu_page" :style="{ width: sidebar.width }">
    <el-menu
      mode="vertical"
      theme="dark"
      :show-timeout="200"
      :default-active="getActiveMenuItem($route.path)"
      :collapse="isCollapse"
      :background-color="menuObj.bgColor"
      :text-color="menuObj.textColor"
      :active-text-color="menuObj.activeTextColor"
      :style="{ width: sidebar.width }"
      @select="handleMenuSelect">
      <template v-for="item in routers">
        <!--表示 有一级菜单-->
        <router-link v-if="!item.children && item.menuType !== 2" :to="routerLinkComputed(item)" :key="item.id">
          <el-menu-item class="dropItem icon-container" :index="item.id">
            <i style="font-size: 18px; margin-right: 5px" v-if="item.icon" :class="'fa fa-margin ' + item.icon"></i>
            <span v-if="item.name" slot="title">{{ item.name }}</span>
          </el-menu-item>
        </router-link>
        <a :key="item.url" :href="item.url" v-else-if="!item.children && item.menuType === 2" target="_blank">
          <el-menu-item class="dropItem icon-container" :index="item.id">
            <i style="font-size: 18px; margin-right: 5px" v-if="item.icon" :class="'fa fa-margin ' + item.icon"></i>
            <span v-if="item.name" slot="title">{{ item.name }}</span>
          </el-menu-item>
        </a>
        <!--表示 有二级或者多级菜单 -->
        <el-submenu
          v-if="item.children && item.children.length >= 1 && !item.noDropdown"
          :index="item.id"
          :key="item.id">
          <template slot="title">
            <i style="font-size: 18px; margin-right: 5px" v-if="item.icon" :class="'fa fa-margin ' + item.icon"></i>
            <span v-if="item.name" slot="title">{{ item.name }}</span>
          </template>
          <!--直接定位到子路由上，子路由也可以实现导航功能-->
          <a :href="item.url" v-if="item.menuType === 2" target="_blank">
            <el-menu-item :index="citem.id" v-if="citem.name" class="icon-container">
              <div slot="title" style="padding-left: 20px !important; position: relative">
                {{ citem.name }}
                <div v-if="citem.status" class="title_status"></div>
              </div>
            </el-menu-item>
          </a>
          <template v-else>
            <router-link v-for="citem in item.children" :to="routerLinkComputed(citem)" :key="citem.id">
              <el-menu-item :index="citem.id" v-if="citem.name" class="icon-container">
                <div slot="title" style="padding-left: 20px !important; position: relative">
                  {{ citem.name }}
                  <div v-if="citem.status" class="title_status"></div>
                </div>
              </el-menu-item>
            </router-link>
          </template>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  // import * as mUtils from '@/utils/mUtils';
  import { EventBus, setFixedHeight } from '@/utils/event-bus';
  import { WINDOW_RESIZE } from '@/utils/const';
  import store from '@/store/';
  export default {
    name: 'left-Menu',
    data() {
      return {
        menuObj: {
          bgColor: '#fff',
          textColor: '#333333',
          activeTextColor: '#2878FF',
        },
        setHeight: () => null,
        userTypeMap: {
          0: '官方设计师',
          1: '店铺设计师',
          3: '共享设计师',
        },
      };
    },
    computed: {
      ...mapGetters(['routers', 'isCollapse', 'sidebar', 'activeMenuId']),
    },
    created() {},
    mounted() {
      this.setHeight = setFixedHeight(this, this.$refs.menu_page, 60);
      this.setHeight();
      EventBus.$on(WINDOW_RESIZE, this.setHeight);
    },
    beforeDestroy() {
      EventBus.$off(WINDOW_RESIZE, this.setHeight);
    },
    methods: {
      routerLinkComputed(item) {
        let type = item.menuType;
        if (type === 1) {
          // iframe链接
          return {
            path: item.path,
            query: { id: item.id, t: new Date().getTime() },
          };
        } else {
          return { path: item.path };
        }
      },
      setMenuHeight() {
        this.$nextTick(() => {
          this.$refs.menu_page.style.height = document.body.clientHeight - 60 + 'px';
        });
      },
      /**
       * 获取当前选中菜单
       * @param path
       */
      getActiveMenuItem(path) {
        // 从sessionStorage初始化activeMenuId
        if (!this.activeMenuId) {
          const activeMenuId = window.sessionStorage.getItem('active-menu-id');
          if (activeMenuId) {
            store.dispatch('SetActiveMenuId', activeMenuId);
          }
        }
        const routers = this.routers;
        // 查找当前选中菜单的id
        routers.find((item) => {
          if (item.path === path) {
            store.dispatch('SetActiveMenuId', item.id);
            return true;
          }
          if (Array.isArray(item.children)) {
            const t = item.children.find((citem) => {
              if (citem.path === path) {
                store.dispatch('SetActiveMenuId', citem.id);
                return true;
              }
            });
            return t != null;
          }
        });
        return this.activeMenuId;
      },
      handleMenuSelect(index, indexPath) {
        if (Array.isArray(indexPath) && indexPath.length > 0) {
          let menu = this.routers.find((value) => indexPath[0] === value.id);
          if (menu.path === '/designCircleIframe') {
            let userType = window.localStorage.getItem('userType');
            // eslint-disable-next-line no-undef
            gio('track', 'CZHT30370', {
              user_type: this.userTypeMap[userType],
            });
          }
        }
        if (!indexPath || !Array.isArray(indexPath) || indexPath.length < 2) {
          return;
        }
        if (!this.routers || !Array.isArray(this.routers)) {
          return;
        }
        //一级菜单
        const firstMenuId = indexPath[0];
        const firstMenu = this.routers.find((value) => firstMenuId === value.id);
        if (!firstMenu || !firstMenu.children || !Array.isArray(firstMenu.children)) {
          return;
        }
        //二级菜单
        const secondMenuId = indexPath[1];
        const secondMenu = firstMenu.children.find((value) => secondMenuId === value.id);
        if ('门店资源池' === secondMenu.name) {
          /* eslint-disable */
          gio('track', 'CZHT30238');
        } else if ('我的门店' === secondMenu.name) {
          /* eslint-disable */
          gio('track', 'CZHT30242');
        }
      },
    },
  };
</script>

<style src="@/style/menuIcon.less" lang="less" scoped></style>
<style lang="less" scoped>
  @left-bgColor: #fff; // 左侧菜单背景颜色;
  @icon-link: #ff6c60;
  .medium-weight {
    opacity: 1;
    font-family: PingFangSC-Medium !important;
    font-weight: 500 !important;
  }
  .regular-weight {
    opacity: 0.7;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #333333;
  }
  .active-menu {
    color: #2878ff !important;
    background-color: rgba(40, 120, 255, 0.1) !important;
    .medium-weight();
    i {
      color: #2878ff !important;
    }
  }
  .hover-menu {
    opacity: 1;
    color: #2878ff !important;
    background-color: #ffffff !important;
    .medium-weight();
    i {
      color: #2878ff !important;
    }
  }
  .standard-menu-size {
    margin: 10px 0;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .menu_page {
    flex: auto;
    height: auto;
    background-color: @left-bgColor;
    overflow-y: scroll;
    border-right: 1px solid rgba(238, 238, 238, 1);
    width: 200px !important;
    flex-shrink: 0;
  }
  .fa-margin {
    margin-right: 5px;
  }
  .router-link-active {
    li {
      .el-submenu__title {
        color: @icon-link !important;
      }
    }
  }
  .title_status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
    position: absolute;
    right: 45px;
    top: 20px;
  }
  .menu_page .el-menu {
    width: 100% !important;
    /deep/.el-menu--inline {
      margin: -10px 0;
      .el-menu-item {
        .regular-weight();
      }
    }
    /deep/.el-menu-item {
      .standard-menu-size();
      &:hover {
        .hover-menu();
      }
    }
    /deep/.el-submenu__icon-arrow {
      font-size: 16px;
    }
    /deep/.el-submenu__title {
      .standard-menu-size();
      i {
        color: #333333;
      }
      &:hover {
        .hover-menu();
      }
    }
    // 一级标题样式
    /deep/.el-submenu {
      .el-submenu__title {
        span {
          .medium-weight();
        }
      }
      // 被选中时
      &.is-active {
        .el-submenu__title {
          color: #2878ff !important;
          i {
            color: #2878ff !important;
          }
        }
      }
    }
    /deep/.el-menu-item {
      // 选中的一级标题或者二级标题字体加粗
      div,
      span {
        .medium-weight();
      }
      &.is-active {
        .medium-weight();
        .active-menu();
      }
    }
  }
</style>
