<template>
  <div class="cascader_box">
    <el-cascader
      ref="cascaderTree"
      class="cascader_stl"
      :options="areaData"
      v-model="selectedCodeList"
      :show-all-levels="true"
      collapse-tags
      size="medium"
      clearable
      placeholder="请选择省市区"
      @change="areaChange"
      :props="{
        multiple: multiple,
        checkStrictly: false,
        value: 'region_code',
        label: 'region_name',
        expandTrigger: 'hover',
      }"
      :filterable="isSupportSearch"
      :popper-class="selectAreaPopperClass"></el-cascader>
  </div>
</template>

<script>
  import { takeDifferentSet, takeDifferentArr } from '@/utils/tools';

  export default {
    props: {
      areaData: {
        type: Array,
        default: () => [],
      },
      selectedArea: {
        type: Array,
        default: () => [],
      },
      multi: {
        type: String,
        default: '0',
      },
      isBackend: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: true,
      },
      isSupportSearch: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      selectedArea: {
        handler(val) {
          this.selectedCodeList = val;
          this.copyData = val;
        },
        immediate: true,
      },
    },
    data() {
      return {
        selectedCodeList: [],
        copyData: [],
      };
    },
    computed: {
      selectAreaPopperClass() {
        let obj = {
          0: `second_third_multi_selectall ${this.isBackend ? 'normal' : 'multi-designerManage-search-hide-checkbox'}`, // 官方 2 3级可选全部
          1: `third_multi_selectall ${this.isBackend ? 'normal' : 'multi-designerManage-search-hide-checkbox'}`, // 店铺 第三级可选全部
          3: `all_select ${this.isBackend ? 'normal' : 'multi-designerManage-search-hide-checkbox'}`, // 共享 省市区均可多选
        };
        return obj[this.multi];
      },
    },
    mounted() {},
    methods: {
      // 区域选择
      areaChange(e) {
        if (this.multiple) {
          if (this.selectedCodeList.length === 0) {
            this.$refs.cascaderTree.$refs.panel.clearCheckedNodes();
            this.$refs.cascaderTree.$refs.panel.activePath = [];
            this.selectedCodeList = e;
            this.$emit('changeAreaCode', {
              codeArr: [],
              provinceCode: '',
              provinceName: '',
              city: [],
              serviceArea: [],
            });
          } else {
            if (this.multi === '0') {
              // 跨省选择时，只保留最新一次选择的数据
              const isMulti = this.multi ? 0 : 1; // 省/市
              const newAdd = takeDifferentArr(e, this.copyData, isMulti); // 取本地列表与当前级联选中列表的第一级差集
              if (newAdd.length > 0) {
                this.selectedCodeList = this.selectedCodeList.map((item) => {
                  if (item[isMulti] === newAdd[0]) {
                    return item;
                  }
                });
              }
              // 获取当前最后选中的数据
              const lastCodeArr = takeDifferentSet(this.copyData, e)[0]; // 取本地列表与当前级联选中列表的差集
              // 数组中的第几位 [省，市，区]
              const idx = this.multi ? 0 : 1; // 省/市
              // 比对最后一次选中的数据中的(省/市)Code与之前的是否一致，只保留一致的数据
              const lastChoose = e.filter((item) => item[idx] === lastCodeArr[idx]);
              const provinceCode = lastChoose && lastChoose[0] ? lastChoose[0][0] : '';

              // 获取当前省数据
              const proData = this.areaData.filter((e) => e.region_code === provinceCode);
              // 省 - 取选择的数组的第一位

              // 匹配省code的name值
              const provinceName = proData && proData[0] ? proData[0].region_name : '';
              // 市code - 数组的第二位
              let city = [];
              let cityArea = []; // 当前已选择市的数据集合
              let cityCode = lastChoose.map((e) => {
                return e[1];
              });
              // 去重
              cityCode = [...new Set(cityCode)];
              // city赋值
              let arr = proData && proData[0] && proData[0].children ? proData[0].children : [];
              arr.forEach((e1) => {
                // 当前省包含的所有城市
                cityCode.forEach((e2) => {
                  // 当前已选择的城市code
                  if (e1.region_code === e2) {
                    city.push({
                      code: e1.region_code,
                      value: e1.region_name,
                    });
                    cityArea.push(e1);
                  }
                });
              });
              // 区code - 数组的第三位
              let serviceArea = [];
              let areaChoose = []; // 当前已选择区的集合
              let areaCode = lastChoose.map((e) => {
                return e[2];
              });
              // 去重
              areaCode = [...new Set(areaCode)];
              // 区筛选赋值
              const areaArr = cityArea.filter((e) => cityCode.includes(e.region_code));
              areaArr.forEach((e1) => {
                areaChoose = e1.children.filter((e2) => areaCode.includes(e2.region_code));
                areaChoose.forEach((e3) => {
                  serviceArea.push({
                    code: e3.region_code,
                    value: e3.region_name,
                    cityCode: e1.region_code,
                    cityValue: e1.region_name,
                    provinceCode: provinceCode,
                    provinceValue: provinceName,
                  });
                });
              });
              this.$emit('changeAreaCode', {
                codeArr: lastChoose,
                provinceCode: provinceCode,
                provinceName: provinceName,
                city: city,
                serviceArea: serviceArea,
                province: [{ code: provinceCode, value: provinceName }],
              });
            } else if (this.multi === '1') {
              const newAdd = takeDifferentArr(e, this.copyData, 0); // 取本地列表与当前级联选中列表的第一级差集
              const cityAdd = takeDifferentArr(e, this.copyData, 1);
              if (newAdd.length > 0 || cityAdd.length) {
                this.selectedCodeList = this.selectedCodeList
                  .map((item) => {
                    if ((newAdd[0] ? item[0] === newAdd[0] : true) && (cityAdd[0] ? item[1] === cityAdd[0] : true)) {
                      return item;
                    }
                  })
                  .filter((item) => item);
              }
              // 获取当前最后选中的数据
              const lastCodeArr = takeDifferentSet(this.copyData, e)[0]; // 取本地列表与当前级联选中列表的差集
              // 数组中的第几位 [省，市，区]
              const idx = this.multi ? 0 : 1; // 省/市
              // 比对最后一次选中的数据中的(省/市)Code与之前的是否一致，只保留一致的数据
              const lastChoose = e.filter((item) => item[idx] === lastCodeArr[idx]);

              // 省 - 取选择的数组的第一位
              const provinceCode = lastChoose && lastChoose[0] ? lastChoose[0][0] : '';
              const provinceInfo = this.areaData.filter((e) => e.region_code === provinceCode);
              const provinceName = provinceInfo && provinceInfo[0] ? provinceInfo[0].region_name : '';
              let city = this.getCity(this.selectedCodeList);
              let serviceArea = this.getTown(this.selectedCodeList);
              this.$emit('changeAreaCode', {
                codeArr: [...this.selectedCodeList],
                provinceCode: provinceCode,
                provinceName: provinceName,
                city: city,
                serviceArea: serviceArea,
                province: [{ code: provinceCode, value: provinceName }],
              });
            } else if (this.multi === '3') {
              let city = this.getCity(this.selectedCodeList, true);
              let serviceArea = this.getTown(this.selectedCodeList);
              let provinces = this.selectedCodeList.map((item) => item[0]);
              let sigglePro = [...new Set(provinces)];
              let provinceData = [];
              sigglePro.forEach((item) => {
                let findV = this.areaData.find((a) => a.region_code === item);
                if (findV) {
                  provinceData.push({
                    code: item,
                    value: findV.region_name,
                  });
                }
              });
              this.$emit('changeAreaCode', {
                codeArr: [...this.selectedCodeList],
                provinceCode: '',
                provinceName: '',
                city: city,
                serviceArea: serviceArea,
                province: provinceData,
              });
            }
          }
          setTimeout(() => {
            this.copyData = [];
            this.selectedCodeList.forEach((item) => {
              if (item) {
                this.copyData.push(item);
              }
            });
          }, 0);
        } else {
          this.$emit('changeAreaCode', e);
        }
      },
      /**
       * @description 获取市的名称和编码
       * @param {Array} selectData 选择的省市区数据
       */
      getCity(selectData, multi) {
        let allCity = [];
        if (!(selectData && selectData.length)) {
          return allCity;
        }
        if (multi) {
          let city = {};
          selectData.forEach((seleItem) => {
            let proviece = this.areaData.find((pro) => pro.region_code === seleItem[0]);
            let arr = proviece && proviece.children ? proviece.children : [];
            arr.forEach((item) => {
              if (item.region_code === seleItem[1] && !city[item.region_code]) {
                city[item.region_code] = {
                  code: item.region_code,
                  value: item.region_name,
                };
              }
            });
          });
          allCity = Object.values(city);
        } else {
          let allCityCode = selectData.map((item) => item[1]);
          allCityCode = [...new Set(allCityCode)];
          let provienceData = this.areaData.find((prov) => prov.region_code === selectData[0][0]);
          let cityArr = provienceData.children || [];
          allCityCode.forEach((code) => {
            let city = cityArr.find((item) => item.region_code === code);
            if (city) {
              allCity.push({
                value: city.region_name,
                code: city.region_code,
              });
            }
          });
        }

        return allCity;
      },
      /**
       * @description 获取市和区的编码和名称
       * @param {Array} selectData 选择的省市区数据
       * @return  {cityCode:"360600",cityValue:"鹰潭",code:"360602",value:"月湖区"}
       */
      getTown(selectData) {
        let allTownData = [];
        selectData.forEach((selectItem) => {
          let [province, city, town] = selectItem;
          let findprovince = this.areaData.find((item) => item.region_code === province);
          let provienceData = findprovince.children || [];

          let findcity = provienceData.find((item) => item.region_code === city);
          let findCityData = findcity.children || [];

          let findtown = findCityData.find((item) => item.region_code === town);
          allTownData.push({
            cityCode: findcity.region_code,
            cityValue: findcity.region_name,
            code: findtown.region_code,
            value: findtown.region_name,
            provinceCode: findprovince.region_code,
            provinceValue: findprovince.region_name,
          });
        });
        return allTownData;
      },
    },
  };
</script>

<style lang="less" scoped>
  .cascader_box {
    .cascader_stl {
      width: 100%;
      height: 36px;
    }
  }
</style>
<style lang="less">
  .radius {
    .el-cascader-panel {
      .el-cascader-menu {
        .el-cascader-menu__wrap {
          .el-cascader-menu__list {
            .el-cascader-node {
              &:hover {
                background: rgba(190, 150, 90, 0.1);
              }
            }
          }
        }
      }
    }
  }
  .second_third_multi_selectall {
    border-radius: 8px !important;
    .el-cascader-menu__wrap {
      height: 260px;
    }
    .radius();
    // .el-cascader-menu:nth-child(1),
    // .el-cascader-menu:nth-child(2) {
    //   .el-cascader-node {
    //     .el-checkbox {
    //       display: none;
    //       opacity: 0;
    //       width: 0;
    //       height: 0;
    //     }
    //   }
    // }
  }

  .third_multi_selectall {
    .el-cascader-menu__wrap {
      height: 260px;
    }

    .el-cascader-menu:nth-child(1) {
      .el-cascader-node {
        .el-checkbox {
          display: none;
          opacity: 0;
          width: 0;
          height: 0;
        }
      }
    }
  }
  .multi-designerManage-search-hide-checkbox {
    .radius();
    border-radius: 8px !important;
    .el-cascader__suggestion-panel {
      .el-scrollbar__wrap {
        .el-cascader__suggestion-list {
          .el-cascader__suggestion-item {
            &:hover {
              background: rgba(190, 150, 90, 0.1);
            }
            &.is-checked {
              color: #be965a;
            }
          }
        }
      }
    }
    .el-cascader-menu {
      .el-checkbox {
        .el-checkbox__inner {
          &:hover {
            border-color: #be965a;
          }
          &:focus {
            border-color: #be965a;
          }
        }
        .el-checkbox__input.is-focus {
          .el-checkbox__inner {
            border-color: #be965a;
          }
        }
      }

      .el-cascader-node.in-active-path,
      .el-cascader-node.is-active {
        color: #be965a;
        .el-checkbox {
          .el-cascader-node__label {
            color: #be965a;
          }
          &:hover {
            border-color: #be965a;
          }
          &:focus {
            border-color: #be965a;
          }
        }
      }
      .el-checkbox.is-checked {
        .el-checkbox__inner {
          background-color: #be965a;
          border-color: #be965a;
        }
      }
      .el-checkbox__input.is-indeterminate {
        .el-checkbox__inner {
          background-color: #be965a;
          border-color: #be965a;
        }
      }
    }
  }
</style>
