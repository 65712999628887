// 获取元素
import Vue from 'vue';

function getInput(el) {
  let inputEle;
  if (el.tagName !== 'INPUT') {
    // 若 el-input 中 type 为 textarea
    if (el._prevClass.includes('el-textarea')) {
      inputEle = el.querySelector('textarea');
    } else {
      inputEle = el.querySelector('input');
    }
  } else {
    inputEle = el;
  }
  return inputEle;
}

// 创建自定义事件
function dispatchEvent(el, type) {
  const evt = new Event(type);
  el.dispatchEvent(evt);
}

const Trim = {
  inserted: (el) => {
    let inputEle = getInput(el);
    const handler = function (event) {
      const newVal = event.target.value.trim();
      if (event.target.value !== newVal) {
        event.target.value = newVal;
        dispatchEvent(inputEle, 'input');
      }
    };
    el.inputEle = inputEle;
    el._blurHandler = handler;
    // 事件监听
    inputEle.addEventListener('blur', handler);
  },
  unbind(el) {
    const { inputEle } = el;
    inputEle.removeEventListener('blur', el._blurHandler);
  },
};
Vue.directive('trim', Trim);
