<template>
  <div class="forget_pwd">
    <!-- 头部 -->
    <!-- <div class="forget_header">
      <div class="header_title">重置密码</div>\
    </div> -->
    <DialogHeader title="重置密码" show-back @handleBack="handleBack">
    </DialogHeader>
    <i class="el-icon-close" @click="handleClose"></i>
    <!-- content -->
    <div class="forget_content">
      <el-form
        ref="phoneForm"
        :model="forgetFormPhone"
        :rules="rulesPhone"
        size="large"
        label-width="100px"
        @validate="handleValidateEvent">
        <el-form-item prop="phone" label="手机号">
          <el-input
            type="text"
            :maxlength="11"
            placeholder="请输入手机号码"
            v-model="forgetFormPhone.phone"></el-input>
        </el-form-item>
        <el-form-item
          prop="imgCode"
          label="图片验证码"
          class="img_code_container">
          <el-input
            class="img_inp"
            type="text"
            placeholder="请输入图片验证码"
            v-model="forgetFormPhone.imgCode"></el-input>
          <img @click="getImgCode" :src="imgSrc" class="img_code" />
        </el-form-item>
        <el-form-item prop="code" class="pwd_code" label="验证码">
          <el-input
            type="text"
            placeholder="请输入验证码"
            v-model="forgetFormPhone.code"></el-input>
          <span class="code_val get_captcha_code" @click="getCodePhone">{{
            verPhone
          }}</span>
          <span class="code_text">{{ paracontPhone }}</span>
        </el-form-item>
        <el-form-item prop="password" label="新密码">
          <el-input
            type="password"
            autocomplete="new-password"
            placeholder="请输入新密码，长度6-20位"
            v-model="forgetFormPhone.password"></el-input>
        </el-form-item>
        <el-form-item prop="rePassword" label="确认密码">
          <el-input
            type="password"
            autocomplete="new-password"
            placeholder="请再次输入密码，长度6-20位"
            v-model="forgetFormPhone.rePassword"></el-input>
        </el-form-item>

        <el-form-item class="submit_btn">
          <ZjsjHomeButton
            size="large"
            :loading="btnLoading"
            :customStyle="{ width: '100%', height: '48px' }"
            :disabled="submitDisabled"
            @click="confirmPwdPhone">
            提交
          </ZjsjHomeButton>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { serverUrl } from '@/utils/env';
  import request from '@/utils/axios';
  import { encodeWord } from '@/utils/encodePwd';
  import { isMobile } from '@/utils/mUtils';
  import DialogHeader from './component/dialogHeader.vue';
  export default {
    components: {
      DialogHeader,
    },
    data() {
      // 校验手机号码
      let checkMobile = (rule, value, callback) => {
        if (value) {
          if (isMobile(value)) {
            callback();
          } else {
            callback(new Error(rule.message));
          }
        }
      };
      // 校验密码
      let checkPwd = (rule, value, callback) => {
        if (value) {
          if (value.length >= 6 && value.length <= 20) {
            callback();
          } else {
            callback(new Error(rule.message));
          }
        }
      };
      return {
        btnLoading: false,
        forgetFormPhone: {
          phone: null,
          code: null,
          password: null,
          rePassword: null,
          imgCode: null,
          captcha_token: null,
        },
        rulesPhone: {
          phone: [
            { required: true, message: '请输入手机号码', trigger: 'blur' },
            {
              validator: checkMobile,
              message: '请输入正确的手机号',
              trigger: 'blur',
            },
          ],
          imgCode: [
            { required: true, message: '请输入图片验证码', trigger: 'blur' },
          ],
          code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
          password: [
            {
              required: true,
              message: '请输入新密码，长度6-20位',
              trigger: 'blur',
            },
            {
              validator: checkPwd,
              message: '请输入新密码，长度6-20位',
              trigger: 'blur',
            },
          ],
          rePassword: [
            {
              required: true,
              message: '请再次输入密码，长度6-20位',
              trigger: 'blur',
            },
            {
              validator: checkPwd,
              message: '请再次输入密码，长度6-20位',
              trigger: 'blur',
            },
          ],
        },
        // 手机号定时器
        timePromisePhone: null,
        captchaFlagPhone: true,
        paracontPhone: null,
        //验证码
        verPhone: '获取验证码',
        secondPhone: 60,
        imgSrc: '',
        submitDisabled: true,
        validateResult: {
          phone: false,
          code: false,
          password: false,
          rePassword: false,
          imgCode: false,
        },
      };
    },
    mounted() {
      this.getImgCode();
    },
    destroyed() {
      clearInterval(this.timePromisePhone);
      this.captchaFlagPhone = true;
      this.paracontPhone = '';
      this.secondPhone = 60;
    },
    methods: {
      // 获取验证码
      getImgCode() {
        request({
          url: serverUrl + '/captcha',
          method: 'post',
        }).then((res) => {
          if (res.status === '0') {
            this.imgSrc = res.data.captcha_image;
            this.forgetFormPhone.captcha_token = res.data.captcha_token;
          }
        });
      },
      // 获取手机号验证码
      getCodePhone() {
        if (!this.forgetFormPhone.phone) {
          this.$message.error('请输入手机号');
          return;
        }
        if (!isMobile(this.forgetFormPhone.phone)) {
          this.$message.error('请输入正确的手机号');
          return;
        }
        if (!this.forgetFormPhone.imgCode) {
          this.$message.error('请输入图片验证码');
          return;
        }
        let params = {
          captcha_answer: this.forgetFormPhone.imgCode,
          captcha_token: this.forgetFormPhone.captcha_token,
          phone_number: this.forgetFormPhone.phone,
          scenario: 'getback',
        };
        request({
          url: serverUrl + '/sendVerification',
          method: 'post',
          data: params,
        }).then((res) => {
          if (res.status === '0' && JSON.parse(res.data).success) {
            this.$message.success('发送验证码成功');
            this.timePromisePhone = setInterval(
              () => {
                if (this.secondPhone <= 0) {
                  clearInterval(this.timePromisePhone);
                  this.timePromisePhone = undefined;
                  this.secondPhone = 60;
                  this.paracontPhone = '';
                  this.captchaFlagPhone = true;
                  this.verPhone = '获取验证码';
                } else {
                  this.verPhone = '';
                  this.paracontPhone = '剩余' + this.secondPhone + '秒';
                  this.captchaFlagPhone = false;
                  this.secondPhone--;
                }
              },
              1000,
              100
            );
          } else {
            this.getImgCode();
          }
        });
      },
      // 手机号修改密码
      confirmPwdPhone() {
        if (this.forgetFormPhone.password !== this.forgetFormPhone.rePassword) {
          this.$message.error('两次输入密码不一致');
          return;
        }
        this.forgetFormPhone.type = 0;
        this.$refs['phoneForm'].validate(async (valid) => {
          if (valid) {
            let pwd = '';
            let rePwd = '';
            await encodeWord(this.forgetFormPhone.password).then((r) => {
              pwd = r;
            });
            await encodeWord(this.forgetFormPhone.rePassword).then((r) => {
              rePwd = r;
            });

            let param = {
              code: this.forgetFormPhone.code,
              phone: this.forgetFormPhone.phone,
              password: pwd,
              rePassword: rePwd,
              type: 0,
            };
            request({
              url: serverUrl + '/pwd/reset/',
              method: 'post',
              data: param,
            }).then((res) => {
              if (res.status === '0') {
                this.$message.success('修改成功');
                setTimeout(() => {
                  this.$store.dispatch('setCurrentComponent', null);
                }, 200);
              }
            });
          }
        });
      },
      toHome() {
        // 3d前台 - 首页
        this.$router.push({ path: '/' });
      },
      handleBack() {
        this.$store.dispatch('setCurrentComponent', { name: 'LOGIN' });
      },
      handleClose() {
        this.$store.dispatch('setCurrentComponent', null);
      },
      // 校验事件，没通过则禁用提交按钮
      handleValidateEvent(key, res) {
        this.validateResult[key] = res;
        let values = Object.values(this.validateResult);
        this.submitDisabled = values.some((item) => !item);
      },
    },
  };
</script>

<style lang="less" scoped>
  .forget_pwd {
    padding-bottom: 6px;
    // width: 550px;
    margin: 0 auto;
    .el-icon-close {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 20px;
      cursor: pointer;
    }
    .forget_header {
      text-align: center;
      .header_title {
        font-size: 24px;
        margin-right: 20px;
        color: #111734;
      }
      .header_home {
        padding-top: 4px;
        color: #879cbb;
        font-size: 13px;
        background: url('~@/assets/img/login/back.png');
        background-size: 14px 14px;
        background-repeat: no-repeat;
        background-position: 0 17px;
        text-indent: 24px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .pwd_tab {
      margin: 50px 0;
      border-bottom: 1px solid #dee2ee;
      text-align: center;
      position: relative;
      .pwd_title {
        margin: 0 auto;
        width: 100px;
        color: #3f81c1;
        padding: 5px;
        font-size: 16px;
        border-bottom: 3px solid #4090ff;
      }
    }
    /deep/ .forget_content {
      margin: 30px auto 0;
      width: 540px;
      .el-form-item {
        margin-bottom: 24px;
        .el-input {
          .el-input__inner {
            &:focus {
              border-color: #be965a !important;
            }
          }
        }
      }
    }
    .img_inp {
      flex: 1;
    }
    .img_code {
      height: 40px;
      flex: 1;
    }
    .pwd_code {
      position: relative;
    }
    .get_captcha_code {
      color: #bbb;
      &:hover {
        color: #be965a;
      }
    }
    .code_val {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 13px;
      color: #bbb;
      cursor: pointer;
    }
    .code_text {
      position: absolute;
      right: 7px;
      top: 3px;
      font-size: 13px;
      color: gray;
      cursor: not-allowed;
    }
    /deep/.el-form-item--mini.el-form-item {
      margin-bottom: 24px;
    }
    /deep/.el-form-item--mini .el-form-item__error {
      margin-top: 5px;
    }
    /deep/ .img_code_container {
      .el-form-item__content {
        display: flex;
        justify-content: space-between;
      }
      .img_inp {
        margin-right: 10px;
        flex: 1;
      }
    }
    .submit_btn {
      margin-top: 156px;
    }
  }
</style>
