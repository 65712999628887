import { Layout } from './layout';

export const TROUBLEROUTER = {
  path: '/trouble',
  component: Layout,
  redirect: '/trouble/troubleShooting',
  meta: {
    title: '问题排查',
    icon: 'el-icon-notebook-2',
  },
  children: [
    {
      path: 'troubleShooting',
      meta: {
        title: '问题排查',
        routerType: 'leftmenu',
      },
      component: () => import('@/page/trouble/troubleShooting'),
    },
    {
      path: 'troubleLog',
      meta: {
        title: '排查日志',
        routerType: 'leftmenu',
      },
      component: () => import('@/page/trouble/troubleLog'),
    },
  ],
};
