/*
 * @Author: jingyh
 * @Date: 2021-10-20 09:30:52
 * @Description:
 */
export function deepCopy(obj) {
  if (typeof obj !== 'object' || obj === null) return obj;
  let newObj = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      /*if (obj.hasOwnProperty(key)) {*/
      if (typeof obj[key] === 'object') {
        newObj[key] = deepCopy(obj[key]);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  return newObj;
}

/**
 * 取两个数组的差集
 * @returns 返回两个数组的差集
 */
export function takeDifferentSet(arr1, arr2) {
  let outerArr = arr1.length >= arr2.length ? arr1 : arr2;
  let innerArr = arr1.length < arr2.length ? arr1 : arr2;

  const arr = [];
  outerArr.forEach((e) => {
    let hasEqual = false;
    innerArr.forEach((item) => {
      if (e[2] === item[2]) {
        hasEqual = true;
      }
    });
    if (!hasEqual) {
      arr.push(e);
    }
  });
  return arr;
}
/**
 * 取两个数组的第一级数据差集
 * @returns 返回两个数组的第N级数据差集
 */
export function takeDifferentArr(arr1, arr2, i) {
  let outerArr = arr1.length >= arr2.length ? arr1 : arr2;
  let innerArr = arr1.length < arr2.length ? arr1 : arr2;
  let proOuterArr = [
    ...new Set(
      outerArr.map((e) => {
        return e[i];
      })
    ),
  ];
  let proInnerArr = [
    ...new Set(
      innerArr.map((e) => {
        return e[i];
      })
    ),
  ];

  const arr = [];
  proOuterArr.forEach((e) => {
    if (!proInnerArr.includes(e)) {
      arr.push(e);
    }
  });
  return arr;
}

/**
 * @description 格式化数字输出
 * @param { Number | String } number 待格式化的数据
 * @param { String } split 用于分割的字符
 * @param { Boolean } showEndZero 是否显示末尾的0
 * @return String
 */
export function kiloSplit(number, split = ',', showEndZero = false) {
  if (!number) {
    return number;
  }
  let numberSplit = number.toString().split('.');
  if (numberSplit[1]) {
    let last = numberSplit[1].length === 1 ? '0' : '';
    return numberSplit[0].replace(/(\d)(?=(?:\d{3})+$)/g, `$1${split}`) + '.' + numberSplit[1] + last;
  }
  let result = numberSplit[0].replace(/(\d)(?=(?:\d{3})+$)/g, `$1${split}`);
  return showEndZero ? result + '.00' : result;
}

export function randomId(length = 10) {
  return Math.random().toString(36).substr(2, length);
}

/* @description: 截取文件类型
 * @param {String} file 文件地址名
 * @return {String} fileType
 */
export function cutFileType(file) {
  return file.substring(file.lastIndexOf('.')).toLowerCase();
}
// 播放器打开全屏
export function mediaLaunchFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else if (element.oRequestFullscreen) {
    element.oRequestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullScreen();
  }
}

// 播放器关闭全屏
export function mediaExitFullscreen() {
  if (document.fullscreenElement !== null) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.oRequestFullscreen) {
      document.oCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
}
/**
 * @description: 多选点击全部
 * @param {Array} params 需要返回的集合
 * @param {Array} list 字典集合
 * @param {String} name 字典编码字段名
 * @return {Array} params
 */
export function multipleSelectAll(params, list, name) {
  let flag = params.length === 1 && (params[0] === '' || params[0] === '全部');
  if (params.length < list.length || flag) {
    params = [];
    list.map((item) => {
      params.push(item[name]);
    });
    if (list.length > 1) {
      params.unshift('全部');
    }
  } else {
    params = [];
  }
  return params;
}
/**
 * @description: 多选下拉改变
 * @param {Array} params 需要返回的集合
 * @param {Array} list 字典集合
 * @param {String} val 选中的值
 * @return {Array} params
 */
export function multipleChangeSelect(val, params, list) {
  if (!val.includes('全部') && val.length === list.length) {
    params.unshift('全部');
  } else if (val.includes('全部') && val.length - 1 < list.length) {
    params = params.filter((item) => {
      return item != '全部';
    });
  }
  return params;
}

/**
 * @description 把数组转化为Object
 * @param {Array} arr 待转化的数组
 * @param {String} key 取值的key
 * @param {String} name 取值
 * @returns
 */
export function arr2obj(arr, key, name) {
  let obj = {};
  arr.forEach((item) => {
    obj[item[key]] = item[name];
  });
  return obj;
}

/**
 *
 * @param {Any} obj 待检测的数据
 * @returns
 */
export function typeIs(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
}

export function isString(str) {
  return typeIs(str) === 'string';
}

export function isArray(arr) {
  return typeIs(arr) === 'array';
}

export function isObject(obj) {
  return typeIs(obj) === 'object';
}

export function isFunction(obj) {
  return typeIs(obj) === 'function';
}

export function hasPermission(action) {
  let btnList = [];
  if (window.self === window.top) {
    if (window.localStorage.getItem('btnList')) {
      btnList = JSON.parse(window.localStorage.getItem('btnList'));
      return btnList.includes(action);
    }
  }
}

/**
 * @description 判断是否是IE浏览器
 */
export function isIE() {
  if (!!window.ActiveXObject || 'ActiveXObject' in window) return true;
  else return false;
}

/**
 * @description 防抖函数
 * @param { Function } fn 需要防抖的函数
 * @param { Number } wait 间隔的时间
 * @param { Boolean } immediate 首次触发是否立即执行
 * @return Function
 * */
export function debounce(fn, delay, immdiate = false) {
  let timer = null;
  let isInvoke = false;
  function _debounce(...arg) {
    if (timer) clearTimeout(timer);
    if (immdiate && !isInvoke) {
      fn.apply(this, arg);
      isInvoke = true;
    } else {
      timer = setTimeout(() => {
        fn.apply(this, arg);
        isInvoke = false;
        timer = null;
      }, delay);
    }
  }

  // 取消功能
  _debounce.cancel = function () {
    if (timer) clearTimeout(timer);
    timer = null;
    isInvoke = false;
  };
  return _debounce;
}

/**
 * @description 节流函数 每隔一段时间触发一次函数
 * @param { Function } fn 要触发的函数
 * @param { Number } wait 间隔的时间
 * @param { Object } option 配置参数
 **/
export function throttle(fn, wait, option = { start: true, end: true }) {
  let init_time = 0,
    timeout;
  let { start, end } = option;
  let resultFn = function () {
    let context = this;
    let args = arguments;
    let later = function () {
      fn.apply(context, args);
    };
    let cancelEnd = function () {
      if (end) {
        timeout = setTimeout(() => {
          later();
          clearTimeout(timeout);
        }, wait);
      } else {
        clearTimeout(timeout);
      }
    };
    var current_time = new Date().getTime();
    if (current_time - init_time > wait) {
      init_time = current_time;
      if (start) {
        if (!timeout) {
          later();
        }
        cancelEnd();
      } else {
        cancelEnd();
      }
    }
  };
  resultFn.cancel = function () {
    init_time = 0;
    clearTimeout(timeout);
    timeout = null;
  };
  return resultFn;
}

/**
 * @description 对比两个对象是否相等
 * @param {Any} a 待对比的数据
 * @param {Any} b 待对比的数据
 * @returns Boolean
 */
export function isEqual(a, b) {
  if (typeof a !== typeof b) {
    return false;
  }
  if (typeof a === 'object') {
    if (Array.isArray(a)) {
      if (a.length !== b.length) {
        return false;
      }
      return a.every((item, index) => {
        return isEqual(item, b[index]);
      });
    } else {
      const aKeys = Object.keys(a);
      const bKeys = Object.keys(b);
      if (aKeys.length !== bKeys.length) {
        return false;
      }
      return aKeys.every((key) => {
        return isEqual(a[key], b[key]);
      });
    }
  } else {
    return a === b;
  }
}
export function isChrome() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes('chrome') && !ua.includes('chromium') && !ua.includes('edg');
}

/**
 * @description 拼接url
 * @param params
 * @returns {string}
 */
export function queryString(params) {
  let str = '';
  for (let key in params) {
    if (params[key]) {
      str += `${key}=${params[key]}&`;
    }
  }
  return str.substring(0, str.length - 1);
}
