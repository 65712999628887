// 获取元素
import Vue from 'vue';
const Intersection = {
  inserted: (el, binding) => {
    let {
      value: { option, onChange = () => {} },
    } = binding;
    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onChange(el, entry.isIntersecting);
        }
      });
    }, option);
    el.intersect = intersectionObserver;
    intersectionObserver.observe(el);
  },
};
Vue.directive('intersection', Intersection);
