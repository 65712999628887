import { Layout } from './layout';

export const HaierHomeRouter = {
  path: '/haierhome',
  component: Layout,
  meta: {
    title: '及',
    icon: 'el-icon-notebook-2',
  },
  redirect: '/haierhome/createuser',
  children: [
    {
      path: 'createuser',
      meta: {
        title: '创建用户',
        routerType: 'leftmenu',
      },
      component: () => import('@/layout/haierHomeLayout.vue'),
    },
    {
      path: 'createorder',
      meta: {
        title: '创建订单',
        routerType: 'leftmenu',
      },
      component: () => import('@/layout/haierHomeLayout.vue'),
    },
  ],
};
