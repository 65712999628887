import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

function sentryInit(router) {
  console.log('process.env.NODE_ENV', process.env.FRAPPE_VERSION);
  Sentry.init({
    Vue,
    release: process.env.FRAPPE_VERSION,
    dsn: 'https://deecfce5206949f58a664dede7735de6@ss.haier.net/17',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [/^\//],
      }),
      new Sentry.Integrations.Breadcrumbs({ console: false }),
      // Sentry.replayIntegration({
      //   // Additional SDK configuration goes in here, for example:
      //   maskAllText: true,
      //   blockAllMedia: true,
      // }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_SENTRY,
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,
  });
}
export default sentryInit;
